import React from 'react';
import { observer } from 'mobx-react-lite';

import './ImageQuestions.css';
import { getSelectedOption } from './util';
import { useSwotStore } from '../../../store/index';

const ImageQuestions = observer(({ currentTestId, questions }) => {
  console.log('Questions: ', questions);

  const { swotStore } = useSwotStore();
  const { testToAnswersMap, setAnswer } = swotStore;

  const getQuestions = question => {
    const n = question.length;
    const questionObjects = [];

    for (let index = 0; index < n; index += 6) {
      const item = (
        <div className="row justify-content-start with-margin" style={{ 'margin-left': '100px' }}>
          <div className="col-10">
            <div className="justify-content-start row with-margin">
              <img src={question[index].questionImage} height={100} width={830} />
            </div>
            <div className="justify-content-start margin-l row with-margin" style={{ 'margin-left': '100px' }}>
              <div className="col offset-sm-0">
                {index + 1}.
                <input
                  type="checkbox"
                  className="boxcheckbox"
                  id={question[index].testQuestionOptions[0].optionId}
                  name={question[index].testQuestionOptions[0].optionId}
                  defaultValue={question[index].testQuestionOptions[0].optionName}
                  checked={getSelectedOption(
                    testToAnswersMap,
                    currentTestId,
                    question[index].questionId,
                    question[index].testQuestionOptions[0].optionId
                  )}
                  onChange={() => {
                    setAnswer(
                      currentTestId,
                      question[index].questionId,
                      question[index].testQuestionOptions[0].optionId
                    );
                  }}
                />
                <label htmlFor={question[index].testQuestionOptions[0].optionId}>
                  {question[index].testQuestionOptions[0].optionName}
                </label>
                <input
                  type="checkbox"
                  className="boxcheckbox"
                  id={question[index].testQuestionOptions[1].optionId}
                  name={question[index].testQuestionOptions[1].optionId}
                  defaultValue={question[index].testQuestionOptions[1].optionName}
                  checked={getSelectedOption(
                    testToAnswersMap,
                    currentTestId,
                    question[index].questionId,
                    question[index].testQuestionOptions[1].optionId
                  )}
                  onChange={() => {
                    setAnswer(
                      currentTestId,
                      question[index].questionId,
                      question[index].testQuestionOptions[1].optionId
                    );
                  }}
                />
                <label htmlFor={question[index].testQuestionOptions[1].optionId}>
                  {question[index].testQuestionOptions[1].optionName}
                </label>
              </div>
              <div className="col offset-sm-0">
                {index + 2}.
                <input
                  type="checkbox"
                  className="boxcheckbox"
                  id={question[index + 1].testQuestionOptions[0].optionId}
                  name={question[index + 1].testQuestionOptions[0].optionId}
                  defaultValue={question[index + 1].testQuestionOptions[0].optionName}
                  checked={getSelectedOption(
                    testToAnswersMap,
                    currentTestId,
                    question[index + 1].questionId,
                    question[index + 1].testQuestionOptions[0].optionId
                  )}
                  onChange={() => {
                    setAnswer(
                      currentTestId,
                      question[index + 1].questionId,
                      question[index + 1].testQuestionOptions[0].optionId
                    );
                  }}
                />
                <label htmlFor={question[index + 1].testQuestionOptions[0].optionId}>
                  {question[index + 1].testQuestionOptions[0].optionName}
                </label>
                <input
                  type="checkbox"
                  className="boxcheckbox"
                  id={question[index + 1].testQuestionOptions[1].optionId}
                  name={question[index + 1].testQuestionOptions[1].optionId}
                  defaultValue={question[index + 1].testQuestionOptions[1].optionName}
                  checked={getSelectedOption(
                    testToAnswersMap,
                    currentTestId,
                    question[index + 1].questionId,
                    question[index + 1].testQuestionOptions[1].optionId
                  )}
                  onChange={() => {
                    setAnswer(
                      currentTestId,
                      question[index + 1].questionId,
                      question[index + 1].testQuestionOptions[1].optionId
                    );
                  }}
                />
                <label htmlFor={question[index + 1].testQuestionOptions[1].optionId}>
                  {question[index + 1].testQuestionOptions[1].optionName}
                </label>
              </div>
              <div className="col offset-sm-0">
                {index + 3}.
                <input
                  type="checkbox"
                  className="boxcheckbox"
                  id={question[index + 2].testQuestionOptions[0].optionId}
                  name={question[index + 2].testQuestionOptions[0].optionId}
                  defaultValue={question[index + 2].testQuestionOptions[0].optionName}
                  checked={getSelectedOption(
                    testToAnswersMap,
                    currentTestId,
                    question[index + 2].questionId,
                    question[index + 2].testQuestionOptions[0].optionId
                  )}
                  onChange={() => {
                    setAnswer(
                      currentTestId,
                      question[index + 2].questionId,
                      question[index + 2].testQuestionOptions[0].optionId
                    );
                  }}
                />
                <label htmlFor={question[index + 2].testQuestionOptions[0].optionId}>
                  {question[index + 2].testQuestionOptions[0].optionName}
                </label>
                <input
                  type="checkbox"
                  className="boxcheckbox"
                  id={question[index + 2].testQuestionOptions[1].optionId}
                  name={question[index + 2].testQuestionOptions[1].optionId}
                  defaultValue={question[index + 2].testQuestionOptions[1].optionName}
                  checked={getSelectedOption(
                    testToAnswersMap,
                    currentTestId,
                    question[index + 2].questionId,
                    question[index + 2].testQuestionOptions[1].optionId
                  )}
                  onChange={() => {
                    setAnswer(
                      currentTestId,
                      question[index + 2].questionId,
                      question[index + 2].testQuestionOptions[1].optionId
                    );
                  }}
                />
                <label htmlFor={question[index + 2].testQuestionOptions[1].optionId}>
                  {question[index + 2].testQuestionOptions[1].optionName}
                </label>
              </div>
              <div className="col offset-sm-0">
                {index + 4}.
                <input
                  type="checkbox"
                  className="boxcheckbox"
                  id={question[index + 3].testQuestionOptions[0].optionId}
                  name={question[index + 3].testQuestionOptions[0].optionId}
                  defaultValue={question[index + 3].testQuestionOptions[0].optionName}
                  checked={getSelectedOption(
                    testToAnswersMap,
                    currentTestId,
                    question[index + 3].questionId,
                    question[index + 3].testQuestionOptions[0].optionId
                  )}
                  onChange={() => {
                    setAnswer(
                      currentTestId,
                      question[index + 3].questionId,
                      question[index + 3].testQuestionOptions[0].optionId
                    );
                  }}
                />
                <label htmlFor={question[index + 3].testQuestionOptions[0].optionId}>
                  {question[index + 3].testQuestionOptions[0].optionName}
                </label>
                <input
                  type="checkbox"
                  className="boxcheckbox"
                  id={question[index + 3].testQuestionOptions[1].optionId}
                  name={question[index + 3].testQuestionOptions[1].optionId}
                  defaultValue={question[index + 3].testQuestionOptions[1].optionName}
                  checked={getSelectedOption(
                    testToAnswersMap,
                    currentTestId,
                    question[index + 3].questionId,
                    question[index + 3].testQuestionOptions[1].optionId
                  )}
                  onChange={() => {
                    setAnswer(
                      currentTestId,
                      question[index + 3].questionId,
                      question[index + 3].testQuestionOptions[1].optionId
                    );
                  }}
                />
                <label htmlFor={question[index + 3].testQuestionOptions[1].optionId}>
                  {question[index + 3].testQuestionOptions[1].optionName}
                </label>
              </div>
              <div className="col offset-sm-0">
                {index + 5}.
                <input
                  type="checkbox"
                  className="boxcheckbox"
                  id={question[index + 4].testQuestionOptions[0].optionId}
                  name={question[index + 4].testQuestionOptions[0].optionId}
                  defaultValue={question[index + 4].testQuestionOptions[0].optionName}
                  checked={getSelectedOption(
                    testToAnswersMap,
                    currentTestId,
                    question[index + 4].questionId,
                    question[index + 4].testQuestionOptions[0].optionId
                  )}
                  onChange={() => {
                    setAnswer(
                      currentTestId,
                      question[index + 4].questionId,
                      question[index + 4].testQuestionOptions[0].optionId
                    );
                  }}
                />
                <label htmlFor={question[index + 4].testQuestionOptions[0].optionId}>
                  {question[index + 4].testQuestionOptions[0].optionName}
                </label>
                <input
                  type="checkbox"
                  className="boxcheckbox"
                  id={question[index + 4].testQuestionOptions[1].optionId}
                  name={question[index + 4].testQuestionOptions[1].optionId}
                  defaultValue={question[index + 4].testQuestionOptions[1].optionName}
                  checked={getSelectedOption(
                    testToAnswersMap,
                    currentTestId,
                    question[index + 4].questionId,
                    question[index + 4].testQuestionOptions[1].optionId
                  )}
                  onChange={() => {
                    setAnswer(
                      currentTestId,
                      question[index + 4].questionId,
                      question[index + 4].testQuestionOptions[1].optionId
                    );
                  }}
                />
                <label htmlFor={question[index + 4].testQuestionOptions[1].optionId}>
                  {question[index + 4].testQuestionOptions[1].optionName}
                </label>
              </div>
              <div className="col offset-sm-0">
                {index + 6}.
                <input
                  type="checkbox"
                  className="boxcheckbox"
                  id={question[index + 5].testQuestionOptions[0].optionId}
                  name={question[index + 5].testQuestionOptions[0].optionId}
                  defaultValue={question[index + 5].testQuestionOptions[0].optionName}
                  checked={getSelectedOption(
                    testToAnswersMap,
                    currentTestId,
                    question[index + 5].questionId,
                    question[index + 5].testQuestionOptions[0].optionId
                  )}
                  onChange={() => {
                    setAnswer(
                      currentTestId,
                      question[index + 5].questionId,
                      question[index + 5].testQuestionOptions[0].optionId
                    );
                  }}
                />
                <label htmlFor={question[index + 5].testQuestionOptions[0].optionId}>
                  {question[index + 5].testQuestionOptions[0].optionName}
                </label>
                <input
                  type="checkbox"
                  className="boxcheckbox"
                  id={question[index + 5].testQuestionOptions[1].optionId}
                  name={question[index + 5].testQuestionOptions[1].optionId}
                  defaultValue={question[index + 5].testQuestionOptions[1].optionName}
                  checked={getSelectedOption(
                    testToAnswersMap,
                    currentTestId,
                    question[index + 5].questionId,
                    question[index + 5].testQuestionOptions[1].optionId
                  )}
                  onChange={() => {
                    setAnswer(
                      currentTestId,
                      question[index + 5].questionId,
                      question[index + 5].testQuestionOptions[1].optionId
                    );
                  }}
                />
                <label htmlFor={question[index + 5].testQuestionOptions[1].optionId}>
                  {question[index + 5].testQuestionOptions[1].optionName}
                </label>
              </div>
            </div>
          </div>
        </div>
      );
      questionObjects.push(item);
    }

    return questionObjects;
  };

  const questionObjects = getQuestions(questions);

  return <>{questionObjects}</>;
});

export default ImageQuestions;
