import React, { Fragment } from 'react';
import { observer } from 'mobx-react-lite';

import ContentWithAsideLayout from '../layouts/ContentWithAsideLayout';
import SettingsProfile from '../components/Settings/SettingsProfile';
import ItemBanner from '../components/item/ItemBanner';

import team2 from '../assets/img/team/2.jpg';
import generic4 from '../assets/img/generic/4.jpg';
import SettingsChangePassword from '../components/Settings/SettingsChangePassword';

const SettingsBanner = () => (
  <ItemBanner>
    <ItemBanner.Header avatarSrc={team2} coverSrc={generic4} isEditable />
  </ItemBanner>
);

const SettingsContent = () => (
  <Fragment>
    <SettingsProfile />
  </Fragment>
);

const PasswordContent = () => {};

const ProfileLayout = observer(() => {
  return <SettingsContent />;
});

export default ProfileLayout;
