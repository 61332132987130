import React, { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Jumbotron,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Button
} from 'reactstrap';
import _ from 'lodash';
import Slider from 'react-slick';
import { toast } from 'react-toastify';
import { toJS } from 'mobx';

import { useSwotStore } from '../../store';
import PageLoader from '../../components/common/PageLoader';

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipe: true
};

const TestTakingLayout = observer(({ currentTestId }) => {
  const { swotStore } = useSwotStore();
  const { testToAnswersMap, setAnswer } = swotStore;

  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);

  let sliderInstance = useRef();

  useEffect(() => {
    if (currentTestId == null) {
      return;
    }
    swotStore.doFetchTestQuestions().then(response => {
      if (response.statusCode != 200) {
        toast.error("Couldn't load questions");
      } else {
        setQuestions(response.result.testQuestions);
      }
    });
  }, [currentTestId]);

  if (_.isEmpty(questions)) {
    return <></>;
  }

  return (
    <>
      <Jumbotron style={{ marginTop: '150px', background: 'inherit', border: '1px solid black' }}>
        <h5> Questions: </h5>
        <Slider settings={settings} style={{ boxShadow: 'none' }} ref={slider => (sliderInstance = slider)}>
          {questions.map((questionObj, index) => (
            <Card style={{ boxShadow: 'none' }}>
              <CardBody>
                <CardTitle>
                  {index + 1}. {questionObj.questionName}:
                </CardTitle>
                <CardText>
                  {/* <FormGroup> */}
                  {questionObj.testQuestionOptions.map(option => (
                    <div className="ml-5 mb-3">
                      <Input
                        type="radio"
                        name={option.optionName}
                        key={option.optionId}
                        value={option.optionId}
                        checked={getSelectedOption(
                          testToAnswersMap,
                          currentTestId,
                          questionObj.questionId,
                          option.optionId
                        )}
                        onChange={({ target }) => {
                          setAnswer(currentTestId, questionObj.questionId, target.value);
                        }}
                      />
                      <Label check style={{ marginTop: '2px' }}>
                        {option.optionName}
                      </Label>
                    </div>
                  ))}
                  {/* </FormGroup> */}
                </CardText>
              </CardBody>
            </Card>
          ))}
        </Slider>
        <div style={{ textAlign: 'right' }}>
          <Button
            className="mr-3"
            color="falcon-primary"
            onClick={() => {
              sliderInstance.slickGoTo(currentQuestion - 1);
              setCurrentQuestion(currentQuestion - 1);
            }}
          >
            Prev
          </Button>
          <Button
            className="mr-3"
            color="falcon-primary"
            onClick={() => {
              sliderInstance.slickGoTo(currentQuestion + 1);
              setCurrentQuestion(currentQuestion + 1);
            }}
          >
            Next
          </Button>
          <Button className="mr-3" color="primary">
            Save
          </Button>
          <Button className="mr-3" color="primary">
            Submit Test
          </Button>
        </div>
      </Jumbotron>
    </>
  );
});

const getSelectedOption = (testToAnswersMap, currentTestId, questionId, optionId) => {
  console.log('Checking', toJS(testToAnswersMap), currentTestId, questionId, optionId);
  let obj = testToAnswersMap[currentTestId];
  if (obj == null || _.isEmpty(obj)) {
    return false;
  }

  let value = obj[questionId];
  return value == optionId;
};

export default TestTakingLayout;
