import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Card, CardBody, Col, CustomInput, Label, Row, Table } from 'reactstrap';
import _ from 'lodash';

import FalconCardHeader from '../../components/common/FalconCardHeader';
import { useSwotStore } from '../../store';
import User from './User';

const CreateUser = observer(() => {
  const { swotStore } = useSwotStore();

  return (
    <Card className="mb-3">
      <CardBody>
        <FalconCardHeader title="Create User" light={false} className="text-center" />
        <User />
      </CardBody>
    </Card>
  );
});

export default CreateUser;
