import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, CustomInput, Form, FormGroup, FormFeedback, FormText, Input, Label } from 'reactstrap';
import withRedirect from '../../hoc/withRedirect';
import { useSwotStore } from '../../store';
import Divider from '../common/Divider';
import SocialAuthButtons from './SocialAuthButtons';
import gdpr from '../../assets/docs/GDPR.pdf';
import pp from '../../assets/docs/IPPolicy.pdf';
import tc from '../../assets/docs/TermsAndConditions.pdf';

const RegistrationForm = observer(({ setRedirect, setRedirectUrl, layout, hasLabel }) => {
  // State
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isAccepted, setIsAccepted] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const { swotStore } = useSwotStore();

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    swotStore.doRegister(null, email, password).then(response => {
      if (response.statusCode == 400) {
        toast.error(response.errorMsg);
      } else if (response.statusCode != 200) {
        toast.error("Couldn't register. Please try again.");
      } else {
        swotStore.doLogin(email, password).then(loginResponse => {
          if (loginResponse.statusCode != 200) {
            toast.error('Something went wrong. Please try again.');
          } else {
            toast.success('Successfully registered.');
            swotStore.doFetchProfile().then(response => {
              setRedirect(true);
            });
          }
        });
      }
    });
  };

  useEffect(() => {
    setRedirectUrl(`/products`);
  }, [setRedirectUrl, layout]);

  useEffect(() => {
    setIsDisabled(!email || !password || !confirmPassword || !isAccepted || password !== confirmPassword);
  }, [email, password, confirmPassword, isAccepted]);

  return (
    <Form onSubmit={handleSubmit}>
      {/* <FormGroup>
        {hasLabel && <Label>Name</Label>}
        <Input placeholder={!hasLabel ? 'Name' : ''} value={name} onChange={({ target }) => setName(target.value)} />
      </FormGroup> */}
      <FormGroup>
        {hasLabel && <Label>Email address</Label>}
        <Input
          placeholder={!hasLabel ? 'Email address' : ''}
          value={email}
          onChange={({ target }) => setEmail(target.value)}
          type="email"
          isRequired
        />
        {email != null && email.conta && <div className="mt-2 text-danger">Passwords don't match.</div>}
      </FormGroup>
      <div className="form-row">
        <FormGroup className="col-6">
          {hasLabel && <Label>Password</Label>}
          <Input
            placeholder={!hasLabel ? 'Password' : ''}
            value={password}
            onChange={({ target }) => setPassword(target.value)}
            type="password"
          />
          {password != confirmPassword && <div className="mt-2 text-danger">Passwords don't match.</div>}
        </FormGroup>
        <FormGroup className="col-6">
          {hasLabel && <Label>Confirm Password</Label>}
          <Input
            placeholder={!hasLabel ? 'Confirm Password' : ''}
            value={confirmPassword}
            onChange={({ target }) => setConfirmPassword(target.value)}
            type="password"
          />
        </FormGroup>
      </div>

      <CustomInput
        id="customCheckTerms"
        label={
          <Fragment>
            I accept the{' '}
            <a href={tc} target="_blank">
              terms
            </a>
            ,{' '}
            <a href={pp} target="_blank">
              privacy policy
            </a>{' '}
            and{' '}
            <a href={gdpr} target="_blank">
              GDPR policy.
            </a>
          </Fragment>
        }
        checked={isAccepted}
        onChange={({ target }) => setIsAccepted(target.checked)}
        type="checkbox"
      />
      <FormGroup>
        <Button color="primary" block className="mt-3" disabled={isDisabled}>
          Register
        </Button>
      </FormGroup>
      {/* <Divider className="mt-4">or register with</Divider>
      <SocialAuthButtons /> */}
    </Form>
  );
});

RegistrationForm.propTypes = {
  setRedirect: PropTypes.func.isRequired,
  setRedirectUrl: PropTypes.func.isRequired,
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

RegistrationForm.defaultProps = {
  layout: 'basic',
  hasLabel: false
};

export default observer(withRedirect(RegistrationForm));
