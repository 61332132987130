import React from 'react';
import { observer } from 'mobx-react-lite';
import { Col, Media, Row, Button, Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import { BsCheckCircle } from 'react-icons/bs';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useSwotStore } from '../../store';
import VideoPlayer from '../../components/common/VideoPlayer';

const VideoModule = observer(({ videos }) => {
  const { swotStore } = useSwotStore();
  // const { videos } = swotStore;
  const [reload, setReload] = useState(null);

  const [collapse, setCollapse] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);

  const handleVideo = video => e => {
    setCollapse(true);
    setCurrentVideo(video);
  };

  // useEffect(() => {
  //   if (_.isEmpty(videos)) return;
  //   let unwatchedVideos = videos.filter(video => !video.videoWatched);
  //   if (reload == null) {
  //     if (!_.isEmpty(unwatchedVideos)) {
  //       setReload(false);
  //     } else {
  //       setReload(true);
  //     }
  //     return;
  //   }

  //   if (_.isEmpty(unwatchedVideos) && !reload) {
  //     window.location.reload();
  //   }
  // }, [videos]);

  return (
    <>
      {videos.map((video, index) => (
        <Row
          key={index}
          noGutters
          className="align-items-center px-1 card"
          style={{ height: '100px', marginBottom: '10px' }}
        >
          <Col xs={12} className="py-3 px-2 px-md-3">
            <Media className="align-items-center">
              <Link to="#">
                <img
                  className="rounded mr-3 d-none d-md-block"
                  src={video.videoThumbnailUri || 'http://addplaybuttontoimage.way4info.net/Images/Icons/25.png'}
                  alt="Icon"
                  width="60"
                />
              </Link>
              <Media body>
                <h5 className="fs-0">{video.videoTitle || video.fileName}</h5>
                <p style={{ marginBottom: 0 }}>{video.videoDescription}</p>
              </Media>
              <Media>
                {video.videoWatched && (
                  <span className="badge fs-lg--1 text" style={{ marginTop: '23px', color: 'green' }}>
                    Completed
                    <FontAwesomeIcon icon="check" transform="shrink-2" className=" ml-1" />
                  </span>
                )}
                <Button onClick={handleVideo(video)} color="primary" block className="mt-3">
                  Play
                </Button>
              </Media>
            </Media>
          </Col>
        </Row>
      ))}
      {currentVideo && <VideoPlayer collapse={collapse} video={currentVideo} setCollapse={setCollapse} />}
    </>
  );
});

export default VideoModule;
