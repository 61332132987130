import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';

import { useSwotStore } from '../../store';

const IntermediateModule = observer(({}) => {
  const { swotStore } = useSwotStore();
  const [data, setData] = useState([]);

  useEffect(() => {
    swotStore.doFetchCommonInstructions().then(response => {
      if (response.statusCode != 200) {
        toast.error("Couldn't fetch the instructions. Please try again.");
      } else {
        setData(response.result);
      }
    });
  }, []);

  return (
    <>
      {data.map(instruction => (
        // <pre key={instruction.instructionId}>{instruction.instructionDesc}</pre>
        <div dangerouslySetInnerHTML={{ __html: instruction.instructionDesc }} />
      ))}
      <div className="text-center">
        <Link className="btn btn-primary" to="/tests">
          Take Tests
        </Link>
      </div>
    </>
  );
});

export default IntermediateModule;
