import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import createMarkup from '../../helpers/createMarkup';
import Section from '../common/Section';
import IconGroup from '../common/icon/IconGroup';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { animateScroll } from 'react-scroll';
import classNames from 'classnames';

import RefundPolicy from '../../assets/docs/RefundPolicy.pdf';

const FooterTitle = ({ children, lightMode = false }) => (
  <h5 className={classNames('text-uppercase opacity-85 mb-3', { 'text-white': !lightMode })}>{children}</h5>
);

FooterTitle.propTypes = { children: PropTypes.node.isRequired };

const FooterList = ({ list }) => (
  <ul className="list-unstyled">
    {list.map(({ title, to, external = false }, index) => (
      <li className="mb-1" key={index}>
        {external ? (
          <a href={to} className="text-600" target="_blank">
            {title}
          </a>
        ) : (
          <Link className="text-600" to={to}>
            {title}
          </Link>
        )}
      </li>
    ))}
  </ul>
);

FooterList.propTypes = { list: PropTypes.array.isRequired };

const FooterBlogList = ({ list }) => (
  <ul className="list-unstyled">
    {list.map((blog, index) => (
      <li key={index}>
        <h5 className="fs-0 mb-0">
          <Link className="text-600" to="#!">
            {blog.title}
          </Link>
        </h5>
        <p className="text-600 opacity-50">
          {blog.date} &bull; {blog.read} read {blog.star && <span dangerouslySetInnerHTML={createMarkup('&starf;')} />}
        </p>
      </li>
    ))}
  </ul>
);

FooterBlogList.propTypes = { list: PropTypes.array.isRequired };

const quickLinks = [
  { title: 'Home', to: '#!' },
  { title: 'Products', to: '/products' },
  { title: 'Blog', to: 'http://blog.swotcareers.com', external: true },
  { title: 'About Us', to: '/about' },
  { title: 'Contact us', to: '/contact' },
  { title: 'Refund Policy', to: RefundPolicy, external: true }
];

const socialMedia = [
  { icon: ['fab', 'facebook-f'], color: 'facebook', bg: 'white', href: 'https://www.facebook.com/swotcareers/' },
  { icon: ['fab', 'twitter'], color: 'twitter', bg: 'white', href: 'https://twitter.com/swotcareers/' },
  {
    icon: ['fab', 'fa-instagram'],
    color: 'instagram',
    bg: 'white',
    href: 'https://instagram.com/swotcareers?igshid=j3z8lssudctg'
  },
  { icon: ['fab', 'youtube'], color: '700', bg: 'white', href: 'https://youtube.com/channel/UCARfDRneFqLWuSgTaccoKNA' }
];

const FooterStandard = ({ lightMode = false }) => {
  const disabledUrls = ['admin', 'analytics'];

  for (let i = 0; i < disabledUrls.length; i++) {
    const url = disabledUrls[i];
    if (window.location.pathname.includes(url)) {
      return <></>;
    }
  }

  return (
    <Fragment>
      <Section bg={!lightMode ? 'dark' : ''} className="pt-8 pb-4">
        {!lightMode && (
          <div
            className={classNames('position-absolute btn-back-to-top cursor-pointer ', { 'bg-dark': !lightMode })}
            onClick={animateScroll.scrollToTop}
          >
            <FontAwesomeIcon icon="chevron-up" transform="rotate-45" className="text-600" />
          </div>
        )}
        <div style={{ borderTop: '1px solid black' }}>
          <Row className="mt-3">
            <Col lg={4}>
              <FooterTitle lightMode={lightMode}>WHO WE ARE</FooterTitle>
              <p className="text-600">
                SWOT Careers serves as a one stop shop platform in helping you understand yourself scientifically and
                pursue the best career for yourself in a practical manner. We provide Instant on-demand career mentoring
                through our career maturity program and our continuous engagement model.
              </p>
              <IconGroup className="mt-4" icons={socialMedia} />
            </Col>
            <Col className="pl-lg-6 pl-xl-8">
              <Row className="mt-5 mt-lg-0">
                <Col xs={6} md={3}>
                  <FooterTitle lightMode={lightMode}>Quick Links</FooterTitle>
                  <FooterList list={quickLinks} />
                </Col>
                <Col xs={6} md={3}>
                  <FooterTitle lightMode={lightMode}>Contact Us</FooterTitle>
                  {/* <FooterList list={menuList2} /> */}
                  <div className="contact-info">
                    <div className="footer-style-w3ls" style={{ color: '#748194' }}>
                      <h4 className="mb-2">
                        <FontAwesomeIcon icon="envelope" /> Email
                      </h4>
                      <p>
                        <a href="mailto:info@example.com"> info@swotcareers.com </a>
                      </p>
                    </div>
                    <div className="footer-style-w3ls">
                      <h4 className="mb-2">
                        <FontAwesomeIcon icon="phone" /> Phone
                      </h4>
                      <p>
                        <a href="tel:+91 79758 05563"> +91 79758 05563 </a>
                      </p>
                    </div>
                  </div>
                </Col>
                <Col className="mt-5 mt-md-0">
                  {/* <FooterTitle>From the Blog</FooterTitle> */}
                  {/* <FooterBlogList list={blogPostList} /> */}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Section>

      {/* <section className=" bg-dark py-0 text-center fs--1">
        <hr className="my-0 border-600 opacity-25" />
        <div className="container py-3">
          <Row className="justify-content-between">
            <Col xs={12} sm="auto">
              <p className="mb-0 text-600">
                Thank you for creating with Falcon <span className="d-none d-sm-inline-block">| </span>
                <br className="d-sm-none" /> {new Date().getFullYear()} &copy;{' '}
                <a
                  className="text-white opacity-85"
                  href="https://themewagon.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ThemeWagon
                </a>
              </p>
            </Col>
            <Col xs={12} sm="auto">
              <p className="mb-0 text-600">v{version}</p>
            </Col>
          </Row>
        </div>
      </section> */}
    </Fragment>
  );
};

export default FooterStandard;
