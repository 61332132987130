import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Card, CardBody, Col, CustomInput, Label, Row } from 'reactstrap';
import { Form, FormGroup, Input } from 'reactstrap';
import _ from 'lodash';
import { toast } from 'react-toastify';

import FalconCardHeader from '../../components/common/FalconCardHeader';
import { useSwotStore } from '../../store';
import PageLoader from '../../components/common/PageLoader';
import Select from '../../components/common/Select';
import WizardInput from '../../components/auth/wizard/WizardInput';

const bgColor = [null, 'rgba(115, 255, 236, 0.18)'];

const feedbackTypes = ['Inaccurate Information', 'Grammatical Error', 'Additions/Suggestions'];

const FeedbackForm = observer(({ setOpen }) => {
  const { swotStore } = useSwotStore();

  const { profile } = swotStore;

  const defaultState = {};
  if (profile && profile.emailId) {
    defaultState.emailId = profile.emailId;
  }

  const [state, setState] = useState({
    ...defaultState
  });

  const handleChange = field => e => {
    setState({
      ...state,
      [field]: e.target.value
    });
  };

  const onSubmit = () => {
    swotStore.createFeedback(state).then(response => {
      if (response.statusCode != 200) {
        toast.error("Feedback couldn't be submitted. Please try again");
      } else {
        toast.success('Feedback was submitted successfully.');
        setOpen(false);
      }
    });
  };

  const onJDSubmit = () => {
    const urls = window.location.pathname.split('/');
    const id = urls[urls.length - 1];
    const data = {
      ...state,
      jdId: id
    };
    swotStore.createJdFeedback(data).then(response => {
      if (response.statusCode != 200) {
        toast.error("Feedback couldn't be submitted. Please try again");
      } else {
        toast.success('Feedback was submitted successfully.');
        setOpen(false);
      }
    });
  };

  const submitHandler = () => {
    const isJD = window.location.pathname.includes('career-info/description');
    return isJD ? onJDSubmit() : onSubmit();
  };

  return (
    <Form>
      <FormGroup>
        <Label>Email</Label>
        <Input type="email" value={defaultState.emailId || ''} onChange={handleChange('emailId')} />
      </FormGroup>
      <FormGroup>
        <Label>Feedback Category</Label>
        <Select
          options={feedbackTypes.map(type => ({
            label: type,
            value: type
          }))}
          onChange={option => {
            setState({
              ...state,
              feedbackType: option.value
            });
          }}
        />
      </FormGroup>
      <FormGroup>
        <Label>Comments</Label>
        <Input onChange={handleChange('feedbackComment')} type="textarea" />
      </FormGroup>
      <Button disabled={state.feedbackComment == null} color="primary" onClick={submitHandler}>
        Submit Feedback
      </Button>
    </Form>
  );
});

export default FeedbackForm;
