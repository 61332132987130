import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Col,
  CustomInput,
  Label,
  Row
} from 'reactstrap';

import 'react-quill/dist/quill.snow.css';
import { useSwotStore } from '../../store/index';
import PageLoader from '../../components/common/PageLoader';
import FalconCardHeader from '../../components/common/FalconCardHeader';

const getContent = value => (value === '<p><br></p>' ? '' : value);

const EditJobDescription = observer(() => {
  const { id } = useParams();

  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState(null);
  const [subCategoryId, setSubCategoryId] = useState(null);
  const [subCategoryName, setSubCategoryName] = useState(null);
  const [categories, setCategories] = useState(null);
  const [jdId, setJdId] = useState(null);

  const { swotStore } = useSwotStore();

  useEffect(() => {
    swotStore.doFetchCareerInformation().then(response => {
      if (response.statusCode !== 200) {
        toast.error("Couldn't fetch the career information. Please try again.");
      } else {
        setCategories(response.result.map(category => category.jdSubCategorys).flat());
      }
    });
  }, []);

  const fetchContent = id => {
    swotStore.doFetchCareerDescInformation(id).then(response => {
      if (response.statusCode === 504) {
        setData('');
      } else if (response.statusCode !== 200) {
        toast.error("Couldn't fetch the career information. Please try again.");
      } else {
        setData(response.result.jdDescription);
        setJdId(response.result.jdId || null);
      }
    });
  };

  const submitContent = () => {
    swotStore.doUpdateCareerDescInformation(subCategoryId, jdId, data).then(response => {
      if (response.statusCode !== 200) {
        toast.error("Couldn't update the career information. Please try again.");
      } else {
        toast.success('Successfully updated the content');
        fetchContent(subCategoryId);
      }
    });
  };

  return categories == null ? (
    <PageLoader />
  ) : (
    <>
      <Card className="mb-3 h-100">
        <FalconCardHeader title="Update Job Descriptions" />
        <CardBody className="fs--1">
          {/* <Label>Select Category:</Label> */}
          <Dropdown className="ml-3 mb-4" isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
            <DropdownToggle caret className="btn btn-falcon-default">
              {subCategoryName != null ? subCategoryName : 'Select a Category'}
            </DropdownToggle>
            <DropdownMenu style={{ marginTop: '10px' }}>
              {categories.map(sub => (
                <DropdownItem
                  key={sub.jdSubCategoryId}
                  onClick={() => {
                    setSubCategoryId(sub.jdSubCategoryId);
                    setSubCategoryName(sub.jdSubCategoryName);
                    fetchContent(sub.jdSubCategoryId);
                  }}
                >
                  {sub.jdSubCategoryName}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
          {subCategoryId != null &&
            (data == null ? (
              <PageLoader />
            ) : (
              data != null && (
                <>
                  <ReactQuill value={getContent(data)} onChange={value => setData(value)} />
                  <div className="mt-3 text-center">
                    <Button
                      color="primary"
                      onClick={() => {
                        submitContent();
                      }}
                    >
                      Submit
                    </Button>
                  </div>
                </>
              )
            ))}
        </CardBody>
      </Card>
    </>
  );
});

export default EditJobDescription;
