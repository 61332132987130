import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Button,
  FormGroup
} from 'reactstrap';
import { toJS } from 'mobx';
import _ from 'lodash';

import { useSwotStore } from '../../../store/index';
import { getSelectedOption } from './util';

const MultiSelectionQuestions = observer(
  ({ currentTestId, questions, inlineOptions = false, inlineForm = false, maxLimit = 3 }) => {
    console.log('Questions: ', questions);

    const { swotStore } = useSwotStore();
    const { testToAnswersMap, setMultiAnswer } = swotStore;

    return (
      <>
        {questions.map((questionObj, index) => (
          <Card style={{ boxShadow: 'none' }}>
            <CardBody>
              <CardTitle
                style={questionObj.questionName != null && questionObj.questionName != '' ? {} : { display: 'inline' }}
              >
                {index + 1}.{' '}
                {questionObj.questionName != null && questionObj.questionName != '' && questionObj.questionName}
              </CardTitle>
              {questionObj.questionImage != null && questionObj.questionImage != '' && (
                <img src={questionObj.questionImage} alt={questionObj.questionId} />
              )}
              <CardText className="mt-2" style={inlineForm ? { display: 'inline' } : {}}>
                <FormGroup style={inlineForm ? { display: 'inline' } : {}}>
                  {questionObj.testQuestionOptions.map(option => (
                    <div className="ml-5 mb-3" style={inlineOptions ? { display: 'inline' } : {}}>
                      <Input
                        type="checkbox"
                        name={option.optionName + index}
                        key={option.optionId}
                        checked={getSelectedOption(
                          testToAnswersMap,
                          currentTestId,
                          questionObj.questionId,
                          option.optionId
                        )}
                        onChange={({ target }) => {
                          if (
                            !target.checked ||
                            canSelect(
                              testToAnswersMap,
                              currentTestId,
                              questionObj.questionId,
                              option.optionId,
                              maxLimit
                            )
                          ) {
                            setMultiAnswer(currentTestId, questionObj.questionId, option.optionId, !target.checked);
                          }
                        }}
                      />
                      <Label check style={{ marginTop: '2px' }}>
                        {option.optionName}
                      </Label>
                    </div>
                  ))}
                </FormGroup>
              </CardText>
            </CardBody>
          </Card>
        ))}
      </>
    );
  }
);

const canSelect = (testToAnswersMap, currentTestId, questionId, optionId, maxLimit) => {
  let test = testToAnswersMap[currentTestId] || {};
  if (_.isEmpty(test)) {
    return true;
  }
  let question = test[questionId] || [];
  if (_.isEmpty(question) || question.length < maxLimit) {
    return true;
  }
  return false;
};

export default MultiSelectionQuestions;
