import React, { useState, useEffect } from 'react';
import {
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardDeck,
  CardText,
  CardGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Collapse,
  Progress
} from 'reactstrap';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { Badge } from 'reactstrap';
import moment from 'moment';

import FalconCardHeader from '../components/common/FalconCardHeader';

import corner1 from '../assets/img/illustrations/corner-1.png';
import corner2 from '../assets/img/illustrations/corner-2.png';
import corner3 from '../assets/img/illustrations/corner-3.png';
import PreModule from './modules/PreModule';
import PostModule from './modules/PostModule';
import VideoModule from './modules/VideoModule';
import IntermediateModule from './modules/IntermediateModule';
import { useSwotStore } from '../store/index';
import PageLoader from '../components/common/PageLoader';
import PricingLayout from './pricing/PricingLayout';
import TestReportDownloader from './tests/TestReportDownloader';
import withRedirect from '../hoc/withRedirect';

const getImage = color => {
  switch (color) {
    case 'warning':
      return corner1;
    case 'info':
      return corner2;
    case 'success':
      return corner3;
    default:
      return corner1;
  }
};

/**
 * Author: Surya Prashanth
 */

const ModulesLayout = observer(({ match, setRedirect, setRedirectUrl, layout }) => {
  const [selectedId, setSelectedId] = useState(null);
  const [hideDropDown, setHideDropDown] = useState(false);
  const [savedModulesData, setSavedmodulesData] = useState({});

  const packageIdInUrl = match.params.id ?? null;

  useEffect(() => {
    setRedirectUrl(`/packages`);
  }, [setRedirectUrl, layout]);

  const activeStyle = {
    maxWidth: '300px',
    transform: 'translateY(-.6rem)',
    boxShadow: '0 1rem 4rem rgb(0 0 0 / 18%)',
    margin: '10px 30px'
  };

  const inactiveStyle = { maxWidth: '300px', margin: '10px 30px' };

  const onProductSelect = id => {
    if (selectedId == id) {
      setSelectedId(null);
    } else {
      setSelectedId(id);
    }
  };

  const { swotStore } = useSwotStore();
  const { selectedPackageId, setSelectedPackageId, setSelectedPurchaseId, setCurrentCategoryId } = swotStore;

  const { preVideos, postVideos, moduleLayoutTests } = swotStore;

  useEffect(() => {
    let modulesData = [
      {
        id: 'one',
        color: 'info',
        image: '/static/media/10.5c194d24.jpg',
        title: 'Section I',
        progress: getVideoModuleProgress(preVideos),
        state: null
      },
      {
        id: 'two',
        color: 'warning',
        image: '/static/media/10.5c194d24.jpg',
        title: 'Section II Assessment Module',
        progress: getIntermediateModuleProgress(moduleLayoutTests),
        state: null
      },
      {
        id: 'three',
        color: 'success',
        image: '/static/media/10.5c194d24.jpg',
        title: 'Section III',
        progress: getVideoModuleProgress(postVideos),
        state: null
      }
    ];

    // Pre Modules
    if (modulesData[0].progress !== 100) {
      modulesData[0].state = 'In progress';
    } else {
      modulesData[0].state = 'Completed';
      if (selectedId === 'one') {
        setSelectedId(null);
      }
    }

    // Test Modules
    if (modulesData[0].state === 'Completed' && modulesData[1].progress !== 100) {
      modulesData[1].state = 'In progress';
    } else if (modulesData[0].state === 'In progress') {
      modulesData[1].state = 'Locked';
    } else if (modulesData[1].progress === 100) {
      modulesData[1].state = 'Completed';
      if (selectedId === 'two') {
        setSelectedId(null);
      }
    }

    // Post Modules
    if (modulesData[1].state === 'Completed' && modulesData[2].progress !== 100) {
      modulesData[2].state = 'In progress';
    } else if (modulesData[1].state === 'In progress' || modulesData[1].state === 'Locked') {
      modulesData[2].state = 'Locked';
    } else if (modulesData[1].progress === 100) {
      modulesData[2].state = 'Completed';
    }

    setSavedmodulesData(modulesData);
  }, [preVideos, postVideos, moduleLayoutTests]);

  const [subscriptions, setSubscriptions] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPackageName, setSelectedPackageName] = useState(null);

  useEffect(() => {
    swotStore.doFetchSubscribedPackages().then(response => {
      if (response.statusCode === 404) {
        setSubscriptions([]);
      } else if (response.statusCode != 200) {
        toast.error("Couldn't fetch details. Please try again.");
      } else {
        setSubscriptions(response.result);
        if (packageIdInUrl == null && response.result.length > 1) return;
        let currentPackage = null;
        if (packageIdInUrl != null) {
          currentPackage = response.result.filter(pack => pack.testPackageId == packageIdInUrl)[0];
          window.history.pushState({}, '', '/modules');
        } else if (response.result.length === 1) {
          currentPackage = response.result[0];
          setHideDropDown(true);
        }
        setSelectedPackageId(currentPackage.testPackageId);
        setSelectedPurchaseId(currentPackage.purchaseId);
        setSelectedPackageName(
          `${currentPackage.testPackageName} - ${moment(currentPackage.purchaseDate).format('MMM Do YYYY')}`
        );
      }
    });
  }, []);

  useEffect(() => {
    if (selectedPackageId == null) {
      return;
    }

    swotStore.doFetchVideoModules('pre', selectedPackageId).then(response => {
      console.log('Fetched videos in pre modules');
    });
    swotStore.doFetchVideoModules('post', selectedPackageId).then(response => {
      console.log('Fetched videos in post modules');
    });
    swotStore.doLoadTestsListingPage().then(response => {
      if (response.statusCode != 200) {
        toast.error('Error loading the tests. Please try again.');
      }
    });
  }, [selectedPackageId]);

  if (subscriptions == null) {
    return <PageLoader />;
  }

  if (_.isEmpty(subscriptions)) {
    return <h5 className="mt-5 text-center">No packages purchased. Please visit dashboard page.</h5>;
  }

  return (
    <>
      <Card className="mb-3 h-100">
        <FalconCardHeader title="Modules" />
        <CardBody className="fs--1" style={{ minHeight: '400px' }}>
          {!hideDropDown && (
            <Dropdown className="ml-3 mb-4" isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
              <DropdownToggle caret className="btn btn-falcon-default">
                {selectedPackageName != null ? selectedPackageName : 'Select a package'}
              </DropdownToggle>
              <DropdownMenu>
                {subscriptions.map(sub => (
                  <DropdownItem
                    key={sub.purchaseId}
                    onClick={() => {
                      setSelectedPackageId(sub.testPackageId);
                      setSelectedPackageName(
                        `${sub.testPackageName} - ${moment(sub.purchaseDate).format('MMM Do YYYY')}`
                      );
                      setSelectedPurchaseId(sub.purchaseId);
                      setCurrentCategoryId(null);
                    }}
                  >
                    {sub.testPackageName} - {moment(sub.purchaseDate).format('MMM Do YYYY')}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          )}
          {selectedPackageId != null && (
            <CardDeck>
              {savedModulesData.map(module => {
                let classNames = '';
                let styles = {};
                let onClickHandler =
                  module.state == 'In progress'
                    ? () => {
                        onProductSelect(`${module.id}`);
                      }
                    : () => {};

                if (selectedId == module.id) {
                  styles = { ...activeStyle };
                } else {
                  styles = { ...inactiveStyle };
                }

                if (module.state === 'In progress') {
                  classNames += ' card-span cursor-pointer h-100';
                  onClickHandler = () => {
                    onProductSelect(`${module.id}`);
                  };
                } else {
                  styles.background = '#b5b1b1';
                }

                return (
                  <Card key={module.id} style={styles} className={classNames} onClick={onClickHandler}>
                    {/* <CardImg top src={module.image} alt="Card image cap" /> */}
                    {/* <Background image={getImage(module.color)} className="bg-card" /> */}
                    <CardBody>
                      <CardTitle tag="h5">{module.title}</CardTitle>
                      {module.text && <CardText style={{ marginTop: '200px' }}>{module.text}</CardText>}
                      <Badge color="soft-secondary" pill className="fs--2">
                        {module.state}
                        {/* <FontAwesomeIcon icon="check" transform="shrink-2" className=" ml-1" /> */}
                      </Badge>
                      {module.state === 'In progress' && (
                        <Progress animated className="mb-3 mt-3" color="info" value={module.progress} />
                      )}
                      {module.state === 'Completed' && module.id === 'two' && <TestReportDownloader />}
                    </CardBody>
                  </Card>
                );
              })}
            </CardDeck>
          )}
          <div className="mt-3">
            <Collapse isOpen={selectedId === 'one'}>
              <Card style={{ boxShadow: 'none' }}>
                <CardTitle className="p-3 text-center" tag="h5">
                  Pre Videos
                </CardTitle>
                <CardBody>
                  <VideoModule videos={preVideos} />
                </CardBody>
              </Card>
            </Collapse>
            <Collapse isOpen={selectedId === 'two'}>
              <Card style={{ boxShadow: 'none' }}>
                <CardTitle className="p-3 text-center" tag="h5">
                  Test Module
                </CardTitle>
                <CardBody>
                  <IntermediateModule />
                </CardBody>
              </Card>
            </Collapse>
            <Collapse isOpen={selectedId === 'three'}>
              <Card style={{ boxShadow: 'none' }}>
                <CardTitle className="p-3 text-center" tag="h5">
                  Post Videos
                </CardTitle>
                <CardBody>
                  <VideoModule videos={postVideos} />
                </CardBody>
              </Card>
            </Collapse>
          </div>
        </CardBody>
      </Card>
    </>
  );
});

const getVideoModuleProgress = videos => {
  if (_.isEmpty(videos)) {
    return 0;
  }

  const total = videos.length;
  const completed = videos.filter(video => video.videoWatched == true).length;

  return (completed / total) * 100;
};

const getIntermediateModuleProgress = tests => {
  if (_.isEmpty(tests)) {
    return 0;
  }

  const total = tests.length;
  const completed = tests.filter(test => test.categorySubmitStatus == true).length;

  return (completed / total) * 100;
};

export default withRedirect(ModulesLayout);
