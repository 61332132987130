import React from 'react';
import { observer } from 'mobx-react-lite';

import SettingsChangePassword from '../components/Settings/SettingsChangePassword';

const ChangePasswordLayout = observer(() => {
  return (
      <SettingsChangePassword />
  );
});

export default ChangePasswordLayout;
