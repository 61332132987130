import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Card, CardBody } from 'reactstrap';
import _ from 'lodash';
import { toast } from 'react-toastify';

import FalconCardHeader from '../../components/common/FalconCardHeader';
import { useSwotStore } from '../../store';
import Coupon from './Coupon';
import PageLoader from '../../components/common/PageLoader';
import withRedirect from '../../hoc/withRedirect';

const UpdateCoupon = observer(({ match, setRedirect, setRedirectUrl, layout }) => {
  const id = match.params.id;

  const { swotStore } = useSwotStore();
  const [couponObj, setCouponObj] = useState(null);

  const fetchCoupon = id => {
    setCouponObj(null);
    swotStore.doFetchCoupons(id).then(response => {
      if (response.statusCode !== 200) {
        toast.error("Couldn't fetch the coupon for updating");
        setCouponObj({});
      } else {
        setCouponObj(response.result);
      }
    });
  };

  useEffect(() => {
    fetchCoupon(id);
  }, []);

  // useEffect(() => {
  //   setRedirectUrl(`/admin/coupons/list`);
  // }, [setRedirectUrl, layout]);

  return (
    <Card className="mb-3">
      <CardBody>
        <FalconCardHeader title="Update Coupon" light={false} className="text-center" />
        <div className="mt-4">
          {couponObj == null ? (
            <PageLoader />
          ) : _.isEmpty(couponObj) ? (
            <b> Couldn't find a coupon </b>
          ) : (
            <Coupon couponObj={couponObj} />
          )}
        </div>
      </CardBody>
    </Card>
  );
});

export default withRedirect(UpdateCoupon);
