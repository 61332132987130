import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Card, CardBody, Col, CustomInput, Label, Row, Table } from 'reactstrap';
import _ from 'lodash';

import FalconCardHeader from '../../components/common/FalconCardHeader';
import { useSwotStore } from '../../store';
import Coupon from './Coupon';

const CreateCoupon = observer(() => {
  const { swotStore } = useSwotStore();

  return (
    <Card className="mb-3">
      <CardBody>
        <FalconCardHeader title="Create Coupon" light={false} className="text-center" />
        <Coupon />
      </CardBody>
    </Card>
  );
});

export default CreateCoupon;
