import React, { useState, useEffect, createRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Card, CardBody, Col, CustomInput, Label, Row } from 'reactstrap';
import _ from 'lodash';
import { toast } from 'react-toastify';
import className from 'classnames';
import { Link } from 'react-router-dom';

import FalconCardHeader from '../components/common/FalconCardHeader';
import { useSwotStore } from '../store';
import PageLoader from '../components/common/PageLoader';
import CardService from '../components/landing/CardService';
import { Packages } from './pricing/Packages';
import { isB2B } from '../helpers/envUtils';

const serviceList = [
  {
    title: 'What you will learn',
    description: `
    <ul class="ul1">
    <li class="li1"><span class="s1">Crafting a Study Plan that suits you</span></li>
    <li class="li1"><span class="s1">How to tackle issues like forgetfulness, Procrastination, etc</span></li>
    <li class="li1"><span class="s1">How to study smarter and better</span></li>
    <li class="li1"><span class="s1">How to manage your time better</span></li>
    <li class="li1"><span class="s1">How to manage your stress better</span></li>
    <li class="li1"><span class="s1">How to face exams confidently</span></li>
    <li class="li1"><span class="s1">How to GENERATE interest in subjects like Maths, History, etc</span></li>    
    </ul>`
  },
  {
    title: 'What’s included',
    description: `
    <ul class="ul1">
    <li class="li1"><span class="s1">17 Training Modules; 3 Assessments; 3 Bonus materials</span></li>
    <li class="li1"><span class="s1">Weekly Progress Tracker</span></li>
    <li class="li1"><span class="s1">Personal Dashboard with progress tracker</span></li>
    <li class="li1"><span class="s1">Virtual live workshops on important competitive exams</span></li>
    <li class="li1"><span class="s1">Virtual live Q&A sessions</span></li>
    <li class="li1"><span class="s1">Study Hacks and tips</span></li>
    <li class="li1"><span class="s1">Continual support</span></li>    
    </ul>`
  }
];

const DashboardContentLayout = observer(() => {
  const { swotStore } = useSwotStore();

  const { profile } = swotStore;

  const isCompleted = profile != null && profile.mobileNo != null;

  const packagesDiv = createRef();

  return (
    <Card className="mb-3">
      <CardBody>
        <p>
          Professional success depends chiefly on two factors: <b>choosing</b> the right career path and{' '}
          <b>preparing</b> for success in that path.
        </p>
        <h4>WHY CHOOSING THE RIGHT CAREER PATH is important?</h4>
        <p>
          <b>It’s a competitive world</b> and therefore identifying one’s abilities and shortcomings at a young age is
          most desirable to ensure one has a successful career. Choosing a subject stream is very important as it is the
          stepping-stone towards a given career path and often times causes confusion which in turn invites a lot of
          mental distress. Some common dilemmas that most youngsters these days encounter are:
        </p>
        <div>
          <p>
            <span>Math or Biology?</span>
          </p>
          <p>
            <span>Science or Commerce?</span>
          </p>
          <p>
            <span>B.tech or B.A?</span>
          </p>
          <p>
            <span>Corporate job or Government job?</span>
          </p>
          <p>
            <span>
              Have any of the afore-mentioned questions ever been on your mind and puzzled you? Have you ever been faced
              with making a life-altering decision and got nervous about the implications of taking a wrong decision?
              Did you ever feel the need to take someone’s help to walk you through every step of the process?
            </span>
          </p>
          <p>
            <span>
              Many schools offer students the choice to opt for or drop a few subjects from the 8th grade itself.
              Especially after the 10th grade a young student is faced with the daunting dilemma of choosing a specific
              subject stream. When faced with such a seemingly intimidating situation the young student often tends to
              merely emulate an older successful student’s career path or tends to take a misinformed well-wisher’s
              advice and much later come to repent his/her decision if it isn’t the best fit for him/her.
            </span>
          </p>
          <p>
            <span>
              At SWOT Careers, we go beyond traditional career counselling by incubating you and guiding you through a
              series of videos, webinars, workshops, self-assessment checklists to help you understand more about the
              world of careers.
            </span>
          </p>
          <h5 className="mt-4 mb-4">
            What works for others may not work for you. You need to know what works for you!{' '}
          </h5>
          <p>
            <span>
              We conduct multiple psychometric assessments to get an in-depth understanding about your abilities,
              personality, interests, career inspirations, learning style, and more and map the results with over 500
              professions available today for a holistic analysis.
            </span>
          </p>
          <p>
            <span>
              Our Program takes you from the fundamentals of careers to the factors that influence one’s careers and
              walk you through our power-packed program which hosts a lot of information about every career stream. By
              joining this program, you will not only gain an in-depth understanding about how to choose a career, but
              you also gain knowledge about various career streams that are available today.
            </span>
          </p>
          <Card>
            <CardBody>
              <p className="font-weight-semi-bold text-1000">Program Name: Career Planning for High School</p>
              <Button disabled>Coming Soon</Button>
            </CardBody>
          </Card>
        </div>
        <div>
          <h5 className="mt-4 mb-4">PREPARING FOR SUCCESS IN THAT CAREER</h5>
          <p>
            No matter what career you choose, in today’s world, you are required to qualify either an entrance exam or
            some form of assessment to be able to pursue that career.
          </p>
          <p>
            For ex, people who want to become doctors need to take NEET, people who want pursue an MBA need to take CAT,
            GMAT or relevant exams, Fashion designing aspirants should clear NIFT or relevant exams, people who want to
            enter defense services need to clear NDA, CDS or relevant exams, and same is the case with many careers. No
            matter how good of a doctor or lawyer or a soldier you can be, if you cannot qualify the entrance exam,
            chances are you may probably not be able to pursue that career (with little exceptions though).
          </p>
          <p>
            Now, to be able to qualify the entrance exam, you need to master your study habits so you can perform to
            your fullest potential and achieve the career you want which is why study habits are especially important.
          </p>
          <p>
            Optimizing your study practices mean you can retain more , get better results from your study efforts and
            thereby be able to crack those exams with confidence and certainty.
          </p>
        </div>
        <Card>
          <CardBody>
            <p className="font-weight-semi-bold text-1000">Program Name: Advanced Study Enhancement Program</p>
            <Button
              className="mr-4"
              color="primary"
              onClick={event => {
                //.current is verification that your element has rendered
                if (packagesDiv.current) {
                  packagesDiv.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest'
                  });
                }
              }}
              disabled={isB2B()}
            >
              Buy now
            </Button>
            <Link to="/products">Learn more</Link>
          </CardBody>
        </Card>
        <Row className="mt-6">
          {serviceList.map((service, index) => (
            <Col lg={6} className={className({ 'mt-6 mt-lg-0': index > 0 })} key={index}>
              <CardService {...service} />
            </Col>
          ))}
        </Row>
        {!isB2B() && (
          <div className="mt-2" ref={packagesDiv}>
            <Card>
              <CardBody>
                {!isCompleted && (
                  <h5 className="text-center">
                    Profile doesn't seem to be completed. Please update the profile{' '}
                    <Link to="/update-profile">here</Link> to purchase.
                  </h5>
                )}
                <div style={{ opacity: !isCompleted ? 0.5 : null }}>
                  <Packages disabled={!isCompleted} />
                </div>
              </CardBody>
            </Card>
            {/* <Packages disabled={!isCompleted} /> */}
          </div>
        )}
      </CardBody>
    </Card>
  );
});

export default DashboardContentLayout;
