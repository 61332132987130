import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Button,
  FormGroup
} from 'reactstrap';
import { toJS } from 'mobx';

import { useSwotStore } from '../../../store/index';
import { getSelectedOption } from './util';

const TextQuestionSingleOption = observer(({ currentTestId, questions, inlineOptions = false, inlineForm = false }) => {
  console.log('Questions: ', questions);

  const { swotStore } = useSwotStore();
  const { testToAnswersMap, setAnswer } = swotStore;

  return (
    <>
      {questions.map((questionObj, index) => (
        <Card style={{ boxShadow: 'none' }}>
          <CardBody>
            <CardTitle
              style={questionObj.questionName != null && questionObj.questionName != '' ? {} : { display: 'inline' }}
            >
              {index + 1}.{' '}
              {questionObj.questionName != null && questionObj.questionName != '' && questionObj.questionName}
              <Input
                type="checkbox"
                className="ml-3"
                name={index}
                key={index}
                checked={getSelectedOption(
                  testToAnswersMap,
                  currentTestId,
                  questionObj.questionId,
                  questionObj.testQuestionOptions[0].optionId
                )}
                onChange={({ target }) => {
                  let option = target.checked ? questionObj.testQuestionOptions[0].optionId : null;
                  setAnswer(currentTestId, questionObj.questionId, option);
                }}
              />
            </CardTitle>
          </CardBody>
        </Card>
      ))}
    </>
  );
});

export default TextQuestionSingleOption;
