import React from 'react';
import { Spinner } from 'reactstrap';

const PageLoader = ({ classNames = 'mt-11 text-center', loading = false }) => {
  return (
    <div className={classNames}>
      <Spinner color="primary" />
      {loading && <h5 className="mt-2"> Loading... </h5>}
    </div>
  );
};

export default PageLoader;
