import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Card, CardBody, Col, CustomInput, Label, Row, Table } from 'reactstrap';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';

import FalconCardHeader from '../../components/common/FalconCardHeader';
import { useSwotStore } from '../../store';
import PageLoader from '../../components/common/PageLoader';

const ListCoupons = observer(() => {
  const { swotStore } = useSwotStore();
  const [coupons, setCoupons] = useState([]);

  useEffect(() => {
    swotStore.doFetchCoupons().then(response => {
      if (response.statusCode != 200) {
        toast.error("Couldn't fetch coupons. Please try again.");
      } else {
        setCoupons(response.result);
      }
    });
  }, []);

  if (_.isEmpty(coupons)) {
    return <PageLoader />;
  }

  return (
    <Card className="mb-3">
      <CardBody>
        <FalconCardHeader title="Available Coupons" light={false} className="text-center" />
        <div>
          <Table>
            <thead>
              <tr>
                <th>#</th>
                <th>Coupon Code</th>
                <th>Coupon Name</th>
                <th>Coupon Discount</th>
                <th>Discount Type</th>
                <th>Packages</th>
                <th>Expiry Date</th>
                <th>Threshold Value</th>
                <th>Is Active</th>
              </tr>
            </thead>
            <tbody>
              {coupons.map((coupon, index) => (
                <tr key={index}>
                  <td>
                    <Link to={`/admin/coupons/update/${coupon.couponId}`}>Edit</Link>
                  </td>
                  <td>{coupon.couponCode}</td>
                  <td>{coupon.couponName}</td>
                  <td>{coupon.couponDiscount}</td>
                  <td>{coupon.couponDiscountType}</td>
                  <td>{_.isEmpty(coupon.testPackageIds) ? '' : coupon.testPackageIds.join(',')}</td>
                  <td>{coupon.expireDate != null ? moment(coupon.expireDate).format('LLL') : ''}</td>
                  <td>{coupon.thresholdValue}</td>
                  <td>{coupon.isActive}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
});

export default ListCoupons;
