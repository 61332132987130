import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Card, CardBody, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';

import { useSwotStore } from '../store';
import FalconCardHeader from '../components/common/FalconCardHeader';
import CategoriesTimeLine from './tests/CategoriesTimeLine';
import TestsListing from './tests/TestsListing';
import TestTakingLayout from './tests/TestTakingLayout';
import TestTakingModal from './tests/TestTakingModal';

const TestsLayout = observer(() => {
  const { swotStore } = useSwotStore();
  const { tests, currentCategoryId, setCurrentCategoryId, currentTestId } = swotStore;

  const [instructionTestId, setInstructionTestId] = useState(null);

  return (
    <>
      <Card className="mb-3 h-100">
        <FalconCardHeader title="Tests" />
        <CardBody className="fs--1" style={{ minHeight: '500px' }}>
          <div>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/modules">Modules</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>Tests</BreadcrumbItem>
            </Breadcrumb>
          </div>
          <CategoriesTimeLine />
          <TestsListing
            currentCategoryId={currentCategoryId}
            testId={instructionTestId}
            setTestId={setInstructionTestId}
          />
          {/* <TestTakingLayout currentTestId={currentTestId} /> */}
          {currentTestId != null && (
            <TestTakingModal currentTestId={currentTestId} setInstructionTestId={setInstructionTestId} />
          )}
        </CardBody>
      </Card>
    </>
  );
});

export default TestsLayout;
