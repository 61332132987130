import React, { useContext, useState } from 'react';
import { Collapse, Navbar, NavItem, Nav, NavLink, NavbarToggler } from 'reactstrap';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import _ from 'lodash';

import AppContext from '../../context/Context';
import Logo from './Logo';
import SearchBox from './SearchBox';
import NavbarProfileRight from './NavbarProfileRight';
import NavbarTopDropDownMenus from './NavbarTopDropDownMenus';
import { navbarBreakPoint, topNavbarBreakpoint } from '../../config';
import autoCompleteInitialItem from '../../data/autocomplete/autocomplete';
import ProfileDropdown from './ProfileDropdown';
import LandingRightSideNavItem from './LandingRightSideNavItem';

import { useSwotStore } from '../../store';

/**
 * Author: Surya Prashanth
 */

const NavbarProfile = observer(() => {
  const [navbarCollapsed, setNavbarCollapsed] = useState(true);

  const { swotStore } = useSwotStore();

  const { token } = swotStore;

  return (
    <Navbar
      light
      className="navbar-glass fs--1 font-weight-semi-bold row navbar-top sticky-kit"
      expand={topNavbarBreakpoint}
    >
      <NavbarToggler className="ml-1 mr-3" onClick={() => setNavbarCollapsed(!navbarCollapsed)} />
      <Logo at="navbar-top" width={100} id="topLogo" />
      <Collapse isOpen={!navbarCollapsed} navbar className="scrollbar">
        <Nav navbar>
          <NavbarTopDropDownMenus setNavbarCollapsed={setNavbarCollapsed} />
        </Nav>
      </Collapse>
      <Nav navbar className="navbar-nav-icons ml-auto flex-row align-items-center">
        {token != null && !_.isEmpty(token) ? <ProfileDropdown /> : <LandingRightSideNavItem />}
      </Nav>
    </Navbar>
  );
});

export default NavbarProfile;
