import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Datetime from 'react-datetime';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import _ from 'lodash';
import moment from 'moment';

import { useSwotStore } from '../../store/index';
import Select from '../../components/common/Select';

const TODAY = moment();

const Coupon = observer(({ couponObj }) => {
  const [data, setData] = useState({
    couponDiscountType: 'RSOFF',
    isActive: 0,
    ...couponObj
  });

  const [error, setError] = useState({});

  const [packages, setPackages] = useState([
    {
      label: 'Select All',
      value: -1
    }
  ]);
  const selectedPackages = _.isEmpty(data.testPackageIds)
    ? []
    : packages.filter(pack => data.testPackageIds.includes(pack.value));

  const type = couponObj == null ? 'create' : 'update';

  const { swotStore } = useSwotStore();

  useEffect(() => {
    swotStore.doFetchPackages().then(response => {
      setPackages([
        {
          label: 'Select All',
          value: -1
        },
        ...response.result.map(pack => ({
          label: pack.packageName,
          value: pack.packageId
        }))
      ]);
    });
  }, []);

  const handleChange = fieldName => e => {
    const value = e.target.value;
    console.log(fieldName, value);
    setData({
      ...data,
      [fieldName]: value
    });
  };

  const upsertCoupon = () => {
    // console.log(data);
    setError({});
    // Validations
    const mandatoryFields = ['couponName', 'couponCode', 'testPackageIds'];
    let isValid = true;
    let errors = {};
    mandatoryFields.forEach(field => {
      if (_.isEmpty(data[field])) {
        errors[field] = `${field} is mandatory`;
        isValid = false;
      }
    });
    if (isNaN(data.couponDiscount) || Number(data.couponDiscount) <= 0) {
      errors['couponDiscount'] = `couponCode should be a number greater than 0`;
    }

    setError(errors);

    if (!isValid) return;

    swotStore.doUpsertCoupons(type, data).then(response => {
      if (response.statusCode !== 200) {
        toast.error(`Couldn't ${type} coupon`);
      } else {
        toast.success(`Coupon ${type} Success`);
        if (type === 'create') {
          setData({
            couponDiscountType: 'RSOFF',
            isActive: 0
          });
        }
      }
    });
  };

  console.log('Data change', data);

  const setSelectedPackages = selectedObjs => {
    if (_.isEmpty(selectedObjs)) {
      setData({
        ...data,
        testPackageIds: []
      });
      return;
    }
    const selectedAll = selectedObjs.filter(obj => obj.value === -1);
    let testPackageIds = [];
    if (!_.isEmpty(selectedAll)) {
      testPackageIds = packages.filter(obj => obj.value !== -1).map(obj => obj.value);
    } else {
      testPackageIds = selectedObjs.map(obj => obj.value);
    }

    setData({
      ...data,
      testPackageIds
    });
  };

  return (
    <Form>
      <FormGroup>
        <Label for="couponName">Coupon Name</Label>
        <Input
          type="text"
          name="name"
          id="couponName"
          placeholder="Name"
          value={data.couponName || ''}
          required
          onChange={handleChange('couponName')}
        />
        {error.couponName != null && <p className="text-danger">{error.couponName}</p>}
      </FormGroup>
      <FormGroup>
        <Label for="couponCode">Coupon Code</Label>
        <Input
          type="text"
          name="couponCode"
          id="couponCode"
          disabled={type === 'update'}
          value={data.couponCode || ''}
          onChange={handleChange('couponCode')}
        />
        {error.couponCode != null && <p className="text-danger">{error.couponCode}</p>}
      </FormGroup>
      <FormGroup>
        <Label for="discount">Coupon Discount Amount</Label>
        <Input
          type="number"
          name="discount"
          id="discount"
          value={data.couponDiscount || ''}
          onChange={handleChange('couponDiscount')}
        />
        {error.couponDiscount != null && <p className="text-danger">{error.couponDiscount}</p>}
      </FormGroup>
      <FormGroup>
        <Label for="packages">Packages applicable</Label>
        {/* <Input type="select" name="packages" id="packages" multiple onChange={handleChange('testPackageIds')}>
          {packages.map(packageObj => (
            <option value={packageObj.packageId}> {packageObj.packageName} </option>
          ))}
        </Input> */}
        <div className="border">
          <Select
            styles={{
              border: '1px solid #d8e2ef !important'
            }}
            isMulti
            options={packages}
            value={selectedPackages}
            onChange={setSelectedPackages}
          />
        </div>
        {error.testPackageIds != null && <p className="text-danger">{error.testPackageIds}</p>}
      </FormGroup>
      <FormGroup>
        <Label for="threshold">Threshold</Label>
        <Input
          type="number"
          name="threshold"
          id="threshold"
          placeholder="Threshold"
          value={data.thresholdValue || ''}
          onChange={handleChange('thresholdValue')}
        />
      </FormGroup>
      <FormGroup>
        <Label for="date">Expiry Date</Label>
        <Datetime
          timeFormat={false}
          value={data.expireDate != null ? moment(data.expireDate) : null}
          onChange={date => {
            // console.log('Date selected ', date, date.toISOString());
            setData({
              ...data,
              expireDate: typeof date !== 'string' ? date.toISOString() : null
            });
          }}
          isValidDate={current => {
            return current.isAfter(TODAY);
          }}
        />
      </FormGroup>
      <FormGroup>
        <Label for="exampleSelect">Discount Type</Label>
        <Input
          type="select"
          name="select"
          id="exampleSelect"
          value={data.couponDiscountType || ''}
          onChange={handleChange('couponDiscountType')}
        >
          <option value="RSOFF">Rupees Off</option>
          <option value="PERCENTOFF">Percentage off</option>
        </Input>
      </FormGroup>
      <FormGroup className="form-check">
        <Input
          type="checkbox"
          name="check"
          id="exampleCheck"
          checked={data.isActive === 1}
          onChange={e => {
            setData({
              ...data,
              isActive: e.target.checked ? 1 : 0
            });
          }}
        />
        <Label for="exampleCheck" check>
          Is Active
        </Label>
      </FormGroup>
      <Button
        color="primary"
        onClick={() => {
          upsertCoupon();
        }}
      >
        Save
      </Button>
    </Form>
  );
});

export default Coupon;
