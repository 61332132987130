import React from 'react';
import PropTypes from 'prop-types';
import className from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardBody } from 'reactstrap';

const CardService = ({ image, media, title, description, children }) => (
  <Card className="card-span h-100">
    {media != null && (
      <div className="card-span-img">
        {/* <FontAwesomeIcon
        icon={media.icon}
        className={className({ [`text-${media.color}`]: media.color }, media.className)}
      /> */}
        <img src={image} />
      </div>
    )}
    <CardBody className={className({ 'pb-4': true, 'pt-6': media != null })}>
      <h5 className="mb-2">{title}</h5>
      {/* {description && <p>{description}</p>} */}
      {description && <div className="text-left pt-4" dangerouslySetInnerHTML={{ __html: description }} />}
      {children}
    </CardBody>
  </Card>
);

CardService.propTypes = {
  media: PropTypes.shape({
    icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
    color: PropTypes.string.isRequired,
    className: PropTypes.string
  }),
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.node
};

export default CardService;
