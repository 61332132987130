import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Dashboard from '../components/dashboard/Dashboard';
import DashboardAlt from '../components/dashboard-alt/DashboardAlt';
import NavbarTop from '../components/navbar/NavbarTop';
import NavbarProfile from '../components/navbar/NavbarProfile';
import NavbarVertical from '../components/navbar/NavbarVertical';
import Footer from '../components/footer/Footer';
import loadable from '@loadable/component';
import AppContext from '../context/Context';
import ProductProvider from '../components/e-commerce/ProductProvider';
import SidePanelModal from '../components/side-panel/SidePanelModal';
import { getPageName } from '../helpers/utils';

import ProductsLayout from './ProductsLayout';
import ModulesLayout from './ModulesLayout';
import ProfileLayout from './ProfileLayout';
import ChangePasswordLayout from './ChangePasswordLayout';
import TestsLayout from './TestsLayout';
import JDLayout from './JDLayout';
import AdminLayout from './admin/AdminLayout';
import withLoggedIn from '../hoc/withLoggedIn';
import JDDescLayout from './JDDescLayout';
import { useSwotStore } from '../store/index';
import PageLoader from '../components/common/PageLoader';
import PricingLayout from './pricing/PricingLayout';
import OrderLayout from './pricing/OrderLayout';
import AboutLayout from './AboutLayout';
import ContactLayout from './ContactLayout';
import TransactionHistory from './transactions/TransactionHistory';
import DashboardContentLayout from './DashboardContentLayout';
import { FeedbackComponent } from './feedback/index';
import FooterStandard from '../components/landing/FooterStandard';
import AnalyticsDashboard from './b2badmin/AnalyticsDashboard';

const DashboardRoutes = loadable(() => import('./DashboardRoutes'));

const DashboardLayout = observer(({ location }) => {
  const { isFluid, isVertical, navbarStyle } = useContext(AppContext);
  const [isLoading, setLoading] = useState(true);

  const { swotStore } = useSwotStore();

  const isKanban = getPageName('kanban');

  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);

  useEffect(() => {
    DashboardRoutes.preload();
    if (swotStore.token == null) {
      setLoading(true);
      swotStore.doLogin(null, null, true).then(response => {
        if (response.statusCode === 200) {
          swotStore.doFetchProfile().then(response => {
            setLoading(false);
          });
        } else {
          setLoading(false);
        }
      });
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className={isFluid || isKanban ? 'container-fluid' : 'container'} style={{ height: '100%' }}>
      <ProductProvider>
        {/* Need to add vh-100 for belwo div to make it take full height */}
        <div className="content">
          {/* <NavbarTop /> */}
          <NavbarProfile />
          {isLoading ? (
            <div style={{ minHeight: '400px' }}>
              <PageLoader />
            </div>
          ) : (
            <>
              <FeedbackComponent isOpen={isFeedbackOpen} setOpen={setIsFeedbackOpen} />
              <Switch>
                <Route path="/career-info/description/:id" exact component={withLoggedIn(JDDescLayout)} />
                <Route path="/career-info" exact component={withLoggedIn(JDLayout)} />
                <Route path="/admin" component={withLoggedIn(AdminLayout)} />
                <Route path="/analytics" component={withLoggedIn(AnalyticsDashboard)} />
                <Route path="/dashboard" exact component={withLoggedIn(DashboardContentLayout)} />
                <Route path="/dashboard-alt" exact component={DashboardAlt} />
                <Route path="/products" exact component={ProductsLayout} />
                <Route path="/modules/" exact component={withLoggedIn(ModulesLayout)} />
                <Route path="/modules/:id" exact component={withLoggedIn(ModulesLayout)} />
                <Route path="/tests" exact component={withLoggedIn(TestsLayout)} />
                <Route path="/about" exact component={AboutLayout} />
                <Route path="/contact" exact component={ContactLayout} />
                <Route path="/update-profile" exact component={withLoggedIn(ProfileLayout)} />
                <Route path="/change-password" exact component={withLoggedIn(ChangePasswordLayout)} />
                <Route path="/transactions" exact component={withLoggedIn(TransactionHistory)} />
                <Route path="/packages" exact component={withLoggedIn(PricingLayout)} />
                <Route path="/packages/purchase/:id" exact component={withLoggedIn(OrderLayout)} />
                <DashboardRoutes />
              </Switch>
            </>
          )}
          {/* <Footer /> */}
          <FooterStandard lightMode={true} />
        </div>
        {/* <SidePanelModal path={location.pathname} /> */}
      </ProductProvider>
      <div
        className="cursor-pointer"
        style={{ position: 'fixed', bottom: '25px', right: '25px' }}
        onClick={() => {
          setIsFeedbackOpen(true);
        }}
      >
        <i
          className="fa fa-comments"
          style={{
            display: 'inline-block',
            borderRadius: '60px',
            boxShadow: '0px 0px 2px #888',
            padding: '0.5em 0.6em',
            fontSize: '30px'
          }}
        />
      </div>
    </div>
  );
});

DashboardLayout.propTypes = { location: PropTypes.object.isRequired };

export default DashboardLayout;
