import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Form, Row, FormGroup, Label, Input, TextArea } from 'reactstrap';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';
import _ from 'lodash';
import moment from 'moment-timezone';

import FalconCardHeader from '../common/FalconCardHeader';
import FormGroupInput from '../common/FormGroupInput';
import FormGroupSelect from '../common/FormGroupSelect';
import PageLoader from '../common/PageLoader';
import Flex from '../common/Flex';
import { useSwotStore } from '../../store';
import WizardInput from '../auth/wizard/WizardInput';
import withRedirect from '../../hoc/withRedirect';

const YESTERDAY = moment().subtract('day', 1);

const SettingsProfile = observer(({ setRedirect, setRedirectUrl, hasLabel, layout }) => {
  const [profile, setProfile] = useState({});
  const [initialProfile, setInitialProfile] = useState({});
  const [languages, setLanguages] = useState([]);
  const [qualifications, setQualifications] = useState([]);
  const [errors, setErrors] = useState({});

  const { swotStore } = useSwotStore();

  const handleInputChange = fieldName => ({ target }) => {
    console.log('Changing ', fieldName, target);
    setErrors({ ...errors, [fieldName]: null });
    setProfile({ ...profile, [fieldName]: target.value });
  };

  useEffect(() => {
    swotStore.doFetchProfile().then(response => {
      if (response.statusCode != 200) {
        toast.error('Unable to fetch profile details. Please reload the page.');
      } else {
        setProfile(response.result);
        setInitialProfile(response.result);
      }
    });

    swotStore.doFetchLanguages().then(response => {
      if (response.statusCode == 200) {
        let options = response.result.map(lang => ({
          label: lang.langName,
          value: lang.langId
        }));
        options = [{ label: 'Select', value: 'null' }, ...options];
        setLanguages(options);
      }
    });

    swotStore.doFetchQualifications().then(response => {
      if (response.statusCode == 200) {
        let options = response.result.map(lang => ({
          label: lang.qualificationName,
          value: lang.qualificationId
        }));
        options = [{ label: 'Select', value: 'null' }, ...options];
        setQualifications(options);
      }
    });
  }, []);

  useEffect(() => {
    setRedirectUrl(`/modules`);
  }, [setRedirectUrl, layout]);

  const handleProfileSettings = e => {
    e.preventDefault();
    const isValid = validateData(profile);
    if (isValid) {
      swotStore.doUpdateProfile(profile).then(response => {
        if (response.statusCode != 200) {
          toast.error('Unable to update profile details. Please try again.');
        } else {
          toast.success('Successfully updated the profile details.');
          swotStore.doFetchProfile().then(response => {
            setRedirect(true);
          });
        }
      });
    }
  };

  const validateData = profile => {
    let validations = {};
    let fields = [
      'firstName',
      'lastName',
      'mobileNo',
      'gender',
      'preferedLang',
      'dateOfBirth',
      'highestEducation',
      'city',
      'state',
      'pinCode',
      'address'
    ];
    console;
    fields.forEach(field => {
      if (profile[field] == null || profile[field] == '' || profile[field] == 'null') {
        validations[field] = 'This field is required';
      }
    });

    if (/[^a-zA-Z \-'\/]/.test(profile.firstName)) {
      validations['firstName'] = "Must contain alphabets with special character ' only";
    }

    if (/[^a-zA-Z \-'\/]/.test(profile.lastName)) {
      validations['lastName'] = "Must contain alphabets with special character ' only";
    }

    if (profile.mobileNo != null && (profile.mobileNo.length != 10 || /[^0-9]/.test(profile.mobileNo))) {
      validations['mobileNo'] = 'Mobile Number contain numbers of 10 digits';
    }

    profile.pinCode += '';
    if (profile.pinCode != null && (profile.pinCode.length != 6 || /[^0-9]/.test(profile.pinCode))) {
      validations['pinCode'] = 'Pincode should contain 6 digits with numbers only';
    }

    if (/[^a-zA-Z \/]/.test(profile.state)) {
      validations['state'] = 'Must contain alphabets only';
    }

    if (/[^a-zA-Z \/]/.test(profile.city)) {
      validations['city'] = 'Must contain alphabets only';
    }
    console.log('Validations', validations);

    setErrors(validations);
    if (_.isEmpty(validations)) {
      return true;
    }

    return false;
  };

  if (_.isEmpty(profile)) {
    return <PageLoader />;
  }

  const states = [
    'Andaman and Nicobar Islands',
    'Andhra Pradesh',
    'Arunachal Pradesh',
    'Assam',
    'Bihar',
    'Chandigarh',
    'Chhattisgarh',
    'Dadra and Nagar Haveli',
    'Delhi',
    'Goa',
    'Gujarat',
    'Haryana',
    'Himachal Praddesh',
    'Himachal Pradesh',
    'Jammu and Kashmir',
    'Jharkhand',
    'Karnataka',
    'Kerala',
    'Madhya Pradesh',
    'Maharashtra',
    'Manipur',
    'Meghalaya',
    'Mizoram',
    'Nagaland',
    'Odisha',
    'Puducherry',
    'Punjab',
    'Rajasthan',
    'Tamil Nadu',
    'Telangana',
    'Tripura',
    'Uttar Pradesh',
    'Uttarakhand',
    'West Bengal'
  ];

  return (
    <Card className="mb-3">
      <FalconCardHeader title="Profile Settings" light={false} />
      <CardBody className="bg-light">
        <Form onSubmit={handleProfileSettings}>
          <Row>
            <Col lg={6}>
              <FormGroupInput
                id="first-name"
                label="First Name*"
                name="firstName"
                value={profile.firstName || ''}
                onChange={handleInputChange('firstName')}
                validationError={errors.firstName || null}
              />
            </Col>
            <Col lg={6}>
              <FormGroupInput
                id="last-name"
                label="Last Name*"
                required
                value={profile.lastName || ''}
                onChange={handleInputChange('lastName')}
                validationError={errors.lastName || null}
              />
            </Col>
            <Col lg={6}>
              <FormGroupInput id="email" label="Email*" value={profile.emailId || ''} type="email" disabled />
            </Col>
            <Col lg={6}>
              <FormGroupInput
                id="phone"
                label="Phone*"
                value={profile.mobileNo || ''}
                onChange={event => {
                  if (event.target.value.length <= 10) {
                    handleInputChange('mobileNo')(event);
                  }
                }}
                type="tel"
                validationError={errors.mobileNo || null}
              />
            </Col>
            <Col lg={6}>
              <FormGroupSelect
                id="gender"
                name="gender"
                label="Gender*"
                onChange={handleInputChange('gender')}
                value={profile.gender || 'null'}
                options={[
                  { label: 'Select', value: 'null' },
                  { label: 'Male', value: 'Male' },
                  { label: 'Female', value: 'Female' }
                ]}
                validationError={errors.gender || null}
              />
            </Col>
            <Col lg={6}>
              <FormGroupSelect
                id="language"
                name="language"
                label="Language*"
                onChange={handleInputChange('preferedLang')}
                value={profile.preferedLang || 'null'}
                options={languages}
                validationError={errors.preferedLang || null}
              />
            </Col>
            <Col lg={6}>
              <FormGroupSelect
                id="highestEducation"
                name="highestEducation"
                label="Highest Education*"
                onChange={handleInputChange('highestEducation')}
                value={profile.highestEducation || 'null'}
                options={qualifications}
                validationError={errors.highestEducation || null}
              />
            </Col>
            <Col lg={6}>
              <FormGroup>
                <WizardInput
                  label="Date of Birth*"
                  id="date"
                  customType="datetime"
                  name="birthDate"
                  placeholder="DD/MM/YYYY"
                  readOnly={true}
                  value={profile.dateOfBirth != null ? new Date(profile.dateOfBirth) : ''}
                  onChange={value => {
                    let dateValue = null;
                    if (!_.isEmpty(value) && value instanceof moment) {
                      dateValue = moment.tz(value.format('YYYY-MM-DD') + ' 00:00', 'UTC');
                      console.log('Date', dateValue);
                    }
                    setProfile({
                      ...profile,
                      dateOfBirth: dateValue
                    });
                    setErrors({ ...errors, dateOfBirth: null });
                  }}
                  maxDate={new Date().getTime()}
                  isValidDate={current => {
                    return current.isBefore(YESTERDAY);
                  }}
                  validationError={errors.dateOfBirth || null}
                />
              </FormGroup>
            </Col>
            <Col lg={6}>
              <FormGroupInput
                type="textarea"
                id="address"
                label="Address*"
                name="address"
                value={profile.address || ''}
                onChange={handleInputChange('address')}
                validationError={errors.address || null}
              />
            </Col>
            <Col lg={6}>
              <FormGroupInput
                id="city"
                label="City*"
                name="city"
                value={profile.city || ''}
                onChange={handleInputChange('city')}
                validationError={errors.city || null}
              />
            </Col>
            <Col lg={6}>
              <FormGroupSelect
                id="state"
                label="State*"
                name="state"
                value={profile.state || ''}
                options={[
                  {
                    label: 'Select',
                    value: 'null'
                  },
                  ...states.map(state => ({
                    label: state,
                    value: state
                  }))
                ]}
                onChange={handleInputChange('state')}
                validationError={errors.state || null}
              />
            </Col>
            <Col lg={6}>
              <FormGroupInput
                id="pinCode"
                label="Pincode*"
                name="pinCode"
                value={profile.pinCode || ''}
                onChange={handleInputChange('pinCode')}
                validationError={errors.pinCode || null}
              />
            </Col>

            <Col tag={Flex} xs={12} justify="end">
              <Button
                color="primary"
                type="submit"
                onClick={handleProfileSettings}
                disabled={_.isEqual(profile, initialProfile)}
              >
                Update
              </Button>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
});

export default withRedirect(SettingsProfile);
