import { getBaseUrl } from '../helpers/envUtils';

export default class BEService {
  BASE_URL = getBaseUrl();

  async fetchData(url, method, requestObj, authToken, headers, credentials = false, formData = null) {
    let eventData = {
      headers: headers || { 'Content-type': 'application/json; charset=UTF-8' },
      credentials: 'include',
      method
    };

    if (credentials) {
      eventData.credentials = 'include';
    }

    if (authToken != null) {
      eventData.headers['Authorization'] = 'Bearer ' + authToken;
    }

    if (requestObj != null) {
      eventData = {
        ...eventData,
        body: JSON.stringify(requestObj)
      };
    }

    if (formData != null) {
      eventData = {
        ...eventData,
        body: formData
      };
      return fetch(url, eventData);
    }

    let response = await fetch(url, eventData).then(response => response.json());
    console.log('Received response: ', response);
    return response;
  }

  async login(email, password, rememberMe) {
    let loginObject = {
      emailId: email,
      password,
      rememberMe
    };
    // let loginObject = {
    //   emailId: null,
    //   password: null,
    //   rememberMe: true
    // };

    const url = this.BASE_URL + '/auth/login';
    return await this.fetchData(url, 'POST', loginObject, null, null, email == null);
  }

  async logout() {
    const url = this.BASE_URL + `/auth/logout`;
    return await this.fetchData(url, 'POST');
  }

  async register(name, email, password) {
    let request = {
      emailId: email,
      password
    };

    const url = this.BASE_URL + '/auth/signup';
    return await this.fetchData(url, 'POST', request);
  }

  async forgotPassword(email) {
    let request = {
      emailId: email
    };

    const url = this.BASE_URL + '/auth/forgotpwd';
    return await this.fetchData(url, 'POST', request);
  }

  async resetPassword(token, password, authToken) {
    const request = {
      newPassword: password,
      confirmPassword: password
    };

    const url = this.BASE_URL + '/api/v1/user/resetpwd/' + token;
    return await this.fetchData(url, 'PUT', request, authToken);
  }

  async fetchProfile(userId, authToken) {
    const url = this.BASE_URL + '/api/v1/user/list/' + userId;
    return await this.fetchData(url, 'GET', null, authToken);
  }

  async updateProfile(userId, authToken, profile) {
    const url = this.BASE_URL + '/api/v1/user/update/' + userId;
    return await this.fetchData(url, 'PUT', profile, authToken);
  }

  async fetchLanguages(authToken) {
    const url = this.BASE_URL + '/api/v1/lang/list';
    return await this.fetchData(url, 'GET', null, authToken);
  }

  async fetchQualifications(authToken) {
    const url = this.BASE_URL + '/api/v1/qualification/list';
    return await this.fetchData(url, 'GET', null, authToken);
  }

  async updatePassword(oldPassword, newPassword, userId, authToken) {
    const obj = {
      oldPassword,
      newPassword,
      confirmPassword: newPassword
    };
    const url = this.BASE_URL + '/api/v1/user/changepassword/' + userId;
    return await this.fetchData(url, 'POST', obj, authToken);
  }

  async fetchModuleData(type, id, userId, authToken) {
    const url = this.BASE_URL + `/api/v1/testpackage/${type}videoslist?purchaseId=${id}&publicUserId=${userId}`;
    let response = await this.fetchData(url, 'GET', null, authToken);
    return response;
  }

  async fetchCommonInstructions(authToken, langId) {
    const url = this.BASE_URL + `/api/v1/instruction/list?testId=0&langId=${langId}`;
    let response = await this.fetchData(url, 'GET', null, authToken);
    return response;
  }

  async fetchPackages(authToken) {
    const url = this.BASE_URL + '/api/v1/testpackage/list';
    let response = await this.fetchData(url, 'GET', null, authToken);
    return response;
  }

  async fetchSubscribedPackages(userId, authToken) {
    const url = this.BASE_URL + '/api/v1/purchasepackage/list/' + userId;
    let response = await this.fetchData(url, 'GET', null, authToken);
    return response;
  }

  async loadTestsListingPage(userId, langId, packageId, purchaseId, authToken) {
    const url =
      this.BASE_URL +
      `/api/v1/testpackage/tests?publicUserId=${userId}&testPackageId=${packageId}&langId=${langId}&purchaseId=${purchaseId}`;
    let response = await this.fetchData(url, 'GET', null, authToken);
    return response;
  }

  async fetchTestQuestions(packageId, testId, userId, langId, authToken) {
    const url =
      this.BASE_URL +
      `/api/v1/testpackage/testquestions?testPackageId=${packageId}&publicUserId=${userId}&langId=${langId}&testId=${testId}`;
    let response = await this.fetchData(url, 'GET', null, authToken);
    return response;
  }

  async saveTestAnswers(userId, authToken, packageId, purchaseId, testId, answerMap) {
    console.log('Data', answerMap);
    const url = this.BASE_URL + `/api/v1/test/save?publicUserId=${userId}`;
    let requestObj = {
      testPackageId: packageId,
      testId: testId,
      purchaseId
    };
    let answers = [];

    Object.keys(answerMap).forEach(questionId => {
      for (let i = 0; i < answerMap[questionId].length; i++) {
        answers.push({
          testQuestionId: questionId,
          testQuestionOptionId: answerMap[questionId][i]
        });
      }
    });

    requestObj.testAnswers = answers;
    let response = await this.fetchData(url, 'POST', requestObj, authToken);
    return response;
  }

  async fetchSavedAnswers(userId, authToken, packageId, purchaseId, testId) {
    const url =
      this.BASE_URL +
      `/api/v1/test/save/list?testPackageId=${packageId}&publicUserId=${userId}&testId=${testId}&purchaseId=${purchaseId}`;
    let response = await this.fetchData(url, 'GET', null, authToken);
    return response;
  }

  async submitAnswers(userId, authToken, packageId, purchaseId, testId, answerMap) {
    const url = this.BASE_URL + `/api/v1/test/submit?publicUserId=${userId}`;
    let requestObj = {
      testPackageId: packageId,
      testId,
      purchaseId
    };

    let answers = [];
    Object.keys(answerMap).forEach(questionId => {
      for (let i = 0; i < answerMap[questionId].length; i++) {
        answers.push({
          testQuestionId: questionId,
          testQuestionOptionId: answerMap[questionId][i]
        });
      }
    });

    requestObj.testAnswers = answers;
    console.log('Sending submit request: ', requestObj);
    let response = await this.fetchData(url, 'POST', requestObj, authToken);
    return response;
  }

  async markVideoAsCompleted(userId, authToken, purchaseId, videoId) {
    const url = this.BASE_URL + `/api/v1/video/watched/save`;
    let requestObj = {
      videoId: videoId,
      purchaseId,
      publicUserId: userId
    };

    let response = await this.fetchData(url, 'POST', requestObj, authToken);
    return response;
  }

  async fetchInstructions(authToken, testId, langId) {
    const url = this.BASE_URL + `/api/v1/instruction/list?testId=${testId}&langId=${langId}`;
    let response = await this.fetchData(url, 'GET', null, authToken);
    return response;
  }

  async fetchCareerInformation(authToken, langId) {
    const url = this.BASE_URL + `/api/v1/jobdescription/list?langId=${langId}`;
    return await this.fetchData(url, 'GET', null, authToken);
  }

  async fetchCareerDescInformation(authToken, langId, subCategoryId) {
    const url = this.BASE_URL + `/api/v1/jobdescription/info?langId=${langId}&jdSubCategoryId=${subCategoryId}`;
    return await this.fetchData(url, 'GET', null, authToken);
  }

  async updateCareerDescInformation(authToken, langId, subCategoryId, jdId, data) {
    const url = this.BASE_URL + `/api/v1/jobdescription/create`;
    let request = {
      jdDescription: data,
      langId,
      jdSubCategoryId: subCategoryId
    };
    if (jdId != null) {
      request.jdId = jdId;
    }
    return await this.fetchData(url, 'POST', request, authToken);
  }

  async createPurchaseOrder(authToken, userId, packageObj, couponCode) {
    const url = this.BASE_URL + `/api/v1/order/create`;
    let request = {
      applicationFee: packageObj.packageAmount,
      publicUserID: userId,
      packageCode: packageObj.packageId
      // couponCode
    };
    if (couponCode != null) {
      request.couponCode = couponCode;
    }
    return await this.fetchData(url, 'POST', request, authToken);
  }

  async updateOrderWithCouponCode(authToken, orderId, couponCode) {
    const url = this.BASE_URL + `/api/v1/payment/updatePaymentDetails`;
    let request = {
      razorpayOrderId: orderId,
      couponCode: couponCode
    };
    return await this.fetchData(url, 'POST', request, authToken);
  }

  async updateOrderWithResponse(authToken, data) {
    const url = this.BASE_URL + `/api/v1/payment/updatePaymentDetails`;
    return await this.fetchData(url, 'POST', data, authToken);
  }

  async validateCoupon(authToken, couponCode, packageId) {
    const url = this.BASE_URL + `/api/v1/coupons/validate?couponCode=${couponCode}&packageId=${packageId}`;
    return await this.fetchData(url, 'GET', null, authToken);
  }

  async fetchCoupons(authToken, id) {
    let url = this.BASE_URL + `/api/v1/coupons/list`;
    if (id != null) {
      url += `/${id}`;
    }
    return await this.fetchData(url, 'GET', null, authToken);
  }

  async upsertCoupons(authToken, type, couponData) {
    const method = type === 'create' ? 'POST' : 'PUT';
    const url = this.BASE_URL + `/api/v1/coupons/${type}`;
    return await this.fetchData(url, method, couponData, authToken);
  }

  async getTransactionsForUser(authToken, userId) {
    const url = this.BASE_URL + `/api/v1/payment/list/user?publicUserId=${userId}`;
    return await this.fetchData(url, 'GET', null, authToken);
  }

  async createFeedback(feedbackData) {
    const url = this.BASE_URL + `/api/v1/feedback/create`;
    return await this.fetchData(url, 'POST', feedbackData, null);
  }

  async createJdFeedback(feedbackData, authToken) {
    const url = this.BASE_URL + `/api/v1/jdfeedback/create`;
    return await this.fetchData(url, 'POST', feedbackData, authToken);
  }

  async createTestFeedback(feedbackData, authToken, userId) {
    const url = this.BASE_URL + `/api/v1/testfeedback/create/${userId}`;
    return await this.fetchData(url, 'POST', feedbackData, authToken);
  }

  async contactUs(data) {
    const url = this.BASE_URL + `/api/v1/feedback/contactus/create`;
    return await this.fetchData(url, 'POST', data, null);
  }

  async b2bCreateUser(data, authToken) {
    const url = this.BASE_URL + `/api/v1/user/userHeirarchy/create`;
    return await this.fetchData(url, 'POST', data, authToken);
  }

  async getUsersByRole(roleId, authToken) {
    const url = this.BASE_URL + `/api/v1/user/list/byrole/${roleId}`;
    return await this.fetchData(url, 'GET', null, authToken);
  }

  async getUserHierarchy(userId, authToken) {
    const url = this.BASE_URL + `/api/v1/user/userheirarchy/${userId}`;
    return await this.fetchData(url, 'GET', null, authToken);
  }

  async getNoOfTestsTaken(userId, authToken) {
    const url = this.BASE_URL + `/api/v1/dashboard/byuser/${userId}`;
    return await this.fetchData(url, 'GET', null, authToken);
  }

  async getNoOfTestsPurchased(userId, authToken) {
    const url = this.BASE_URL + `/api/v1/dashboard/byuser/${userId}`;
    return await this.fetchData(url, 'GET', null, authToken);
  }

  async addPayment(data, authToken) {
    const url = this.BASE_URL + `/api/v1/payment/create`;
    return await this.fetchData(url, 'POST', data, authToken);
  }

  async addPackagesToUsers(data, authToken) {
    const url = this.BASE_URL + `/api/v1/purchasepackage/create`;
    return await this.fetchData(url, 'POST', data, authToken);
  }

  async bulkCreateUsers(file, authToken) {
    const url = this.BASE_URL + `/api/v1/user/bulkcreate`;
    let formData = new FormData();
    formData.append('file', file);
    return await this.fetchData(url, 'POST', null, authToken, {}, null, formData);
  }
}
