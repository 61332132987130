import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Card, CardBody, Col, CustomInput, Label, Row } from 'reactstrap';
import { Form, FormGroup, Input } from 'reactstrap';
import _ from 'lodash';
import { toast } from 'react-toastify';
import StarRatings from 'react-star-ratings';

import { useSwotStore } from '../../store';
import PageLoader from '../../components/common/PageLoader';

const TestFeedbackForm = observer(({ setOpen }) => {
  const { swotStore } = useSwotStore();

  const { profile, selectedPackageId } = swotStore;

  const [data, setData] = useState({});

  const submitHandler = () => {
    const request = {
      ...data,
      feedbackPhonenumber: profile.mobileNo
    };
    swotStore.createTestFeedback(request).then(response => {
      if (response.statusCode !== 200) {
        toast.error("Couldn't submit feedback please try again.");
      } else {
        toast.success('Thanks for submitting the feedback.');
        setOpen(false);
        if (selectedPackageId != null) {
          window.location.href = `/modules/${selectedPackageId}`;
        } else {
          window.location.href = '/modules';
        }
      }
    });
  };

  return (
    <Form className="text-center mt-2">
      <h5 className="text-center">Please rate your experience</h5>
      <FormGroup>
        <StarRatings
          rating={data?.feedbackRating}
          isAggregateRating={false}
          starRatedColor="green"
          changeRating={rating => {
            setData({
              ...data,
              feedbackRating: rating
            });
          }}
          numberOfStars={5}
          name="Rate the experience"
        />
      </FormGroup>
      <FormGroup>
        <Input
          onChange={e => {
            setData({
              ...data,
              feedbackCommnets: e.target.value
            });
          }}
          placeholder="Comments"
          type="textarea"
        />
      </FormGroup>
      <Button disabled={data.length < 2} color="primary" onClick={submitHandler}>
        Submit Feedback
      </Button>
    </Form>
  );
});

export default TestFeedbackForm;
