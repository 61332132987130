import React from 'react';
import PropTypes from 'prop-types';
import { configure } from 'mobx';
import SwotStore from './SwotStore';
import { useLocalObservable } from 'mobx-react-lite';

configure({ enforceActions: 'observed' });

const swotStoreContext = React.createContext(null);
export const SwotStoreProvider = ({ children }) => {
  const store = useLocalObservable(() => ({
    swotStore: SwotStore
  }));
  return <swotStoreContext.Provider value={store}>{children}</swotStoreContext.Provider>;
};

SwotStoreProvider.propTypes = {
  children: PropTypes.node
};

export const useSwotStore = () => {
  return React.useContext(swotStoreContext);
};
