import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';
import _ from 'lodash';
import { Button } from 'reactstrap';
import HorizontalTimeline from 'react-horizontal-timeline';
import moment from 'moment/moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useSwotStore } from '../../store';
import PageLoader from '../../components/common/PageLoader';

const CategoriesTimeLine = observer(() => {
  const { swotStore } = useSwotStore();
  const { tests, currentCategoryId, setCurrentCategoryId } = swotStore;

  useEffect(() => {
    swotStore.doLoadTestsListingPage().then(response => {
      if (response.statusCode != 200) {
        toast.error('Error loading the tests. Please try again.');
      }
    });
  }, []);

  if (tests == null) {
    return <PageLoader />;
  }

  let dateToLabel = {};
  let categoryToIndex = {};
  const categories = getCategories(tests, dateToLabel, categoryToIndex);

  let index = 0;
  for (let i = 0; i < categories.length; i++) {
    if (!categories[i].status) {
      setCurrentCategoryId(categories[i].id);
      break;
    }
    index = i;
  }
  
  // setCurrentCategoryId(3);
  // index = 1;

  return (
    <>
      <div
        style={{
          width: '90%',
          height: '100px',
          margin: '0 auto',
          marginTop: '20px',
          fontSize: '15px'
        }}
      >
        <HorizontalTimeline
          labelWidth={250}
          isTouchEnabled={false}
          isKeyboardEnabled={false}
          index={index}
          indexClick={() => {}}
          values={categories.map(obj => obj.date)}
          getLabel={date => {
            const obj = dateToLabel[date];
            let classNames = '';
            if (obj.completed) {
              classNames += 'btn-block disabled';
            } else {
              classNames += 'falcon-primary';
            }
            return (
              <Button color={classNames}>
                {obj.label}
                {obj.completed && <FontAwesomeIcon icon="check" transform="shrink-2" className=" ml-1" />}
              </Button>
            );
          }}
        />
      </div>
    </>
  );
});

const getCategories = (tests, dateToLabel, categoryToIndex) => {
  let categories = {};
  for (let i = 0; i < tests.length; i++) {
    let test = tests[i];
    categories[test.categoryId] = test;
  }
  let categoriesList = [];
  Object.keys(categories).forEach((id, i) => {
    let obj = {
      id: id,
      name: categories[id].categoryName,
      status: categories[id].categorySubmitStatus
    };
    obj.date = moment()
      .add(i, 'days')
      .format('YYYY-MM-DD');
    categoriesList.push(obj);
    dateToLabel[obj.date] = {
      label: obj.name,
      state: 'btn-success',
      completed: obj.status
    };
    categoryToIndex[id] = i;
  });
  return categoriesList.sort((a, b) => a.id - b.id);
};

export default CategoriesTimeLine;
