import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Card, CardBody, Col, CustomInput, Label, Row } from 'reactstrap';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

import FalconCardHeader from '../../components/common/FalconCardHeader';
import PricingCard from './PricingCard';
import { useSwotStore } from '../../store';
import PageLoader from '../../components/common/PageLoader';

const bgColor = [null, 'rgba(115, 255, 236, 0.18)'];

export const Packages = observer(({ disabled = false }) => {
  const { swotStore } = useSwotStore();
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    swotStore.doFetchPackages().then(response => {
      if (response.statusCode != 200) {
        toast.error("Couldn't fetch packages. Please try again.");
      } else {
        setPackages(response.result);
      }
    });
  }, []);

  if (_.isEmpty(packages)) {
    return <PageLoader />;
  }

  return (
    <Slider
      dots={true}
      infinite={false}
      speed={500}
      slidesToShow={3}
      slidesToScroll={1}
      arrows={true}
      responsive={[
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }
      ]}
      className="mb-5 mt-5"
    >
      {packages.map((pricingItem, index) => (
        <PricingCard
          key={index}
          type={pricingItem.packageName}
          description={pricingItem.packageDesc}
          price={pricingItem.packageAmount}
          backgroundColor={bgColor[index % 2]}
          packageObj={pricingItem}
          disabled={disabled}
        />
      ))}
    </Slider>
  );
});
