import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Card, CardBody, Col, CustomInput, Label, Input, Row, Table, Form, FormGroup } from 'reactstrap';
import _, { add } from 'lodash';
import { toast } from 'react-toastify';

import FalconCardHeader from '../../components/common/FalconCardHeader';
import { useSwotStore } from '../../store';
import User from './User';
import Select from '../../components/common/Select';

/**
 * 
{
    "amount":"20000",
    "description":"paymnet paied",
    "paymetReferenceNo":"ddsfsdf",
    "paymentmode":"cash ",
    "publicUserId":"73a355b1-79cd-4b3c-a392-651b28fbeec4"
}
 */

const AddPayment = observer(() => {
  const { swotStore } = useSwotStore();

  const type = 'create';

  const [data, setData] = useState({});
  const [users, setUsers] = useState(null);
  const [user, setUser] = useState(null);

  const fetchUsers = () => {
    const roleId = 2;
    swotStore.getUsersByRole(roleId).then(response => {
      if (response.statusCode == 200) {
        let users = response.result;
        users = users.map(user => ({ label: user.emailId, value: user.publicUserId }));
        setUsers(users);
      }
    });
  };

  const addPayment = () => {
    swotStore.addPayment(data).then(response => {
      if (response.statusCode != 200) {
        toast.error("Couldn't save payment");
      } else {
        toast.success('Saved payment details successfully');
      }
    });
  };

  const handleChange = fieldName => e => {
    const value = e.target.value;
    console.log(fieldName, value);
    setData({
      ...data,
      [fieldName]: value
    });
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <Card className="mb-3">
      <CardBody>
        <FalconCardHeader title="Add Payment" light={false} className="text-center" />
        <Form>
          <FormGroup>
            <Label for="user">User</Label>
            <div className="border">
              <Select
                styles={{
                  border: '1px solid #d8e2ef !important'
                }}
                options={users}
                value={user}
                onChange={user => {
                  setData({
                    ...data,
                    publicUserId: user.value
                  });
                  setUser(user);
                }}
              />
            </div>
          </FormGroup>
          <FormGroup>
            <Label for="amount">Amount</Label>
            <Input type="text" name="amount" id="amount" value={data.amount || ''} onChange={handleChange('amount')} />
          </FormGroup>
          <FormGroup>
            <Label for="description">description</Label>
            <Input
              type="text"
              name="description"
              id="description"
              value={data.description || ''}
              onChange={handleChange('description')}
            />
          </FormGroup>
          <FormGroup>
            <Label for="paymetReferenceNo">Payment Reference No</Label>
            <Input
              type="text"
              name="paymetReferenceNo"
              id="paymetReferenceNo"
              disabled={type === 'update'}
              value={data.paymetReferenceNo || ''}
              onChange={handleChange('paymetReferenceNo')}
            />
          </FormGroup>
          <FormGroup>
            <Label for="paymentmode">Payment Mode</Label>
            <Input
              type="text"
              name="paymentmode"
              id="paymentmode"
              disabled={type === 'update'}
              value={data.paymentmode || ''}
              onChange={handleChange('paymentmode')}
            />
          </FormGroup>
          <Button
            color="primary"
            onClick={() => {
              addPayment();
            }}
          >
            Save
          </Button>
        </Form>
      </CardBody>
    </Card>
  );
});

export default AddPayment;
