import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Card, CardBody } from 'reactstrap';
import _ from 'lodash';

import FalconCardHeader from '../../components/common/FalconCardHeader';
import { useSwotStore } from '../../store';
import CardSummary from '../../components/dashboard/CardSummary';
import MarketShare from '../../components/dashboard-alt/MarketShare';

const Home = observer(() => {
  const { swotStore } = useSwotStore();
  const [data, setData] = useState({});

  useEffect(() => {
    swotStore.getNoOfTestsTaken().then(response => {
      if (response.statusCode == 200) {
        setData({
          ...data,
          ...response.result
        });
      }
    });
  }, []);

  return (
    <Card className="mb-3 m-3">
      <CardBody>
        <FalconCardHeader title="Dashboard" light={false} className="text-center" />
        <div className="card-deck">
          <CardSummary title="Total Packages Purchased" color="warning">
            {data.noOfPackagesPurchased ?? 0}
          </CardSummary>
          <CardSummary title="Total Tests Taken" color="info">
            {data.noOfTestTaken ?? 0}
          </CardSummary>
          {/* <CardSummary title="Total Packages Purchased" color="warning">
            {data.noOfPackagesPurchased ?? 0}
          </CardSummary> */}
        </div>
        <div>
          <MarketShare
            title="Tests Count"
            data={[
              { id: 1, value: data.taotalSuccessCount ?? 0, name: 'Success Count', color: '#27bcfd' },
              { id: 2, value: data.totalFailureCount ?? 0, name: 'Failure Count', color: '#d8e2ef' }
            ]}
          />
        </div>
        <div className="mt-2">
          <MarketShare
            title="Payment Details"
            data={[
              { id: 1, value: data.totalpackageAmountPaid ?? 0, name: 'Amount Paid', color: '#27bcfd' },
              { id: 2, value: data.pendingAmount ?? 0, name: 'Pending Amount', color: '#d8e2ef' }
            ]}
          />
        </div>
      </CardBody>
    </Card>
  );
});

export default Home;

/**
{
    "statusCode": 200,
    "statusMsg": "SUCCESS",
    "result": {
        "taotalCount": null,
        "taotalSuccessCount": null,
        "totalFailureCount": null,
        "totalAmount": null,
        "noOfTestTaken": 0,
        "noOfPackagesPurchased": 0,
        "pendingAmount": null,
        "totalpackageAmountPaid": null,
        "totalPackageAmount": null
    }
}
 */
