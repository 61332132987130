import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Card, CardBody, Col, CustomInput, Label, Row, Table, Form, FormGroup } from 'reactstrap';
import _ from 'lodash';
import Tree from 'react-hierarchy-tree-graph';

import FalconCardHeader from '../../components/common/FalconCardHeader';
import { useSwotStore } from '../../store';
import Select from '../../components/common/Select';
import PageLoader from '../../components/common/PageLoader';
import { toast } from '../../../node_modules/react-toastify/index';

const TreeNode = ({ nodeData }) => (
  <div>
    <div>
      <div>
        <h5 style={{ margin: '5px', fontSize: '14px' }}>{nodeData.name}</h5>
        <h6 style={{ margin: '5px', fontSize: '10px' }}>{nodeData.attributes.emailId}</h6>
        {/* <p style={{ margin: '5px', fontSize: '10px' }}>{nodeData.attributes.publicUserId}</p> */}
      </div>
    </div>
  </div>
);

const UserHierarchy = observer(() => {
  const { swotStore } = useSwotStore();
  const { user: publicUserID } = swotStore;

  const [dimensions, setDimensions] = useState(null);

  const [users, setUsers] = useState(null);
  const [user, setUser] = useState(null);

  const [userHierarchy, setUserHierarchy] = useState(null);

  const treeContainerRef = useCallback(node => {
    console.log('Calling tree callback');
    if (node != null && dimensions == null) {
      setDimensions(node.getBoundingClientRect());
    }
  });

  useEffect(() => {
    fetchHierarchy(publicUserID);
  }, []);

  const fetchHierarchy = user => {
    setUser(user);
    swotStore.getUserHierarchy(user.value).then(response => {
      if (response.statusCode != 200) {
        toast.error("Couldn't fetch hierarchy. Please try again.");
      } else {
        setUserHierarchy(response.result);
      }
    });
  };

  return userHierarchy == null ? (
    <PageLoader />
  ) : (
    <Card className="mb-3">
      <CardBody>
        <FalconCardHeader title="User Hierarchy" light={false} className="text-center" />
        <div
          style={{
            width: '100%',
            height: '100vh'
          }}
          ref={treeContainerRef}
        >
          {dimensions != null && userHierarchy != null && (
            <Tree
              data={userHierarchy}
              collapsible={false}
              scaleExtent={{ min: 1, max: 3 }}
              orientation="vertical"
              translate={{
                x: dimensions.width / 2,
                y: 100
              }}
              nodeSvgShape={{ shape: 'none' }}
              nodeSize={{ x: 300, y: 150 }}
              pathFunc="elbow"
              allowForeignObjects={true}
              nodeLabelComponent={{
                render: <TreeNode />,
                foreignObjectWrapper: {
                  style: {
                    background: 'lightblue',
                    border: '1px solid black',
                    width: '200px',
                    height: '80px',
                    x: 150 / -2,
                    y: 80 / -2
                  }
                }
              }}
            />
          )}
        </div>
      </CardBody>
    </Card>
  );
});

export default UserHierarchy;
