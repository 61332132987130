import React, { useEffect, useState, createRef } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { InputGroup, CustomInput, Button, Card, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';
import moment from 'moment';
import Badge from 'reactstrap/es/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FalconCardHeader from '../../components/common/FalconCardHeader';
import { useSwotStore } from '../../store/index';
import PageLoader from '../../components/common/PageLoader';
import InvoiceDownloader from './InvoiceDownloader';

const badgeFormatter = status => {
  let color = '';
  let icon = '';
  let text = '';
  switch (status) {
    case 'Y':
      color = 'success';
      icon = 'check';
      text = 'Success';
      break;
    default:
      color = 'warning';
      icon = 'stream';
      text = 'Failure';
  }
  return (
    <Badge color={`soft-${color}`} className="rounded-capsule">
      {text}
      <FontAwesomeIcon icon={icon} transform="shrink-2" className="ml-1" />
    </Badge>
  );
};

const columns = [
  {
    dataField: 'index',
    text: 'Serial No.',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0'
  },
  {
    dataField: 'orderId',
    text: 'Order ID',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0'
  },
  {
    dataField: 'feeToBePaid',
    text: 'Amount',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    formatter: (test, row, index) => {
      const { feeToBePaid, packageAmount } = row;
      console.log('Row', feeToBePaid, packageAmount);
      return <>Rs. {feeToBePaid != null ? feeToBePaid : packageAmount != null ? packageAmount : ''} </>;
    }
  },
  {
    dataField: 'packageName',
    text: 'Package Name',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0'
  },
  {
    dataField: 'paymentdate',
    text: 'Payment Date',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    formatter: paymentDate => {
      const date = moment(paymentDate);
      return date.format('LLL');
    }
  },
  {
    dataField: 'status',
    text: 'Payment Status',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    formatter: badgeFormatter
  },
  {
    dataField: 'purchaseId',
    text: '#',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    formatter: (purchaseId, row, index) => (row.status !== 'Y' ? <></> : <InvoiceDownloader purchaseId={purchaseId} />)
  }
];

const TransactionHistory = () => {
  const { swotStore } = useSwotStore();
  const [transactions, setTransactions] = useState(null);
  let table = createRef();

  useEffect(() => {
    swotStore.doGetTransactionsForUser().then(response => {
      if (response.statusCode !== 200) {
        toast.error("Couldn't fetch transactions");
      } else {
        let result = response.result;
        result.forEach((obj, index) => {
          obj.index = index + 1;
        });
        setTransactions(result);
      }
    });
  }, []);

  return (
    <Card className="mb-3">
      <FalconCardHeader title="Recent Purchases" light={false} />
      <CardBody className="p-0">
        <div className="table-responsive">
          {transactions == null ? (
            <PageLoader />
          ) : (
            <BootstrapTable
              ref={table}
              bootstrap4
              keyField="id"
              data={transactions}
              columns={columns}
              // selectRow={selectRow(onSelect)}
              bordered={false}
              classes="table-dashboard table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
              rowClasses="btn-reveal-trigger border-top border-200"
              headerClasses="bg-200 text-900 border-y border-200"
              // {...paginationTableProps}
            />
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default TransactionHistory;
