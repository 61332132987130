import React from 'react';
import classNames from 'classnames';
import Lottie from 'react-lottie';
import { Spinner, Modal, ModalBody } from 'reactstrap';

import successAnimationData from './loading.json';


const successOptions = {
  loop: true,
  autoplay: true,
  animationData: successAnimationData,
  rendererSettings: {
    preserveAspectRatio: 'xMinYMin slice'
  }
};

const PageLoaderOverflow = ({ classNames = 'mt-11 text-center', isOpen = false }) => {
  return (
    <Modal isOpen={isOpen} size="xl" centered>
      <ModalBody>
        <>
          <Lottie options={successOptions} style={{ width: '120px', height: '120px' }} />
          <h5 className="text-center"> Loading...</h5>
        </>
      </ModalBody>
    </Modal>
  );
};

export default PageLoaderOverflow;
