const DEV_BASE_URL = 'http://ec2-18-188-169-222.us-east-2.compute.amazonaws.com:9090/swotcareers';
const PROD_BASE_URL = 'https://swotcareers.com/swotcareers';

const urlMap = {
  dev: 'http://ec2-18-188-169-222.us-east-2.compute.amazonaws.com:9090/swotcareers',
  b2bdev: 'http://ec2-18-188-169-222.us-east-2.compute.amazonaws.com:9091/swotcareersb2b',
  production: 'https://swotcareers.com/swotcareers'
};

export const getEnvironment = () => {
  const env = process.env.REACT_APP_CUSTOM_ENV || '';
  console.log('Running in environment', env);
  return env;
};

export const isB2B = () => {
  const env = getEnvironment();
  if (env.startsWith('b2b')) {
    return true;
  }
  return false;
};

export const getBaseUrl = () => {
  const env = process.env.REACT_APP_CUSTOM_ENV;

  const url = urlMap[env] || this.PROD_BASE_URL;

  return url;
};
