import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSwotStore } from '../store/index';

const withLoggedIn = OriginalComponent => {
  const UpdatedComponent = props => {
    // State

    const { swotStore } = useSwotStore();

    if (swotStore.token == null) {
      return <Redirect to={'/auth/login?next=' + window.location.pathname} />;
    }

    return <OriginalComponent {...props} />;
  };

  return UpdatedComponent;
};

export default withLoggedIn;
