import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Card, CardBody, Form, FormGroup, Input, Col, CustomInput, Label, Row, Table } from 'reactstrap';
import _ from 'lodash';
import { toast } from 'react-toastify';

import FalconCardHeader from '../../components/common/FalconCardHeader';
import { useSwotStore } from '../../store';
import { getBaseUrl } from '../../helpers/envUtils';
import PageLoaderOverflow from '../../components/common/PageLoaderOverflow';

const BulkCreate = observer(() => {
  const { swotStore } = useSwotStore();

  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const linkRef = React.createRef();

  const changeHandler = event => {
    console.log('files', event.target.files[0]);
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  const uploadFile = () => {
    setLoading(true);
    swotStore
      .bulkCreateUsers(selectedFile)
      .then(res => res.blob())
      .then(blob => {
        toast.success('File upload successfully.');
        setLoading(false);
        const href = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.download = 'Response.xlsx';
        a.href = href;
        a.click();
        a.href = '';
      });
  };

  return (
    <Card className="mb-3">
      <CardBody>
        <FalconCardHeader title="Bulk Create User" light={false} className="text-center" />
        {isLoading && <PageLoaderOverflow isOpen={isLoading} />}
        <FormGroup>
          <Label for="exampleFile">File to Upload</Label>
          <Input type="file" name="file" id="exampleFile" onChange={changeHandler} />
          <Button color="primary" className="mt-4" type="button" onClick={uploadFile} disabled={!isFilePicked}>
            Submit File
          </Button>
        </FormGroup>
        <FormGroup />
        <a ref={linkRef} style={{ display: 'hidden' }} />
      </CardBody>
    </Card>
  );
});

export default BulkCreate;
