import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import 'plyr/dist/plyr.css';
import Plyr from 'plyr';
import ReactPlayer from 'react-player';
import { toast } from 'react-toastify/';

import PageLoader from './PageLoader';
import { useSwotStore } from '../../store';

const FalconPlyr = ({ type, videoId, url, postTask = () => {} }) => {
  const plyrRef = useRef();

  const { swotStore } = useSwotStore();
  const { token, selectedPackageId } = swotStore;

  const [objectUrl, setObjectUrl] = useState(null);

  useEffect(() => {
    const instance = new Plyr(plyrRef.current);
    instance.on('ended', e => {
      console.log('Event finished');
    });
  }, []);

  useEffect(() => {
    async function fetchVideo() {
      const result = await fetch(url, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      });
      const resp = await result.blob();
      if (resp) {
        setObjectUrl(URL.createObjectURL(resp));
      }
    }
    // Commenting fetchVideo call and directly setting url for un-authenticated endpoints
    // fetchVideo();
    setObjectUrl(url);
  }, [url]);

  if (objectUrl == null) {
    return <PageLoader classNames="mt-5 mb-5 text-center" loading />;
  }

  switch (type) {
    case 'youtube':
      return (
        <div className="position-relative">
          <div className="plyr__video-embed" ref={plyrRef}>
            <iframe
              src={`https://www.youtube.com/embed/${videoId}?origin=https://plyr.io&amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1`}
              // src = {url}
              allowFullScreen
              allow="autoplay"
              title={videoId}
            />
          </div>
        </div>
      );
    case 'vimeo':
      return (
        <div className="plyr__video-embed" ref={plyrRef}>
          <iframe
            src={`https://player.vimeo.com/video/${videoId}?loop=false&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=0&amp;gesture=media`}
            allowFullScreen
            allow="autoplay"
            title={videoId}
          />
        </div>
      );
    case 'source':
      return (
        <div className="position-relative">
          <div className="plyr__video-embed" ref={plyrRef}>
            <ReactPlayer
              url={objectUrl}
              width="100%"
              height="auto"
              playing
              controls
              onReady={e => {
                console.log('On ready', e);
              }}
              onBuffer={e => {
                console.log('On buffer', e);
              }}
              onError={e => {
                console.log('On buffer', e);
              }}
              onEnded={e => {
                swotStore.doMarkVideoComplete(videoId).then(response => {
                  swotStore.doFetchVideoModules('pre', selectedPackageId);
                  swotStore.doFetchVideoModules('post', selectedPackageId);
                });

                postTask();
              }}
              fallback={<PageLoader />}
              pip={false}
              config={{
                file: {
                  attributes: {
                    controlsList: 'nodownload',
                    disablepictureinpicture: 'true'
                  }
                }
              }}
            />
          </div>
        </div>
      );
    default:
      return null;
  }
};

FalconPlyr.propTypes = {
  videoId: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['youtube', 'vimeo', 'source']).isRequired
};

export default FalconPlyr;
