import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Badge, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import { useSwotStore } from '../../store/index';

const PricingRow = ({ children }) => (
  <li className="py-1">
    <FontAwesomeIcon icon="check" transform="shrink-2" className="text-success" /> {children}
  </li>
);

PricingRow.propTypes = { children: PropTypes.node };

const PricingCard = observer(({ type, description, price, button, backgroundColor, packageObj, disabled }) => {
  const { swotStore } = useSwotStore();

  const { token, user } = swotStore;

  if (button == null) {
    button = {
      text: 'Purchase',
      color: 'outline-primary',
      link: token != null ? '/packages/purchase/' : '/auth/login?next=/dashboard/'
    };
  }

  return (
    <div className="h-100" style={{ backgroundColor, minHeight: '350px' }}>
      <div className="text-center p-4 container">
        <div className="row" style={{ height: '180px' }}>
          <div className="col">
            <h3 className="font-weight-normal my-0">{type}</h3>
            <p className="mt-3">{description}</p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h2 className="font-weight-medium my-4">
              {/* <sup className="font-weight-normal fs-2 mr-1">₹</sup> */}
              Rs. {price}
              {/* <small className="fs--1 text-700">/ {plan}</small> */}
            </h2>
            <Button
              tag={Link}
              color={button.color}
              disabled={disabled}
              to={button.link + packageObj.packageId}
            >
              {button.text}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
});

export default PricingCard;
