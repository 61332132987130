import { makeAutoObservable, observable, computed, runInAction, action } from 'mobx';

import BEService from '../services/BEService';

class SwotStore {
  STATE = {
    LOADING: 'Loading',
    SUCCESS: 'Success',
    ERROR: 'Error'
  };

  // @observable user = 'bb97dfd8-4ee2-48d0-9df5-e92a7b92b255';
  // @observable token = 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxMSIsImlhdCI6MTYyMDA1MDMzMiwiZXhwIjoxNjIwOTE0MzMyfQ.6frYHy6KbmPn4Kt_j7b7WJSY1CD00TyMNrOlw5kEjJ1UxubWpzlEgb3NBcGs_UUYIDXfJwUxUhaFWymxpXvCyg';

  // @observable user = '5e848553-4b70-450c-a7e5-6631d1cba80b';
  // @observable token =
  //   'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxOCIsImlhdCI6MTYyMzg1NDgzMCwiZXhwIjoxNjI0NzE4ODMwfQ.KW-dsSxpKKxhzkiqN10VykIiQlap-w-sEEFT26Gl0nWby9r-hGT2bTewCH3tonB5fMautTAx27S1fv1ClLR12w';
  // @observable langId = 1;
  @observable role = null;
  @observable user = null;
  @observable token = null;
  @observable langId = null;
  @observable profile = null;
  @observable state = null;
  @observable response = null;
  @observable premodules = null;
  @observable selectedPackageId = null; //5;
  @observable tests = null;
  @observable currentCategoryId = null; //3;
  @observable currentTestId = null;
  @observable currentTestDuration = null;
  @observable purchaseId = null; //2;
  @observable testToQuestionsMap = {};
  @observable testToAnswersMap = {};
  @observable preVideos = [];
  @observable postVideos = [];
  @observable moduleLayoutTests = [];
  @observable purchasedPackages = [];

  constructor() {
    this.service = new BEService();
    makeAutoObservable(this);
  }

  @action
  async doLogin(email, password, rememberMe) {
    this.state = this.STATE.LOADING;
    let response = await this.service.login(email, password, rememberMe);
    runInAction(() => {
      if (response.statusCode == 200) {
        this.token = response.result.accessToken;
        this.user = response.result.publicUserId;
        this.role = response.result.roleName;
      }
      this.state = this.STATE.SUCCESS;
    });
    return response;
  }

  @action.bound
  async doRegister(name, email, password) {
    this.state = this.STATE.LOADING;
    let response = await this.service.register(name, email, password);
    runInAction(() => {
      this.state = this.STATE.SUCCESS;
    });
    return response;
  }

  @action.bound
  async doForgotPassword(email) {
    this.state = this.STATE.LOADING;
    let response = await this.service.forgotPassword(email);
    runInAction(() => {
      this.state = this.STATE.SUCCESS;
    });
    return response;
  }

  @action.bound
  async doResetPassword(token, password, authToken) {
    this.state = this.STATE.LOADING;
    let response = await this.service.resetPassword(token, password, authToken);
    runInAction(() => {
      this.state = this.STATE.SUCCESS;
    });
    return response;
  }

  @action.bound
  async doFetchProfile() {
    this.state = this.STATE.LOADING;
    let response = await this.service.fetchProfile(this.user, this.token);
    runInAction(() => {
      this.state = this.STATE.SUCCESS;
      this.langId = response.result.preferedLang;
      this.profile = response.result;
    });
    return response;
  }

  @action.bound
  async doUpdateProfile(profile) {
    this.state = this.STATE.LOADING;
    let response = await this.service.updateProfile(this.user, this.token, profile);
    runInAction(() => {
      this.state = this.STATE.SUCCESS;
    });
    return response;
  }

  @action.bound
  async doFetchLanguages() {
    this.state = this.STATE.LOADING;
    let response = await this.service.fetchLanguages(this.token);
    runInAction(() => {
      this.state = this.STATE.SUCCESS;
    });
    return response;
  }

  @action.bound
  async doFetchQualifications() {
    this.state = this.STATE.LOADING;
    let response = await this.service.fetchQualifications(this.token);
    runInAction(() => {
      this.state = this.STATE.SUCCESS;
    });
    return response;
  }

  @action.bound
  async doUpdatePassword(oldPassword, newPassword) {
    this.state = this.STATE.LOADING;
    let response = await this.service.updatePassword(oldPassword, newPassword, this.user, this.token);
    runInAction(() => {
      this.state = this.STATE.SUCCESS;
    });
    return response;
  }

  @action.bound
  async doFetchVideoModules(type, selectedPackageId) {
    let response = await this.service.fetchModuleData(type, this.purchaseId, this.user, this.token);
    runInAction(() => {
      this.state = this.STATE.SUCCESS;
      if (type == 'pre') {
        this.preVideos = response.result;
      } else if (type == 'post') {
        this.postVideos = response.result;
      }
    });
    return response;
  }

  @action.bound
  async doFetchCommonInstructions() {
    let response = await this.service.fetchCommonInstructions(this.token, this.langId);
    runInAction(() => {
      this.state = this.STATE.SUCCESS;
    });
    return response;
  }

  @action.bound
  async doFetchPackages() {
    let response = await this.service.fetchPackages(this.token);
    return response;
  }

  @action.bound
  async doFetchSubscribedPackages() {
    let response = await this.service.fetchSubscribedPackages(this.user, this.token);
    runInAction(() => {
      if (response.result != null) this.purchasedPackages = response.result;
    });
    return response;
  }

  @action.bound
  async doFetchVideoStream(url) {
    let response = await this.service.fetchData(url, 'GET', null, this.token, {});
    return response;
  }

  @action.bound
  async doLoadTestsListingPage() {
    let response = await this.service.loadTestsListingPage(
      this.user,
      this.langId,
      this.selectedPackageId,
      this.purchaseId,
      this.token
    );
    runInAction(() => {
      this.tests = response.result;
      this.moduleLayoutTests = response.result;
    });
    return response;
  }

  @action.bound
  async doFetchTestQuestions() {
    let response = await this.service.fetchTestQuestions(
      this.selectedPackageId,
      this.currentTestId,
      this.user,
      this.langId,
      this.token
    );
    let existingAnswersResponse = await this.service.fetchSavedAnswers(
      this.user,
      this.token,
      this.selectedPackageId,
      this.purchaseId,
      this.currentTestId
    );
    runInAction(() => {
      this.testToAnswersMap = {};
      if (response.statusCode == 200) {
        this.testToQuestionsMap[this.currentTestId] = response.result.testQuestions;
      }
      if (existingAnswersResponse.statusCode == 200) {
        if (this.testToAnswersMap[existingAnswersResponse.result.testId] == null) {
          this.testToAnswersMap[existingAnswersResponse.result.testId] = {};
        }
        existingAnswersResponse.result.testAnswers.forEach(obj => {
          let question = obj.testQuestionId;
          if (this.testToAnswersMap[existingAnswersResponse.result.testId][question] == null) {
            this.testToAnswersMap[existingAnswersResponse.result.testId][question] = [];
          }
          this.testToAnswersMap[existingAnswersResponse.result.testId][question].push(obj.testQuestionOptionId);
        });
      }
    });
    return response;
  }

  @action.bound
  async doSaveAnswers() {
    let response = await this.service.saveTestAnswers(
      this.user,
      this.token,
      this.selectedPackageId,
      this.purchaseId,
      this.currentTestId,
      this.testToAnswersMap[this.currentTestId]
    );
    return response;
  }

  @action.bound
  async doSubmitAnswers() {
    let response = await this.service.submitAnswers(
      this.user,
      this.token,
      this.selectedPackageId,
      this.purchaseId,
      this.currentTestId,
      this.testToAnswersMap[this.currentTestId]
    );
    return response;
  }

  @action.bound
  async doMarkVideoComplete(videoId) {
    let response = await this.service.markVideoAsCompleted(this.user, this.token, this.purchaseId, videoId);
    return response;
  }

  @action.bound
  async dofetchInstructions(testId) {
    let response = await this.service.fetchInstructions(this.token, testId, this.langId);
    return response;
  }

  @action.bound
  async doFetchCareerInformation() {
    return await this.service.fetchCareerInformation(this.token, this.langId);
  }

  @action.bound
  async doFetchCareerDescInformation(subCategoryId) {
    return await this.service.fetchCareerDescInformation(this.token, this.langId, subCategoryId);
  }

  @action.bound
  async doUpdateCareerDescInformation(subCategoryId, jdId, data) {
    return await this.service.updateCareerDescInformation(this.token, this.langId, subCategoryId, jdId, data);
  }

  @action.bound
  async doCreatePurchaseOrder(packageObj, couponCode) {
    return await this.service.createPurchaseOrder(this.token, this.user, packageObj, couponCode);
  }

  @action.bound
  async doUpdateOrderWithCoupon(orderId, couponCode) {
    return await this.service.updateOrderWithCouponCode(this.token, orderId, couponCode);
  }

  @action.bound
  async doUpdateOrderWithResponse(data) {
    return await this.service.updateOrderWithResponse(this.token, data);
  }

  @action.bound
  async doValidateCoupon(couponCode, packageId) {
    return await this.service.validateCoupon(this.token, couponCode, packageId);
  }

  @action.bound
  async doFetchCoupons(id) {
    return await this.service.fetchCoupons(this.token, id);
  }

  @action.bound
  async doUpsertCoupons(type, couponData) {
    return await this.service.upsertCoupons(this.token, type, couponData);
  }

  @action.bound
  async doGetTransactionsForUser() {
    return await this.service.getTransactionsForUser(this.token, this.user);
  }

  @action.bound
  async createFeedback(feedbackData) {
    return await this.service.createFeedback(feedbackData);
  }

  @action.bound
  async createJdFeedback(feedbackData) {
    return await this.service.createJdFeedback(feedbackData, this.token);
  }

  @action.bound
  async createTestFeedback(feedbackData) {
    return await this.service.createTestFeedback(feedbackData, this.token, this.user);
  }

  @action.bound
  async contactUs(data) {
    return await this.service.contactUs(data);
  }

  @action.bound
  async b2bCreateUser(data) {
    return await this.service.b2bCreateUser(data, this.token);
  }

  @action.bound
  async getUsersByRole(roleId) {
    return await this.service.getUsersByRole(roleId, this.token);
  }

  @action.bound
  async getUserHierarchy(userId) {
    if (userId == null) {
      userId = this.user;
    }
    return await this.service.getUserHierarchy(userId, this.token);
  }

  @action.bound
  async getNoOfTestsTaken() {
    return await this.service.getNoOfTestsTaken(this.user, this.token);
  }

  @action.bound
  async getNoOfTestsPurchased() {
    return await this.service.getNoOfTestsPurchased(this.user, this.token);
  }

  @action.bound
  async addPayment(data) {
    return await this.service.addPayment(data, this.token);
  }

  @action.bound
  async addPackagesToUsers(data) {
    return await this.service.addPackagesToUsers(data, this.token);
  }

  @action.bound
  async bulkCreateUsers(file) {
    return await this.service.bulkCreateUsers(file, this.token);
  }

  @action
  setSelectedPurchaseId = id => {
    runInAction(() => {
      this.purchaseId = id;
    });
  };

  @action
  setSelectedPackageId = id => {
    runInAction(() => {
      this.selectedPackageId = id;
    });
  };

  @action
  setCurrentCategoryId = id => {
    runInAction(() => {
      this.currentCategoryId = id;
    });
  };

  @action
  setCurrentTestId = id => {
    runInAction(() => {
      this.currentTestId = id;
    });
  };

  @action
  setCurrentTestDuration = duration => {
    runInAction(() => {
      this.currentTestDuration = duration;
    });
  };

  @action
  setAnswer = (testId, questionId, option) => {
    runInAction(() => {
      if (this.testToAnswersMap[testId] == null) {
        this.testToAnswersMap[testId] = {};
      }
      this.testToAnswersMap[testId][questionId] = [option];
    });
    this.doSaveAnswers();
  };

  @action
  setMultiAnswer = (testId, questionId, option, remove = true) => {
    runInAction(() => {
      if (this.testToAnswersMap[testId] == null) {
        this.testToAnswersMap[testId] = {};
      }
      if (this.testToAnswersMap[testId][questionId] == null) {
        this.testToAnswersMap[testId][questionId] = [];
      }
      if (!remove) {
        this.testToAnswersMap[testId][questionId].push(option);
      } else {
        while (this.testToAnswersMap[testId][questionId].indexOf(option) !== -1) {
          this.testToAnswersMap[testId][questionId].splice(
            this.testToAnswersMap[testId][questionId].indexOf(option),
            1
          );
        }
      }
    });
    this.doSaveAnswers();
  };

  @action.bound
  setResponse(response) {
    this.response = response;
  }

  @action.bound
  reset() {
    this.user = {};
    this.token = null;
    this.state = null;
    this.response = null;
    this.premodules = null;
    this.service.logout();
  }
}

const instance = new SwotStore();

export default instance;
