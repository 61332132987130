import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import _ from 'lodash';

import { useSwotStore } from '../../store';

const InstructionsLayout = observer(({ testId }) => {
  const { swotStore } = useSwotStore();
  const { setCurrentTestId } = swotStore;

  const [instructions, setInstructions] = useState(null);

  useEffect(() => {
    swotStore.dofetchInstructions(testId).then(response => {
      if (response.statusCode === 404) {
        setInstructions([]);
      } else if (response.statusCode !== 200) {
        toast.error("Couldn't fetch the instructions.");
      } else {
        setInstructions(response.result);
      }
    });
  }, [testId]);

  if (instructions == null) {
    return <></>;
  }

  return (
    <>
      <div className="mt-7">
        {!_.isEmpty(instructions) && <h5 className="mb-3 text-center"> Test Instructions </h5>}
        {instructions.map(instruction => (
          <div style={{ fontSize: '18px' }} dangerouslySetInnerHTML={{ __html: instruction.instructionDesc }} />
        ))}
      </div>
      <div className="text-center">
        <Button
          color="primary"
          onClick={() => {
            setCurrentTestId(testId);
          }}
        >
          Start Test
        </Button>
      </div>
    </>
  );
});

export default InstructionsLayout;
