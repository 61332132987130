import React, { useState } from 'react';
import { Button, Modal } from 'reactstrap';
import { observer } from 'mobx-react-lite';
import Lottie from 'react-lottie';

import downloadAnimation from './downloading-animation.json';
import { useSwotStore } from '../../store/index';
import BEService from '../../services/BEService';
import { getBaseUrl } from '../../helpers/envUtils';

const options = {
  loop: true,
  autoplay: true,
  animationData: downloadAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMinYMin slice'
  }
};

const TestReportDownloader = observer(() => {
  const [open, setOpen] = useState(false);
  const [objectUrl, setObjectUrl] = useState(null);

  const { swotStore } = useSwotStore();
  const { purchaseId, user, token } = swotStore;

  const service = new BEService();
  const BASE_URL = getBaseUrl();

  const url = `${BASE_URL}/api/v1/test/generatereport?purchaseId=${purchaseId}&publicUserId=${user}`;

  const downloadFile = () => {
    setOpen(true);
    fetch(url, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(resp => resp.blob())
      .then(resp => {
        let url = URL.createObjectURL(resp);
        let a = document.createElement('a');
        a.href = url;
        a.download = 'SwotCareersReport.pdf';
        a.click();
        a.remove();
        setTimeout(() => window.URL.revokeObjectURL(url), 100);
        setOpen(false);
      });
  };

  return (
    <>
      <Modal centered isOpen={open}>
        <Lottie options={options} style={{ width: '120px', height: '120px' }} />
        <h5 className="text-center m-4">Downloading the report. Please wait.</h5>
      </Modal>
      <div className="text-center mt-4">
        <Button color="primary" onClick={downloadFile}>
          Download Tests Report
        </Button>
      </div>
    </>
  );
});

export default TestReportDownloader;
