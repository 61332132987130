import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Form } from 'reactstrap';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';

import FalconCardHeader from '../common/FalconCardHeader';
import FormGroupInput from '../common/FormGroupInput';
import { useSwotStore } from '../../store';
import withRedirect from '../../hoc/withRedirect';

const SettingsChangePassword = observer(({ setRedirect, setRedirectUrl, hasLabel, layout }) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (oldPassword === '' || newPassword === '' || confirmPassword === '') return setIsDisabled(true);

    setIsDisabled(newPassword !== confirmPassword);
  }, [oldPassword, newPassword, confirmPassword]);

  useEffect(() => {
    setRedirectUrl(`/modules`);
  }, [setRedirectUrl, layout]);

  const { swotStore } = useSwotStore();

  const handleSubmit = e => {
    e.preventDefault();
    swotStore.doUpdatePassword(oldPassword, newPassword).then(response => {
      if (response.statusCode == 501) {
        toast.error("Old password didn't match our records.");
      } else if (response.statusCode != 200) {
        toast.error('Unable to update password. Please try again.');
      } else {
        toast.success('Successfully updated password.');
        setRedirect(true);
      }
    });
    setOldPassword('');
    setNewPassword('');
    setConfirmPassword('');
  };

  return (
    <Card className="mb-3">
      <FalconCardHeader title="Change Password" light={false} />
      <CardBody className="bg-light">
        <Form onSubmit={handleSubmit} style={{ width: '40%' }}>
          <FormGroupInput
            id="old-password"
            label="Old Password"
            value={oldPassword}
            onChange={({ target }) => setOldPassword(target.value)}
            type="password"
          />
          <FormGroupInput
            id="new-password"
            label="New Password"
            value={newPassword}
            onChange={({ target }) => setNewPassword(target.value)}
            type="password"
          />
          <FormGroupInput
            id="confirm-password"
            label="Confirm Password"
            value={confirmPassword}
            onChange={({ target }) => setConfirmPassword(target.value)}
            type="password"
            validationError={newPassword != confirmPassword ? "Passwords don't match" : null}
          />
          <Button color="primary" block disabled={isDisabled}>
            Update Password
          </Button>
        </Form>
      </CardBody>
    </Card>
  );
});

export default withRedirect(SettingsChangePassword);
