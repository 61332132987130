import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Datetime from 'react-datetime';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import _ from 'lodash';
import moment from 'moment';

import { useSwotStore } from '../../store/index';
import Select from '../../components/common/Select';

const TODAY = moment();

const IS_ACTIVE_ROLES = [6,7,'6','7']; // Admin levels 1,2

const User = observer(({ UserObj }) => {
  const [data, setData] = useState({
    isActive: 1,
    ...UserObj
  });

  const [users, setUsers] = useState([]);
  const [parent, setParent] = useState(null);

  const [error, setError] = useState({});

  const [packages, setPackages] = useState([
    {
      label: 'Select All',
      value: -1
    }
  ]);
  const selectedPackages = _.isEmpty(data.testPackageIds)
    ? []
    : packages.filter(pack => data.testPackageIds.includes(pack.value));

  const type = UserObj == null ? 'create' : 'update';

  const { swotStore } = useSwotStore();

  useEffect(() => {
    swotStore.doFetchPackages().then(response => {
      setPackages([
        {
          label: 'Select All',
          value: -1
        },
        ...response.result.map(pack => ({
          label: pack.packageName,
          value: pack.packageId
        }))
      ]);
    });
  }, []);

  const handleChange = fieldName => e => {
    const value = e.target.value;
    console.log(fieldName, value);
    setData({
      ...data,
      [fieldName]: value
    });
  };

  const fetchUsers = roleId => {
    roleId = Number(roleId);
    swotStore.getUsersByRole(roleId).then(response => {
      if (response.statusCode == 200) {
        let users = response.result;
        users = users.map(user => ({ label: user.emailId, value: user.userId }));
        setUsers(users);
      }
    });
  };

  const upsertUser = () => {
    // console.log(data);
    setError({});
    // Validations
    const mandatoryFields = [];
    let isValid = true;
    let errors = {};
    mandatoryFields.forEach(field => {
      if (_.isEmpty(data[field])) {
        errors[field] = `${field} is mandatory`;
        isValid = false;
      }
    });
    // if (isNaN(data.UserDiscount) || Number(data.UserDiscount) <= 0) {
    //   errors['UserDiscount'] = `UserCode should be a number greater than 0`;
    // }

    setError(errors);

    if (!isValid) return;

    swotStore.b2bCreateUser(data).then(response => {
      if (response.statusCode !== 200) {
        toast.error(`Couldn't ${type} User`);
      } else {
        toast.success(`User ${type} Success`);
      }
    });
  };

  console.log("data", data);

  return (
    <Form
      onSubmit={event => {
        event.preventDefault();
        upsertUser();
      }}
    >
      <FormGroup>
        <Label for="emailId">Email Id</Label>
        <Input
          type="email"
          name="emailId"
          id="emailId"
          disabled={type === 'update'}
          value={data.emailId || ''}
          onChange={handleChange('emailId')}
          required
        />
        {error.emailId != null && <p className="text-danger">{error.emailId}</p>}
      </FormGroup>
      <FormGroup>
        <Label for="address">Address</Label>
        <Input
          type="text"
          name="address"
          id="address"
          disabled={type === 'update'}
          value={data.address || ''}
          onChange={handleChange('address')}
        />
        {error.address != null && <p className="text-danger">{error.address}</p>}
      </FormGroup>

      <FormGroup>
        <Label for="nameOfCompany">Name Of Company</Label>
        <Input
          type="text"
          name="nameOfCompany"
          id="nameOfCompany"
          disabled={type === 'update'}
          value={data.nameOfCompany || ''}
          onChange={handleChange('nameOfCompany')}
        />
        {error.nameOfCompany != null && <p className="text-danger">{error.nameOfCompany}</p>}
      </FormGroup>

      <FormGroup>
        <Label for="nameOfOffcier">Name Of Officer</Label>
        <Input
          type="text"
          name="nameOfOffcier"
          id="nameOfOffcier"
          disabled={type === 'update'}
          value={data.nameOfOffcier || ''}
          onChange={handleChange('nameOfOffcier')}
        />
        {error.nameOfOffcier != null && <p className="text-danger">{error.nameOfOffcier}</p>}
      </FormGroup>

      <FormGroup>
        <Label for="designationOfPerson">Designation Of Person</Label>
        <Input
          type="text"
          name="designationOfPerson"
          id="designationOfPerson"
          disabled={type === 'update'}
          value={data.designationOfPerson || ''}
          onChange={handleChange('designationOfPerson')}
        />
        {error.designationOfPerson != null && <p className="text-danger">{error.designationOfPerson}</p>}
      </FormGroup>

      <FormGroup>
        <Label for="pancard">Pancard</Label>
        <Input
          type="text"
          name="pancard"
          id="pancard"
          disabled={type === 'update'}
          value={data.pancard || ''}
          onChange={handleChange('pancard')}
          pattern="[a-zA-Z0-9]{9}"
          title="Pan card must be of 9 digits"
        />
        {error.pancard != null && <p className="text-danger">{error.pancard}</p>}
      </FormGroup>

      <FormGroup>
        <Label for="alternativeMobileNo">Alternative Mobile No</Label>
        <Input
          type="text"
          name="alternativeMobileNo"
          id="alternativeMobileNo"
          disabled={type === 'update'}
          value={data.alternativeMobileNo || ''}
          onChange={handleChange('alternativeMobileNo')}
          pattern="[0-9]{10}"
          title="Mobile Number should be 10 digits"
        />
        {error.alternativeMobileNo != null && <p className="text-danger">{error.alternativeMobileNo}</p>}
      </FormGroup>

      <FormGroup>
        <Label for="msmeNo">Msme No</Label>
        <Input
          type="text"
          name="msmeNo"
          id="msmeNo"
          disabled={type === 'update'}
          value={data.msmeNo || ''}
          onChange={handleChange('msmeNo')}
        />
        {error.msmeNo != null && <p className="text-danger">{error.msmeNo}</p>}
      </FormGroup>

      <FormGroup>
        <Label for="incorporationCertificate">Incorporation Certificate</Label>
        <Input
          type="text"
          name="incorporationCertificate"
          id="incorporationCertificate"
          disabled={type === 'update'}
          value={data.incorporationCertificate || ''}
          onChange={handleChange('incorporationCertificate')}
        />
        {error.incorporationCertificate != null && <p className="text-danger">{error.incorporationCertificate}</p>}
      </FormGroup>

      <FormGroup>
        <Label for="gstIn">Gst In</Label>
        <Input
          type="text"
          name="gstIn"
          id="gstIn"
          disabled={type === 'update'}
          value={data.gstIn || ''}
          onChange={handleChange('gstIn')}
        />
        {error.gstIn != null && <p className="text-danger">{error.gstIn}</p>}
      </FormGroup>

      <FormGroup>
        <Label for="gstNo">Gst No</Label>
        <Input
          type="text"
          name="gstNo"
          id="gstNo"
          disabled={type === 'update'}
          value={data.gstNo || ''}
          onChange={handleChange('gstNo')}
        />
        {error.gstNo != null && <p className="text-danger">{error.gstNo}</p>}
      </FormGroup>

      <FormGroup>
        <Label for="gstPersonName">Gst Person Name</Label>
        <Input
          type="text"
          name="gstPersonName"
          id="gstPersonName"
          disabled={type === 'update'}
          value={data.gstPersonName || ''}
          onChange={handleChange('gstPersonName')}
        />
        {error.gstPersonName != null && <p className="text-danger">{error.gstPersonName}</p>}
      </FormGroup>

      <FormGroup>
        <Label for="gstrFilling">Gstr Filling</Label>
        <Input
          type="text"
          name="gstrFilling"
          id="gstrFilling"
          disabled={type === 'update'}
          value={data.gstrFilling || ''}
          onChange={handleChange('gstrFilling')}
        />
        {error.gstrFilling != null && <p className="text-danger">{error.gstrFilling}</p>}
      </FormGroup>

      <FormGroup>
        <Label for="officeEmailId">Office Email Id</Label>
        <Input
          type="email"
          name="officeEmailId"
          id="officeEmailId"
          disabled={type === 'update'}
          value={data.officeEmailId || ''}
          onChange={handleChange('officeEmailId')}
        />
        {error.officeEmailId != null && <p className="text-danger">{error.officeEmailId}</p>}
      </FormGroup>

      <FormGroup>
        <Label for="offcieMobileNo">Office Mobile No</Label>
        <Input
          type="text"
          name="offcieMobileNo"
          id="offcieMobileNo"
          disabled={type === 'update'}
          value={data.offcieMobileNo || ''}
          onChange={handleChange('offcieMobileNo')}
          pattern="[0-9]{10}"
          title="Mobile Number should be 10 digits"
        />
        {error.offcieMobileNo != null && <p className="text-danger">{error.offcieMobileNo}</p>}
      </FormGroup>

      <FormGroup>
        <Label for="bankAccountNumber">Bank Account Number</Label>
        <Input
          type="text"
          name="bankAccountNumber"
          id="bankAccountNumber"
          disabled={type === 'update'}
          value={data.bankAccountNumber || ''}
          onChange={handleChange('bankAccountNumber')}
        />
        {error.bankAccountNumber != null && <p className="text-danger">{error.bankAccountNumber}</p>}
      </FormGroup>

      <FormGroup>
        <Label for="bankName">Bank Name</Label>
        <Input
          type="text"
          name="bankName"
          id="bankName"
          disabled={type === 'update'}
          value={data.bankName || ''}
          onChange={handleChange('bankName')}
        />
        {error.bankName != null && <p className="text-danger">{error.bankName}</p>}
      </FormGroup>

      <FormGroup>
        <Label for="bankAddress">Bank Address</Label>
        <Input
          type="text"
          name="bankAddress"
          id="bankAddress"
          disabled={type === 'update'}
          value={data.bankAddress || ''}
          onChange={handleChange('bankAddress')}
        />
        {error.bankAddress != null && <p className="text-danger">{error.bankAddress}</p>}
      </FormGroup>

      <FormGroup>
        <Label for="ifscCode">Ifsc Code</Label>
        <Input
          type="text"
          name="ifscCode"
          id="ifscCode"
          disabled={type === 'update'}
          value={data.ifscCode || ''}
          onChange={handleChange('ifscCode')}
        />
        {error.ifscCode != null && <p className="text-danger">{error.ifscCode}</p>}
      </FormGroup>

      <FormGroup>
        <Label for="natureOfTheBusiness">Nature Of The Business</Label>
        <Input
          type="text"
          name="natureOfTheBusiness"
          id="natureOfTheBusiness"
          disabled={type === 'update'}
          value={data.natureOfTheBusiness || ''}
          onChange={handleChange('natureOfTheBusiness')}
        />
        {error.natureOfTheBusiness != null && <p className="text-danger">{error.natureOfTheBusiness}</p>}
      </FormGroup>

      <FormGroup>
        <Label for="nameOfConcernPerson">Name Of Concern Person</Label>
        <Input
          type="text"
          name="nameOfConcernPerson"
          id="nameOfConcernPerson"
          disabled={type === 'update'}
          value={data.nameOfConcernPerson || ''}
          onChange={handleChange('nameOfConcernPerson')}
        />
        {error.nameOfConcernPerson != null && <p className="text-danger">{error.nameOfConcernPerson}</p>}
      </FormGroup>
      <FormGroup>
        <Label for="roleId">Role Id</Label>
        <Input
          type="select"
          name="roleId"
          id="roleId"
          value={data.roleId || ''}
          onChange={event => {
            handleChange('roleId')(event);
            fetchUsers(event.target.value);
          }}
        >
          <option value="null"> Select </option>
          <option value="1">Admin</option>
          <option value="2">Client Level 1</option>
          <option value="3">Client Level 2</option>
          <option value="4">Client Level 3</option>
          <option value="5">Client Level 4</option>
          <option value="6">Admin Level 1</option>
          <option value="7">Admin Level 2</option>
          <option value="8">Admin Level 3</option>
          <option value="9">Admin Level 4</option>
        </Input>
        {error.roleId != null && <p className="text-danger">{error.roleId}</p>}
      </FormGroup>

      {!_.isEmpty(users) && (
        <FormGroup>
          <Label for="user">Select a parent user</Label>
          <div className="border">
            <Select
              styles={{
                border: '1px solid #d8e2ef !important'
              }}
              options={users}
              value={parent}
              onChange={user => {
                setData({
                  ...data,
                  parentId: user.value
                });
                setParent(user);
              }}
            />
          </div>
        </FormGroup>
      )}

      {IS_ACTIVE_ROLES.includes(data?.roleId) && (
        <FormGroup className="form-check">
          <Input
            type="checkbox"
            name="check"
            id="exampleCheck"
            checked={data.isActive === 1}
            onChange={e => {
              setData({
                ...data,
                isActive: e.target.checked ? 1 : 0
              });
            }}
          />
          <Label for="exampleCheck" check>
            Is Active
          </Label>
        </FormGroup>
      )}
      <Button
        color="primary"
        // onClick={() => {
        //   upsertUser();
        //   return false;
        // }}
        type="submit"
      >
        Save
      </Button>
    </Form>
  );
});

export default User;
