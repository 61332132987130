import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Button,
  FormGroup
} from 'reactstrap';

import { getSelectedOption } from './util';
import { useSwotStore } from '../../../store/index';

const TextQuestions = observer(
  ({ currentTestId, questions, inlineOptions = false, inlineForm = false, htmlText = false }) => {
    const { swotStore } = useSwotStore();
    const { testToAnswersMap, setAnswer } = swotStore;

    return (
      <>
        {questions.map((questionObj, index) => (
          <Card style={{ boxShadow: 'none' }}>
            <CardBody>
              <CardTitle
                style={questionObj.questionName != null && questionObj.questionName != '' ? {} : { display: 'inline' }}
              >
                {index + 1}.{' '}
                {questionObj.questionName != null &&
                  questionObj.questionName != '' &&
                  (!htmlText ? (
                    questionObj.questionName
                  ) : (
                    <span style={{ fontSize: '18px' }} dangerouslySetInnerHTML={{ __html: questionObj.questionName }} />
                  ))}
              </CardTitle>
              {questionObj.questionImage != null && questionObj.questionImage != '' && (
                <img src={questionObj.questionImage} alt={questionObj.questionId} />
              )}
              <CardText className="mt-2" style={inlineForm ? { display: 'inline' } : {}}>
                <FormGroup style={inlineForm ? { display: 'inline' } : {}}>
                  {questionObj.testQuestionOptions.map(option => (
                    <div className="ml-5 mb-3" style={inlineOptions ? { display: 'inline' } : {}}>
                      <Input
                        type="radio"
                        name={option.optionName + index}
                        key={option.optionId}
                        value={option.optionId}
                        checked={getSelectedOption(
                          testToAnswersMap,
                          currentTestId,
                          questionObj.questionId,
                          option.optionId
                        )}
                        onChange={() => {
                          console.log('Setting answer...', currentTestId, questionObj.questionId, option.optionId);
                          setAnswer(currentTestId, questionObj.questionId, option.optionId);
                        }}
                      />
                      <Label check style={{ marginTop: '2px' }}>
                        {!htmlText ? (
                          option.optionName
                        ) : (
                          <span style={{ fontSize: '18px' }} dangerouslySetInnerHTML={{ __html: option.optionName }} />
                        )}
                      </Label>
                    </div>
                  ))}
                </FormGroup>
              </CardText>
            </CardBody>
          </Card>
        ))}
      </>
    );
  }
);

export default TextQuestions;
