import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Card, CardBody, Col, CustomInput, Label, Row } from 'reactstrap';
import _ from 'lodash';
import { toast } from 'react-toastify';

import { useSwotStore } from '../store';
import PageLoader from '../components/common/PageLoader';
import FalconCardHeader from '../components/common/FalconCardHeader';
import { useParams } from 'react-router-dom';

const JDDescLayout = observer(() => {
  const { id } = useParams();

  const [data, setData] = useState(null);

  const { swotStore } = useSwotStore();

  useEffect(() => {
    swotStore.doFetchCareerDescInformation(id).then(response => {
      if (response.statusCode === 504) {
        setData('');
      } else if (response.statusCode !== 200) {
        toast.error("Couldn't fetch the career information. Please try again.");
        setData('');
      } else {
        setData(response.result.jdDescription);
      }
    });
  }, []);
  return data == null ? (
    <PageLoader />
  ) : (
    <>
      <Card className="mb-3 h-100">
        <FalconCardHeader title="Job Descriptions" />
        <CardBody className="fs--1">
          {data === '' ? <p>No data found.</p> : <div dangerouslySetInnerHTML={{ __html: data }} />}
        </CardBody>
      </Card>
    </>
  );
});

export default JDDescLayout;
