import React from 'react';

import ImageQuestions from './ImageQuestions';
import TextQuestions from './TextQuestions';
import TextQuestionSingleOption from './TextQuestionSingleOption';
import MultiSelectionQuestions from './MultiSelectionQuestions';

const getQuestionComponentFromTestId = testId => {
  if (8 === testId) {
    // Spatial Ability
    return ImageQuestions;
  } else if (1 === testId) {
    return TextQuestionSingleOption;
  } else if (12 === testId) {
    // Career Inspiration
    return MultiSelectionQuestions;
  } else if (13 === testId) {
    // Learning Style
    return MultiSelectionQuestions;
  }
  return TextQuestions;
};

const getAdditionalPropsFromTestId = testId => {
  if (4 === testId) {
    // Closure Ability
    return {
      inlineOptions: true
    };
  } else if (6 === testId) {
    // Numberical Ability
    return {
      inlineOptions: true,
      htmlText: true
    };
  } else if (7 === testId) {
    // Numberical Ability
    return {
      inlineOptions: true,
      inlineForm: true
    };
  } else if (13 === testId) {
    // Learning Style
    return {
      maxLimit: 3
    };
  } else if (14 === testId) {
    // Study Habits
    return {
      inlineOptions: true
    };
  }
  return {};
};

export { getQuestionComponentFromTestId, getAdditionalPropsFromTestId };
