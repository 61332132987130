import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';

import { Card, CardBody, CardTitle, CardDeck, Collapse } from 'reactstrap';
import { Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Slider from 'react-slick';

import { useSwotStore } from '../../store';
import PageLoader from '../../components/common/PageLoader';
import InstructionsLayout from './InstructionsLayout';

const TestCard = ({ test, completed, setter, durationSetter }) => {
  const onClick = completed
    ? () => {}
    : () => {
        setter(toJS(test.testId));
        if (test.testDuration != null) {
          let time = test.testDuration.split(':').reverse();
          let duration = 0;
          for (let i = 0; i < time.length; i++) {
            if (i === 0) {
              duration += Number(time[i]);
            } else {
              duration += i * 60 * Number(time[i]);
            }
            durationSetter(duration * 1000);
          }
        } else {
          durationSetter(null);
        }
      };
  const styles = { width: '90%', border: '1px solid black', maxWidth: '300px', margin: 'auto' }; // { minHeight: '100px', minWidth: '100px' };
  let classNames = 'h-100';
  if (!completed) {
    classNames += ' cursor-pointer card-span ';
  } else {
    styles.background = '#b5b1b1';
  }

  return (
    <Card style={styles} key={test.testId} className={classNames} onClick={onClick}>
      <CardBody>
        <CardTitle tag="h5">{test.testName}</CardTitle>
        {/* {test.testCode && <CardText style={{ marginTop: '200px' }}>{test.testCode}</CardText>} */}
        {completed ? (
          <Badge color="soft-secondary" pill className="fs--2">
            Completed
            <FontAwesomeIcon icon="check" transform="shrink-2" className=" ml-1" />
          </Badge>
        ) : (
          <Badge color="soft-primary" pill className="fs--2">
            Start Test
          </Badge>
        )}
      </CardBody>
    </Card>
  );
};

const TestsListing = observer(({ currentCategoryId, testId, setTestId }) => {
  const { swotStore } = useSwotStore();
  const { tests, setCurrentCategoryId, currentTestId, setCurrentTestId, setCurrentTestDuration } = swotStore;
  console.log("All tests", toJS(tests), currentCategoryId);

  // const [testId, setTestId] = useState(null);

  if (tests == null || currentCategoryId == null) {
    return <PageLoader />;
  }

  const testsToList = getCurrentTests(tests, currentCategoryId);
  console.log('Test to slider ', testsToList);

  const slidesToShow = testsToList.length > 2 ? 3 : testsToList.length;

  return (
    <>
      <div>
        <Slider
          dots={true}
          infinite={false}
          speed={500}
          slidesToShow={slidesToShow}
          slidesToScroll={1}
          responsive={[
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            }
          ]}
        >
          {testsToList.map((test, index) => (
            <TestCard
              key={index}
              test={test}
              completed={test.testSubmittedStatus}
              setter={setTestId}
              durationSetter={setCurrentTestDuration}
            />
          ))}
        </Slider>
      </div>
      <div className="mt-3">
        <Collapse isOpen={testId != null}>{testId != null && <InstructionsLayout testId={testId} />}</Collapse>
      </div>
    </>
  );
});

const getCurrentTests = (tests, currentCategoryId) => {
  let category = tests.filter(test => test.categoryId == currentCategoryId)[0];
  let sortedTests = category.tests.slice().sort((a, b) => a.testId - b.testId);
  return sortedTests;
};

export default TestsListing;
