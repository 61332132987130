import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Card, CardBody, Col, CustomInput, Label, Row, Table, Form, FormGroup } from 'reactstrap';
import _ from 'lodash';
// import Tree from 'react-hierarchy-tree-graph';
import { Tree } from 'react-d3-tree';

import FalconCardHeader from '../../components/common/FalconCardHeader';
import { useSwotStore } from '../../store';
import Select from '../../components/common/Select';
import PageLoader from '../../components/common/PageLoader';
import { toast } from '../../../node_modules/react-toastify/index';

let myTreeData = [
  {
    name: 'testing@gmail.com',
    attributes: {
      userId: 11,
      emailId: 'testing@gmail.com',
      firstName: 'Testing abc',
      lastName: 'abc',
      isActive: true,
      highestEducation: 3,
      dateOfBirth: '1995-07-12T18:30:00.000+00:00',
      mobileNo: '1234567890',
      gender: 'Male',
      publicUserId: 'bb97dfd8-4ee2-48d0-9df5-e92a7b92b255',
      preferedLang: 1,
      address: 'kukatpally',
      city: 'hyderabad',
      state: 'Telangana',
      pinCode: 500072
    },
    children: [
      {
        name: 'testing1@gmail.com',
        attributes: {
          userId: 12,
          emailId: 'testing1@gmail.com',
          isActive: true,
          publicUserId: 'd9d26f95-93d4-45b5-ace8-d5d11eae07e5',
          preferedLang: 1,
          parentId: 11
        },
        children: []
      },
      {
        name: 'prashantsurya002@gmail.com',
        attributes: {
          userId: 13,
          emailId: 'prashantsurya002@gmail.com',
          firstName: 'Surya 1234',
          lastName: 'Prashanth',
          isActive: true,
          publicUserId: 'fe551de3-f688-44ce-838d-e69b0d1bffd9',
          preferedLang: 1,
          parentId: 11
        },
        children: []
      },
      {
        name: 'testabcd@gmail.com',
        attributes: {
          userId: 14,
          emailId: 'testabcd@gmail.com',
          isActive: true,
          publicUserId: '3ebc4985-e2a9-48eb-a013-32c28cfbf469',
          preferedLang: 1,
          parentId: 11
        },
        children: []
      },
      {
        name: 'p.sadik425@gmail.com',
        attributes: {
          userId: 15,
          emailId: 'p.sadik425@gmail.com',
          isActive: true,
          publicUserId: 'd15f2e7b-9f4b-4fd8-86b5-64213c92cf3c',
          preferedLang: 1,
          parentId: 11
        },
        children: []
      },
      {
        name: 'pattan.sadik@gmail.com',
        attributes: {
          userId: 18,
          emailId: 'pattan.sadik@gmail.com',
          firstName: 'Sadik demo',
          lastName: 'pattan',
          isActive: true,
          highestEducation: 3,
          dateOfBirth: '1991-04-01T18:30:00.000+00:00',
          mobileNo: '9797979797',
          gender: 'Male',
          publicUserId: '5e848553-4b70-450c-a7e5-6631d1cba80b',
          preferedLang: 1,
          address: '17-191,\nEast street,\nVenkatagiri\n566666.',
          city: 'dfsdg',
          state: 'Andhra pradesh',
          pinCode: 567777,
          parentId: 11
        },
        children: [
          {
            name: 'testabcd@gmail.com',
            attributes: {
              userId: 14,
              emailId: 'testabcd@gmail.com',
              isActive: true,
              publicUserId: '3ebc4985-e2a9-48eb-a013-32c28cfbf469',
              preferedLang: 1,
              parentId: 11
            },
            children: []
          },
          {
            name: 'p.sadik425@gmail.com',
            attributes: {
              userId: 15,
              emailId: 'p.sadik425@gmail.com',
              isActive: true,
              publicUserId: 'd15f2e7b-9f4b-4fd8-86b5-64213c92cf3c',
              preferedLang: 1,
              parentId: 11
            },
            children: [
              {
                name: 'testabcd@gmail.com',
                attributes: {
                  userId: 14,
                  emailId: 'testabcd@gmail.com',
                  isActive: true,
                  publicUserId: '3ebc4985-e2a9-48eb-a013-32c28cfbf469',
                  preferedLang: 1,
                  parentId: 11
                },
                children: []
              },
              {
                name: 'p.sadik425@gmail.com',
                attributes: {
                  userId: 15,
                  emailId: 'p.sadik425@gmail.com',
                  isActive: true,
                  publicUserId: 'd15f2e7b-9f4b-4fd8-86b5-64213c92cf3c',
                  preferedLang: 1,
                  parentId: 11
                },
                children: []
              }
            ]
          }
        ]
      }
    ]
  }
];

const TreeNode = ({ nodeData }) => (
  <div>
    <div>
      <div>
        <h5 style={{ margin: '5px', fontSize: '14px' }}>{nodeData.name}</h5>
        <h6 style={{ margin: '5px', fontSize: '10px' }}>{nodeData.attributes.emailId}</h6>
        {/* <p style={{ margin: '5px', fontSize: '10px' }}>{nodeData.attributes.publicUserId}</p> */}
      </div>
    </div>
  </div>
);

const UserHierarchy = observer(() => {
  const { swotStore } = useSwotStore();

  const [dimensions, setDimensions] = useState(null);

  const [users, setUsers] = useState(null);
  const [user, setUser] = useState(null);

  const [userHierarchy, setUserHierarchy] = useState(null);

  const treeContainerRef = useCallback(node => {
    console.log('Calling tree callback');
    if (node != null && dimensions == null) {
      setDimensions(node.getBoundingClientRect());
    }
  });

  useEffect(() => {
    swotStore.getUsersByRole(1).then(response => {
      if (response.statusCode != 200) {
        toast.error("Couldn't fetch users. Please try again");
      } else {
        setUsers(response.result.map(user => ({ label: user.emailId, value: user.publicUserId })));
      }
    });
  }, []);

  const fetchHierarchy = user => {
    setUser(user);
    swotStore.getUserHierarchy(user.value).then(response => {
      if (response.statusCode != 200) {
        toast.error("Couldn't fetch hierarchy. Please try again.");
      } else {
        setUserHierarchy(response.result);
      }
    });
  };

  return users == null ? (
    <PageLoader />
  ) : (
    <Card className="mb-3">
      <CardBody>
        <FalconCardHeader title="User Hierarchy" light={false} className="text-center" />
        <div
          style={{
            width: '100%',
            height: '100vh'
          }}
          ref={treeContainerRef}
        >
          <Form>
            <FormGroup>
              <Label for="user">Select a user</Label>
              <div className="border">
                <Select
                  styles={{
                    border: '1px solid #d8e2ef !important'
                  }}
                  options={users}
                  value={user}
                  onChange={fetchHierarchy}
                />
              </div>
            </FormGroup>
          </Form>
          {dimensions != null && userHierarchy != null && (
            <Tree
              data={userHierarchy}
              collapsible={true}
              scaleExtent={{ min: 1, max: 3 }}
              orientation="vertical"
              translate={{
                x: dimensions.width / 2,
                y: 100
              }}
              nodeSvgShape={{ shape: 'none' }}
              nodeSize={{ x: 300, y: 150 }}
              pathFunc="elbow"
              allowForeignObjects={true}
              nodeLabelComponent={{
                render: <TreeNode />,
                foreignObjectWrapper: {
                  style: {
                    background: 'lightblue',
                    border: '1px solid black',
                    width: '200px',
                    height: '80px',
                    x: 150 / -2,
                    y: 80 / -2
                  }
                }
              }}
              onClick={args => {
                console.log('Clicked', args);
                args['_collapsed'] = !args['_collpsed'];
              }}
            />
          )}
        </div>
      </CardBody>
    </Card>
  );
});

export default UserHierarchy;
