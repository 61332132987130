import React from 'react';
import { observer } from 'mobx-react-lite';
import { Card, CardBody, CardTitle, CardText } from 'reactstrap';
import _ from 'lodash';

import FalconCardHeader from '../components/common/FalconCardHeader';
const content = [
  {
    title: 'Vision',
    description: '<p>Creating generations of happy working professionals who love their work.</p>'
  },
  {
    title: 'Mission',
    description:
      '<p>To inspire and empower all students towards making fruitful & robust career decisions.</p>'
  },
  {
    title: 'What we do',
    description:
      '<p>We help students identify their best-fit career path and improve their study habits to help them achieve those career goals.</p>'
  },
  {
    title: 'How we do',
    description:
      '<p>With the help of our copyrighted algorithm, our specially designed career maturity program and continuous engagement model, we ensure you can make the best fit career for you.</p>'
  }
];

const AboutLayout = observer(() => {
  return (
    <Card className="mb-3">
      <CardBody>
        <FalconCardHeader title="About Us" light={false} className="text-center" />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}
        >
          {content.map(row => (
            <Card className="mb-3 border" style={{ width: '50%' }}>
              <CardBody>
                <CardTitle>{row.title}</CardTitle>
                <CardText>
                  <div dangerouslySetInnerHTML={{ __html: row.description }} />
                </CardText>
              </CardBody>
            </Card>
          ))}
        </div>
      </CardBody>
    </Card>
  );
});

export default AboutLayout;
