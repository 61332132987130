import React from 'react';
import { Fragment, useState } from 'react';
import { Modal, ModalHeader, ModalFooter, ModalBody, ModalTitle, Button } from 'reactstrap';
import FalconPlyr from './FalconPlyr';

const VideoPlayer = ({ collapse, setCollapse, video, postTask = null }) => {
  return (
    <Fragment>
      <Modal isOpen={collapse} centered size="lg" fade toggle={() => setCollapse(!collapse)}>
        <ModalHeader toggle={() => setCollapse(!collapse)}>{video.fileName}</ModalHeader>
        <ModalBody>
          {/* Woohoo, you're reading this text in a modal! */}
          <div className="rounded-soft overflow-hidden position-relative">
            <FalconPlyr videoId={video.id} type="source" url={video.fileDownloadUri} postTask={setCollapse} />
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default VideoPlayer;
