import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Button
} from 'reactstrap';
import _ from 'lodash';
import Lottie from 'react-lottie';
import { toast } from 'react-toastify';
import Timer from 'react-compound-timer';

import { useSwotStore } from '../../store';
import PageLoader from '../../components/common/PageLoader';
import TextQuestions from './questionComponents/TextQuestions';
import { getAdditionalPropsFromTestId, getQuestionComponentFromTestId } from './questionComponents';
import successAnimationData from './success-animation.json';
import failureAnimationData from '../../components/auth/wizard/lottie/warning-light.json';
import TestFeedbackForm from '../feedback/TestFeedbackForm';

const successOptions = {
  loop: true,
  autoplay: true,
  animationData: successAnimationData,
  rendererSettings: {
    preserveAspectRatio: 'xMinYMin slice'
  }
};

const failureOptions = {
  loop: true,
  autoplay: true,
  animationData: failureAnimationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const TestTakingModal = observer(({ currentTestId, setInstructionTestId }) => {
  const [questions, setQuestions] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [isSubmitDialogOpen, setSubmitDialogOpen] = useState(true);
  const [feedbackOpen, setFeedbackOpen] = useState(false);

  const [responseMsg, setResponseMsg] = useState(null);

  const { swotStore } = useSwotStore();
  const { testToAnswersMap, setAnswer, setCurrentTestId, currentTestDuration } = swotStore;

  const QuestionComponent = getQuestionComponentFromTestId(currentTestId);
  const ComponentProps = getAdditionalPropsFromTestId(currentTestId);

  const submitHandler = () => {
    swotStore.doSubmitAnswers().then(response => {
      setSubmitDialogOpen(true);
      setResponseMsg(null);
      if (response.statusCode == 200) {
        setSubmitStatus(true);
        if (response.result.feedbackFlag == true) {
          setFeedbackOpen(true);
        }
      } else if (response.statusCode == 900) {
        setSubmitStatus(false);
        setResponseMsg(response.errorMsg);
      } else {
        setSubmitStatus(false);
      }
    });
    // setSubmitDialogOpen(true);
    // setSubmitStatus(true);
  };

  useEffect(() => {
    if (currentTestId == null) {
      return;
    }
    swotStore.doFetchTestQuestions().then(response => {
      if (response.statusCode != 200) {
        toast.error("Couldn't load questions");
      } else {
        toast.info('Fetched saved answers...', {
          autoClose: 1000,
          position: 'top-center'
        });
        setQuestions(response.result.testQuestions);
        setOpen(true);
        setSubmitDialogOpen(false);
      }
    });
  }, [currentTestId]);

  const exitFunction = () => {
    console.log('Exiting');
    setCurrentTestId(null);
    setInstructionTestId(null);
  };

  let duration = 5000;

  return (
    <>
      <Modal isOpen={isOpen} size="xl" onClosed={exitFunction} onExit={exitFunction}>
        <ModalHeader
          toggle={() => {
            setOpen(!isOpen);
          }}
          charCode="X"
        >
          Questions
          {currentTestDuration != null && (
            <div
              style={{
                position: 'absolute',
                top: '18px',
                right: '50px',
                visibility: [8, 3].includes(currentTestId) ? 'hidden' : 'visible'
              }}
            >
              <span>Timer: </span>
              <Timer
                initialTime={currentTestDuration + 5}
                // initialTime={duration}
                direction="backward"
                formatValue={value => `${value < 10 ? `0${value}` : value}`}
                onStop={() => {
                  console.log('Timer ended');
                }}
                checkpoints={[
                  {
                    time: 0,
                    callback: () => {
                      toast.info("Timer's up. Submitting the test", {
                        autoClose: 5000,
                        position: 'top-center'
                      });
                      submitHandler();
                    }
                  }
                ]}
              >
                {() => (
                  <>
                    <Timer.Hours />:
                    <Timer.Minutes />:
                    <Timer.Seconds />
                  </>
                )}
              </Timer>
            </div>
          )}
        </ModalHeader>
        <ModalBody>
          <QuestionComponent questions={questions} currentTestId={currentTestId} {...ComponentProps} />
        </ModalBody>
        <ModalFooter>
          <div style={{ textAlign: 'right' }}>
            <Button
              className="mr-3"
              color="primary"
              disabled={_.isEmpty(testToAnswersMap[currentTestId])}
              onClick={() => {
                swotStore.doSaveAnswers();
                toast.info('Saved answers', {
                  autoClose: 1000,
                  position: 'top-center'
                });
              }}
            >
              Save
            </Button>
            <Button
              className="mr-3"
              color="primary"
              disabled={_.isEmpty(testToAnswersMap[currentTestId])}
              onClick={submitHandler}
            >
              Submit Test
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={isSubmitDialogOpen}
        toggle={() => {
          setSubmitDialogOpen(!isSubmitDialogOpen);
        }}
        onClosed={() => {
          if (submitStatus != null && responseMsg == null) {
            setOpen(false);
            swotStore.doLoadTestsListingPage().then(response => {
              console.log('Re fetching tests', response);
            });
          }
        }}
        onExit={() => {
          if (submitStatus != null && responseMsg == null) {
            setOpen(false);
            swotStore.doLoadTestsListingPage().then(response => {
              console.log('Re fetching tests', response);
            });
          }
        }}
        centered
      >
        <ModalBody>
          {submitStatus == null ? (
            <PageLoader classNames="mt-5 mb-5 text-center" />
          ) : submitStatus ? (
            <>
              <Lottie options={successOptions} style={{ width: '120px', height: '120px' }} />
              <h5 className="text-center"> Successfully Submitted the test.</h5>
              {feedbackOpen && <TestFeedbackForm setOpen={setSubmitDialogOpen} />}
            </>
          ) : (
            <>
              <Lottie options={failureOptions} style={{ width: '120px', height: '120px' }} />
              <h5 className="text-center">
                {responseMsg != null ? responseMsg : `Couldn't submit the test. Please try again.`}
              </h5>
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  );
});

export default TestTakingModal;
