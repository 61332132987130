import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Card, CardBody, Col, Container, Input, Label, Row } from 'reactstrap';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Lottie from 'react-lottie';
import { useParams } from 'react-router-dom';

import FalconCardHeader from '../../components/common/FalconCardHeader';
import { useSwotStore } from '../../store';
import PageLoader from '../../components/common/PageLoader';

import successAnimationData from './success-animation.json';
import failureAnimationData from '../../components/auth/wizard/lottie/warning-light.json';
import withRedirect from '../../hoc/withRedirect';

const successOptions = {
  loop: true,
  autoplay: true,
  animationData: successAnimationData,
  rendererSettings: {
    preserveAspectRatio: 'xMinYMin slice'
  }
};

const failureOptions = {
  loop: true,
  autoplay: true,
  animationData: failureAnimationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const OrderLayout = observer(({ setRedirect, setRedirectUrl, layout }) => {
  const { swotStore } = useSwotStore();
  const [orderDetails, setOrderDetails] = useState(null);
  const [couponCode, setCouponCode] = useState('');
  const [couponCodeObj, setCouponCodeObj] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [response, setResponse] = useState(null);
  const [selectedPackageToPurchase, setSelectedPackageToPurchase] = useState(null);

  const { id } = useParams();

  useEffect(() => {
    setRedirectUrl(`/dashboard`);
  }, [setRedirectUrl, layout]);

  useEffect(() => {
    if (id == null) {
      setRedirect(true);
    } else {
      swotStore.doFetchPackages().then(response => {
        if (response.statusCode === 200) {
          const pack = response.result.filter(obj => obj.packageId == id);
          if (!_.isEmpty(pack)) {
            setSelectedPackageToPurchase(pack[0]);
          }
        }
      });
    }
  }, []);

  const { profile } = swotStore;

  const validateCoupon = () => {
    swotStore.doValidateCoupon(couponCode, selectedPackageToPurchase.packageId).then(couponResponse => {
      if (couponResponse.statusCode !== 200) {
        setValidationErrors({
          couponCode: 'Invalid code. Please check the code'
        });
      } else {
        setCouponCodeObj(couponResponse.result);
        setCouponCode('');
      }
    });
  };

  const razorPayResponseHandler = response => {
    console.log('Response from razorpay', response);
    const data = {
      orderId: response.razorpay_order_id,
      transactionid: response.razorpay_payment_id,
      signature: response.razorpay_signature
    };
    if (couponCodeObj != null) {
      data.couponCode = couponCodeObj.couponCode;
    }
    swotStore.doUpdateOrderWithResponse(data).then(response => {
      if (response.statusCode !== 200) {
        setResponse({
          status: 'FAILURE'
        });
      } else {
        setResponse({
          status: 'SUCCESS'
        });
      }
    });
  };

  const getAmountToPay = () => {
    let amount = selectedPackageToPurchase.packageAmount;
    if (couponCodeObj != null) {
      amount -= couponCodeObj.discountAmount;
    }
    return amount;
  };

  const getRazorPayAmount = () => {
    let amount = getAmountToPay();
    amount = amount * 100;
    return '' + amount;
  };

  const getOptions = order => {
    const orderInfo = order ?? orderDetails;
    const options = {
      key: orderInfo.secretKey,
      amount: getRazorPayAmount(),
      currency: 'INR',
      name: 'Swot Careers',
      description: selectedPackageToPurchase.packageName,
      image: 'https://www.swotcareers.com/static/swotauth/images/logonew.jpg',
      order_id: orderInfo.razorpayOrderId,
      prefill: {
        name: getName(profile),
        email: profile.emailId,
        contact: '91' + (profile.mobileNo || '')
      },
      theme: {
        color: '#3399cc'
      },
      handler: razorPayResponseHandler
    };
    console.log('Options are', options);
    return options;
  };

  const createOrder = () => {
    swotStore.doCreatePurchaseOrder(selectedPackageToPurchase, couponCodeObj?.couponCode).then(response => {
      console.log(selectedPackageToPurchase);
      if (response.statusCode === 500) {
        setOrderDetails({ error: response.errorMsg });
      } else if (response.statusCode != 200) {
        toast.error("Couldn't proceed with Transaction. Please try again.");
      } else {
        console.log(response.result);
        setOrderDetails(response.result);
        let orderInfo = response.result;
        // If amount is 0 no need to call razorpay
        if (orderInfo.applicationFee == 0) {
          setResponse({
            status: 'SUCCESS'
          });
          return;
        }
        const rzp1 = new Razorpay(getOptions(orderInfo));
        rzp1.on('payment.failed', function(response) {
          console.log('Failed response: ', response);
        });
        rzp1.open();
      }
    });
  };

  // useEffect(() => {
  //   if (selectedPackageToPurchase == null) return;
  //   if (orderDetails != null) return;
  //   // createOrder();
  // }, []);

  if (selectedPackageToPurchase == null) {
    return <PageLoader />;
  }

  return !_.isEmpty(orderDetails?.error) ? (
    <div style={{ minHeight: '400px' }}>
      <Lottie options={failureOptions} style={{ width: '120px', height: '120px' }} />
      <h6 className="text-center">{orderDetails.error}</h6>
    </div>
  ) : (
    <Card className="mb-3">
      <CardBody>
        <FalconCardHeader title="Confirm Payment" light={false} className="text-center" />
        <Container>
          <div className="row">
            <div className="col-lg-6 mx-auto">
              <div className="card ">
                <div className="card-header">
                  <div className="tab-content">
                    <div id="credit-card" className="tab-pane fade show active pt-3">
                      <div>
                        <div className="input-group">
                          <label htmlFor="username">
                            <p>Name: {getName(profile)}</p>
                          </label>
                        </div>
                        <div className="input-group">
                          <label htmlFor="Mobile Number">
                            <p>Contact Number: {profile.mobileNo || ''}</p>
                          </label>
                        </div>
                        {/* <div className="input-group">
                          <label htmlFor="planName">
                            <p>Order ID: {orderDetails.razorpayOrderId} </p>
                          </label>
                        </div> */}
                        <div className="order-md-2 mb-4">
                          <h4 className="d-flex justify-content-between align-items-center mb-3">
                            <span className="text-muted">Your cart</span>
                          </h4>
                          <ul className="list-group mb-3">
                            <li className="list-group-item d-flex justify-content-between lh-condensed">
                              <div>
                                <h6 className="my-0">{selectedPackageToPurchase.packageName}</h6>
                                {selectedPackageToPurchase.packageDesc != null && (
                                  <small className="text-muted">{selectedPackageToPurchase.packageDesc}</small>
                                )}
                              </div>
                              <span className="text-muted">
                                Rs. {orderDetails?.applicationFee ?? selectedPackageToPurchase.packageAmount}
                              </span>
                            </li>
                            {couponCodeObj != null && (
                              <li className="list-group-item d-flex justify-content-between bg-light">
                                <div className="text-success">
                                  <h6 className="my-0">Promo code</h6>
                                  <span>
                                    <small>
                                      {couponCodeObj.couponCode} (
                                      {couponCodeObj.couponDiscountType === 'RSOFF'
                                        ? `Flat Rs. ${couponCodeObj.couponDiscount} OFF`
                                        : `${couponCodeObj.couponDiscount}% OFF `}
                                      )
                                    </small>
                                    <a
                                      href="#"
                                      onClick={e => {
                                        e.preventDefault();
                                        setCouponCodeObj(null);
                                      }}
                                    >
                                      <small> Remove X</small>
                                    </a>
                                  </span>
                                </div>
                                <span className="text-success text-center">-Rs. {couponCodeObj.discountAmount}</span>
                              </li>
                            )}
                            <li className="list-group-item d-flex justify-content-between">
                              <span>Total (INR)</span>
                              <strong>Rs. {getAmountToPay()}</strong>
                            </li>
                          </ul>
                          <div className="card p-2">
                            <div className="input-group">
                              <Input
                                type="text"
                                className="form-control"
                                placeholder="Promo code"
                                value={couponCode}
                                onChange={e => {
                                  if (validationErrors) setValidationErrors({});
                                  setCouponCode(e.target.value);
                                }}
                              />
                              <div className="input-group-append">
                                <button
                                  className="btn btn-secondary"
                                  onClick={() => {
                                    validateCoupon();
                                  }}
                                >
                                  Redeem
                                </button>
                              </div>
                            </div>
                            {!_.isEmpty(validationErrors) && validationErrors.couponCode != null && (
                              <span className="text-danger m-2"> {validationErrors.couponCode} </span>
                            )}
                          </div>
                        </div>
                        <div className="card-footer">
                          <button
                            id="rzp-button1"
                            type="button"
                            className="subscribe btn btn-primary btn-block shadow-sm"
                            onClick={() => {
                              createOrder();
                            }}
                          >
                            Proceed To Pay
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </CardBody>
      {response != null && (
        <Modal
          isOpen={response != null}
          toggle={() => {
            setResponse(null);
          }}
          onClosed={() => {
            if (response.status === 'SUCCESS') {
              setRedirect(true);
            } else {
              setResponse(null);
            }
          }}
          onExit={() => {
            if (response.status === 'SUCCESS') {
              setRedirect(true);
            } else {
              setResponse(null);
            }
          }}
          centered
        >
          {response.status === 'SUCCESS' && (
            <ModalBody>
              <>
                <Lottie options={successOptions} style={{ width: '120px', height: '120px' }} />
                <h5 className="text-center">Thanks for purchasing the package. </h5> <br />
                <h5 className="text-center">We've sent the invoice to your email.</h5>
                {/* <h5 className="text-center">
                  Please click
                  <a href="">here</a> to download the invoice.
                </h5> */}
              </>
            </ModalBody>
          )}
          {response.status === 'FAILURE' && (
            <ModalBody>
              <>
                <Lottie options={failureOptions} style={{ width: '120px', height: '120px' }} />
                <h5 className="text-center">Couldn't receive the payment. Please retry the payment. </h5>
                <h5 className="text-center"> Any money deducted will be refunded to your account.</h5>
              </>
            </ModalBody>
          )}
        </Modal>
      )}
    </Card>
  );
});

const getName = profile => {
  let name = '';
  if (profile.firstName != null) {
    name += profile.firstName;
  }
  if (profile.lastName != null) {
    name += ' ' + profile.lastName;
  }
  return name;
};

export default withRedirect(OrderLayout);
