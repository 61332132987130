import React from 'react';
import { observer } from 'mobx-react-lite';
import { Col, Media, Row, Button, Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import { BsCheckCircle } from 'react-icons/bs';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useSwotStore } from '../../store';
import VideoPlayer from '../../components/common/VideoPlayer';

const PreModule = observer(() => {
  const { swotStore } = useSwotStore();
  const { preVideos } = swotStore;

  const [collapse, setCollapse] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);

  const handleVideo = video => e => {
    setCollapse(true);
    setCurrentVideo(video);
  };

  return (
    <>
      {preVideos.map((video, index) => (
        <Row
          key={index}
          noGutters
          className="align-items-center px-1"
          style={{ borderBottom: '1px solid grey', height: '100px', marginBottom: '10px' }}
        >
          <Col xs={12} className="py-3 px-2 px-md-3">
            <Media className="align-items-center">
              <Link to="#">
                <img
                  className="rounded mr-3 d-none d-md-block"
                  src="http://addplaybuttontoimage.way4info.net/Images/Icons/25.png"
                  alt="Icon"
                  width="60"
                />
              </Link>
              <Media body>
                <h5 className="fs-0">{video.fileName}</h5>
              </Media>
              <Media>
                {video.videoWatched && (
                  <span className="badge fs-lg--1 text" style={{ marginTop: '23px', color: 'green' }}>
                    Completed
                    <FontAwesomeIcon icon="check" transform="shrink-2" className=" ml-1" />
                  </span>
                )}
                <Button onClick={handleVideo(video)} color="primary" block className="mt-3">
                  Play
                </Button>
              </Media>
            </Media>
          </Col>
        </Row>
      ))}
      {currentVideo && <VideoPlayer collapse={collapse} video={currentVideo} setCollapse={setCollapse} />}
    </>
  );
});

export default PreModule;
