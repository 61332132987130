const getSelectedOption = (testToAnswersMap, currentTestId, questionId, optionId) => {
  let obj = testToAnswersMap[currentTestId];
  if (obj == null || _.isEmpty(obj)) {
    return false;
  }

  let value = obj[questionId] || [];
  return value.includes(optionId);
};

export { getSelectedOption };
