import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Button
} from 'reactstrap';
import FeedbackForm from './FeedbackForm';

export const FeedbackComponent = ({ isOpen, setOpen }) => {
  const TempComponent = () => <></>;

  if (!isOpen) {
    return <></>;
  }

  return (
    <Modal
      centered
      isOpen={isOpen}
      // size="sm"
      toggle={() => {
        setOpen(!isOpen);
      }}
    >
      <ModalHeader>Submit Feedback</ModalHeader>
      <ModalBody>
        <div>
          <FeedbackForm setOpen={setOpen} />
        </div>
      </ModalBody>
    </Modal>
  );
};
