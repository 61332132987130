import React from 'react';
import { observer } from 'mobx-react-lite';
import { Route, Switch } from 'react-router-dom';
import { Row, Col, Container } from 'reactstrap';
import B2BAdminNavbar from '../../components/navbar/B2BAdminNavbar';
import Home from './Home';
import UserHierarchy from './UserHierarchy';

const AnalyticsDashboard = observer(() => {
  return (
    <Container>
      <Row>
        <Col xs="2">
          <B2BAdminNavbar navbarStyle="transparent" />
        </Col>
        <Col xs="10" className="mt-2">
          <Switch>
            <Route path="/analytics/dashboard" exact component={Home} />
            <Route path="/analytics/users" exact component={UserHierarchy} />
          </Switch>
        </Col>
      </Row>
    </Container>
  );
});

export default AnalyticsDashboard;
