import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';
import queryString from 'query-string';

import { Button, Form, Row, Col, FormGroup, Input, CustomInput, Label } from 'reactstrap';
import withRedirect from '../../hoc/withRedirect';
import { useSwotStore } from '../../store';

const LoginForm = observer(({ setRedirect, setRedirectUrl, hasLabel, layout }) => {
  // State
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [error, setError] = useState(null);

  const { swotStore } = useSwotStore();

  let params = queryString.parse(window.location.search);
  // console.log(params, params.next);
  const redirectUrl = params.next != null ? params.next : '/products';

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    swotStore.doLogin(email, password, remember).then(response => {
      if (response.statusCode != 200) {
        setPassword('');
        setError('Invalid Credentials.');
      } else {
        toast.success(`Logged in as ${email}`, {
          autoClose: 1000,
          position: 'top-center'
        });
        swotStore.doFetchProfile().then(response => {
          setRedirect(true);
        });
      }
    });
  };

  useEffect(() => {
    setRedirectUrl(redirectUrl);
  }, [setRedirectUrl, layout]);

  useEffect(() => {
    setIsDisabled(!email || !password);
  }, [email, password]);

  return (
    <Form onSubmit={handleSubmit}>
      {error != null && <div className="mb-2 text-center text-danger">{error}</div>}
      <FormGroup>
        {hasLabel && <Label>Email address</Label>}
        <Input
          placeholder={!hasLabel ? 'Email address' : ''}
          value={email}
          onChange={({ target }) => {
            setEmail(target.value);
            setError(null);
          }}
          type="email"
        />
      </FormGroup>
      <FormGroup>
        {hasLabel && <Label>Password</Label>}
        <Input
          placeholder={!hasLabel ? 'Password' : ''}
          value={password}
          onChange={({ target }) => {
            setPassword(target.value);
            setError(null);
          }}
          type="password"
        />
      </FormGroup>
      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <CustomInput
            id="customCheckRemember"
            label="Remember me"
            checked={remember}
            onChange={({ target }) => setRemember(target.checked)}
            type="checkbox"
          />
        </Col>
        <Col xs="auto">
          <Link className="fs--1" to={`/auth/forget-password`}>
            Forgot Password?
          </Link>
        </Col>
      </Row>
      <FormGroup>
        <Button color="primary" block className="mt-3" disabled={isDisabled}>
          Login
        </Button>
      </FormGroup>
      {/* <Divider className="mt-4">or log in with</Divider>
      <SocialAuthButtons /> */}
    </Form>
  );
});

LoginForm.propTypes = {
  setRedirect: PropTypes.func.isRequired,
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'basic',
  hasLabel: false
};

export default withRedirect(LoginForm);
