import React, { useState, createRef } from 'react';
import { Card, CardImg, CardBody, CardTitle, CardDeck, CardText, CardGroup, Button, Collapse } from 'reactstrap';
import { observer } from 'mobx-react-lite';

import FalconCardHeader from '../components/common/FalconCardHeader';
import PageHeader from '../components/common/PageHeader';
import { useSwotStore } from '../store';

/**
 * Author: Surya Prashanth
 */

const ProductsLayout = observer(() => {
  const [selectedId, setSelectedId] = useState(null);

  const clickedStyle = {
    transform: 'translateY(-.6rem)',
    boxShadow: '0 1rem 4rem rgb(0 0 0 / 18%)'
  };

  const widthStyle = { 'max-width': '300px' };

  const onProductSelect = id => {
    if (selectedId == id) {
      setSelectedId(null);
    } else {
      setSelectedId(id);
    }
  };

  const { swotStore } = useSwotStore();
  const { user } = swotStore;

  console.log('User info,', user);

  const productOneRef = createRef();
  const productTwoRef = createRef();

  return (
    <>
      <Card className="mb-3 h-100">
        <FalconCardHeader title="Products" />
        <CardBody className="fs--1">
          <CardDeck>
            <Card
              style={selectedId == 'one' ? clickedStyle : {}}
              className="card-span cursor-pointer h-100"
              onClick={() => {
                onProductSelect('one');
                if (productOneRef.current) {
                  productOneRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest'
                  });
                }
              }}
            >
              <CardImg top src="/static/media/10.5c194d24.jpg" alt="Card image cap" style={{ height: '250px' }} />
              <CardBody>
                <CardTitle tag="h5">Study Enhancement Program</CardTitle>
                <CardText>
                  By the end of this program, you will discover how to optimize your study habits and incorporate best
                  practices and techniques to make the most out of your study time.
                </CardText>
              </CardBody>
            </Card>
            <Card
              style={selectedId == 'two' ? clickedStyle : {}}
              className="card-span cursor-pointer h-100"
              onClick={() => {
                onProductSelect('two');
                if (productTwoRef.current) {
                  productTwoRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest'
                  });
                }
              }}
            >
              <CardImg
                top
                width="100px"
                src="/static/media/10.5c194d24.jpg"
                alt="Card image cap"
                style={{ height: '250px' }}
              />
              <CardBody>
                <CardTitle tag="h5">Career Planning for High School</CardTitle>
                <CardText>
                  By the end of this program, you will have complete clarity on various career fields, your strengths,
                  weaknesses, preferences and confident about your future plans.
                </CardText>
              </CardBody>
            </Card>
          </CardDeck>
          <div className="mt-4">
            {/* <Collapse isOpen={selectedId === 'one'}> */}
            {selectedId === 'one' && (
              <div>
                <Card style={{ boxShadow: 'none' }}>
                  <CardTitle className="p-3 text-center" tag="h5">
                    Study Enhancement Program
                  </CardTitle>
                  <CardBody>
                    <CardText>
                      <div style={{ fontSize: '18px' }} ref={productOneRef}>
                        <p className="p1">
                          <span className="s1">
                            <strong>Escape the circle of exams. Crack tough exams with ease.</strong>
                          </span>
                        </p>
                        <p className="p2">
                          <span className="s1">
                            Hey, do you dream of becoming a doctor or an actor or engineer or any other profession? Did
                            you evet think “Aah, but this entrance exam is so difficult?” We feel you.
                            <span className="Apple-converted-space">&nbsp;</span>
                          </span>
                        </p>
                        <p className="p2">
                          <span className="s1">
                            Be it tough competitive entrance exams or school exams, exam do form an important part of
                            our academic life. Good grades do not define us. We must essentially study well so we can
                            retain many concepts and apply them in real-time scenarios. But, we must also study to crack
                            competitive exams to get into our Dream College or Job.
                          </span>
                        </p>
                        <br />
                        <p className="p2">
                          <span className="s1">
                            A student may keep on putting in the hours but the effort may not be culminating into
                            performance. Another student may simply not be getting time to sit down and practice due to
                            having a hectic daily schedule filled with many strenuous activities. “I keep studying, but
                            I don’t remember it”, this statement is one of the most common complaints to tumble out of a
                            student’s mouth.
                          </span>
                        </p>
                        <br />
                        <p className="p2">
                          <span className="s1">
                            Similarly there are many common issues that act as hurdles on the path to achieve both
                            academic as well as professional success. To help set this right, we have designed this
                            comprehensive study enhancement program where we analyze your strengths and weaknesses in
                            over 14 traits influencing your study habits and also provide you detailed orientation and
                            tips on how to improve and optimize your strategy in various factors influencing your study
                            habits.
                          </span>
                        </p>
                        <br />
                        <p className="p2">
                          <span className="s1">
                            <strong>What the report tells you</strong>
                          </span>
                        </p>
                        <p className="p2">
                          <span className="s1">
                            The Report is a culmination of your efficacy of study habits in 9 important areas, your
                            learning style and your learning preferences.
                          </span>
                        </p>
                        <p className="p2">
                          <span className="s1">
                            It also consists of domain wise tips which is further sub-divided into 2 categories:
                            satisfactory and unsatisfactory. Depending on your score in each domain, you will be given
                            specific tips. These improvement tips will be given based on your respective scope for
                            improvement.
                          </span>
                        </p>
                        <p className="p2">
                          <span className="s1">
                            You need to carefully read and analyse these tips and make concerted efforts to improve.
                          </span>
                        </p>
                        <p className="p2">
                          <span className="s1">
                            This will ensure that you can maximize the impact of your study habits and reach your goals
                            - improve your marks, clear a tough to crack entrance or competitive exam or getting in your
                            dream college or job.
                          </span>
                        </p>
                      </div>
                    </CardText>
                  </CardBody>
                </Card>
              </div>
            )}
            {/* </Collapse> */}
            {/* <Collapse isOpen={selectedId === 'two'}> */}
            {selectedId === 'two' && (
              <div>
                <Card style={{ boxShadow: 'none' }}>
                  <CardTitle className="p-3 text-center" tag="h5">
                    Career Planning for High School
                  </CardTitle>
                  <CardBody>
                    <CardText>
                      <div ref={productTwoRef} className="">
                        <b>[Coming soon]</b>
                      </div>
                    </CardText>
                  </CardBody>
                </Card>
              </div>
            )}
            {/* </Collapse> */}
          </div>
        </CardBody>
      </Card>
    </>
  );
});

export default ProductsLayout;
