import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Card, CardBody, Form, FormGroup, Label, Row, Table } from 'reactstrap';
import _ from 'lodash';

import FalconCardHeader from '../../components/common/FalconCardHeader';
import { useSwotStore } from '../../store';
import PageLoader from '../../components/common/PageLoader';
import { toast } from 'react-toastify';
import Select from '../../components/common/Select';
import PageLoaderOverflow from '../../components/common/PageLoaderOverflow';

const AddPackages = observer(() => {
  const { swotStore } = useSwotStore();

  const [packages, setPackages] = useState([]);

  const [users, setUsers] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState([]);

  useEffect(() => {
    swotStore.doFetchPackages().then(response => {
      setPackages([
        {
          label: 'Select All',
          value: -1
        },
        ...response.result.map(pack => ({
          label: pack.packageName,
          value: pack.packageId
        }))
      ]);
    });

    // Fetching all users with user level(last level)
    swotStore.getUsersByRole(5).then(response => {
      if (response.statusCode == 504) {
        setUsers([]);
      } else if (response.statusCode != 200) {
        toast.error("Couldn't fetch users. Please try again");
      } else {
        setUsers(response.result.map(user => ({ label: user.emailId, value: user.userId })));
      }
    });
  }, []);

  const addPackagesToUsers = () => {
    if (_.isEmpty(selectedPackages) || _.isEmpty(selectedUsers)) {
      return;
    }

    const data = [];
    for (let i = 0; i < selectedPackages.length; i++) {
      for (let j = 0; j < selectedUsers.length; j++) {
        data.push({
          testPackageId: selectedPackages[i].value,
          userId: selectedUsers[i].value
        });
      }
    }

    setLoading(true);
    swotStore.addPackagesToUsers(data).then(response => {
      setLoading(false);
      if (response.statusCode != 200) {
        toast.error("Couldn't add packages");
      } else {
        toast.success('Successfully added packages to users');
      }
    });
  };

  return (
    <Card className="mb-3">
      <CardBody>
        <FalconCardHeader title="Add packages" light={false} className="text-center" />
        {isLoading && <PageLoaderOverflow isOpen={isLoading} />}
        <Form>
          <FormGroup>
            <Label for="packages">Packages applicable</Label>
            <div className="border">
              <Select
                styles={{
                  border: '1px solid #d8e2ef !important'
                }}
                isMulti
                options={packages}
                value={selectedPackages}
                onChange={setSelectedPackages}
              />
            </div>
          </FormGroup>
          <FormGroup>
            <Label for="users">Users applicable(use comma separated emails for pasting)</Label>
            <div className="border">
              <Select
                styles={{
                  border: '1px solid #d8e2ef !important'
                }}
                isMulti
                options={users}
                value={selectedUsers}
                onChange={setSelectedUsers}
                onInputChange={inputValue => {
                  if (_.isEmpty(inputValue) || !inputValue.includes(',')) {
                    return;
                  }
                  const values = inputValue.split(',');
                  const copiedUsers = users.filter(user => values.includes(user.label));
                  setSelectedUsers(copiedUsers);
                }}
              />
            </div>
          </FormGroup>
          <Button
            color="primary"
            disabled={_.isEmpty(selectedPackages) || _.isEmpty(selectedUsers)}
            onClick={() => {
              addPackagesToUsers();
            }}
          >
            Save
          </Button>
        </Form>
      </CardBody>
    </Card>
  );
});

export default AddPackages;
