import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Card, CardBody, Col, CustomInput, Label, Row } from 'reactstrap';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import { useSwotStore } from '../store';
import PageLoader from '../components/common/PageLoader';
import FalconCardHeader from '../components/common/FalconCardHeader';

import './JDLayout.css';

const ROW_SIZE = 3;

/**
 * https://stackoverflow.com/questions/57600674/react-table-show-nested-table-for-a-selected-rows-nested-data
 */

const datax = [
  {
    name: 'Career 1',
    subs: [
      {
        name: 'Sub 11'
      },
      {
        name: 'Sub 12'
      },
      {
        name: 'Sub 13'
      },
      {
        name: 'Sub 14'
      }
    ]
  },
  {
    name: 'Career 2',
    subs: [
      {
        name: 'Sub 21'
      },
      {
        name: 'Sub 22'
      },
      {
        name: 'Sub 23'
      }
    ]
  },
  {
    name: 'Career 3',
    subs: [
      {
        name: 'Sub 31'
      },
      {
        name: 'Sub 32'
      },
      {
        name: 'Sub 33'
      }
    ]
  },
  {
    name: 'Career 4',
    subs: [
      {
        name: 'Sub 41'
      },
      {
        name: 'Sub 42'
      }
    ]
  }
];

const JDLayout = observer(() => {
  const [selectedId, setSelectedId] = useState(null);
  const [data, setData] = useState(null);

  const { swotStore } = useSwotStore();

  useEffect(() => {
    swotStore.doFetchCareerInformation().then(response => {
      if (response.statusCode !== 200) {
        toast.error("Couldn't fetch the career information. Please try again.");
      } else {
        setData(response.result);
      }
    });
  }, []);

  const getSubElementsForCategory = data => {
    const elements = [];
    const len = data.length;
    for (let i = 0; i < len; i += ROW_SIZE) {
      let temp = [];
      for (let j = 0; j < ROW_SIZE; j++) {
        if (i + j < len) {
          temp.push(
            <Col lg="4" key={data[i + j].jdSubCategoryId}>
              <div className="sub-career">
                <Link
                  className="btn btn-falcon-primary"
                  style={{ width: '100%' }}
                  to={`/career-info/description/${data[i + j].jdSubCategoryId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {data[i + j].jdSubCategoryName}
                </Link>
              </div>
            </Col>
          );
        }
      }
      if (!_.isEmpty(temp)) {
        elements.push(<Row className="mt-3">{temp}</Row>);
      }
    }
    return elements;
  };

  const getJDElements = data => {
    const len = data.length;
    let finalElements = [];
    for (let i = 0; i < len; i += ROW_SIZE) {
      // Parent grid of 3 elements
      let parentGrid = [];
      parentGrid.push();
      for (let j = 0; j < ROW_SIZE; j++) {
        if (i + j < len) {
          parentGrid.push(
            <Col key={'Career' + data[i + j].jdCategoryName} lg="4">
              <div className="career">
                <Button
                  color="primary"
                  style={{ width: '100%' }}
                  onClick={() => {
                    if (selectedId === data[i + j].jdCategoryName) {
                      setSelectedId(null);
                    } else {
                      setSelectedId(data[i + j].jdCategoryName);
                    }
                  }}
                >
                  {data[i + j].jdCategoryName}
                </Button>
                {selectedId === data[i + j].jdCategoryName && <div className="triangle" />}
              </div>
            </Col>
          );
        }
      }
      finalElements.push(<Row className="career-row">{parentGrid}</Row>);

      // Child Grids
      for (let j = i; j < Math.min(i + ROW_SIZE, len); j++) {
        finalElements.push(
          <Row
            key={'SubCareer' + data[j].jdCategoryName}
            className="sub-career-row"
            style={selectedId === data[j].jdCategoryName ? { display: 'block' } : { display: 'none' }}
          >
            <div className="container">{getSubElementsForCategory(data[j].jdSubCategorys)}</div>
          </Row>
        );
      }
    }
    return finalElements;
  };

  return data == null ? (
    <PageLoader />
  ) : (
    <>
      <Card className="mb-3 h-100">
        <FalconCardHeader title="Job Descriptions" />
        <CardBody className="fs--1">{getJDElements(data)}</CardBody>
      </Card>
    </>
  );
});

/**
 * // return (
  //   <div className="container mt-5">
  //     <Row className="career-row">
  //       <Col>
  //         <div className="career">
  //           <Button>One</Button>
  //         </div>
  //       </Col>
  //       <Col>
  //         <div className="career">Two</div>
  //       </Col>
  //       <Col>
  //         <div className="career">Three</div>
  //       </Col>
  //     </Row>
  //     <Row className="sub-career-row">
  //       <div className="container">
  //         <Row className="mt-3">
  //           <Col>
  //             <div className="sub-career">Sub One</div>
  //           </Col>
  //           <Col>
  //             <div className="sub-career">Sub Two</div>
  //           </Col>
  //           <Col>
  //             <div className="sub-career">Sub Three</div>
  //           </Col>
  //         </Row>
  //         <Row className="mt-3">
  //           <Col>
  //             <div className="sub-career">Sub One</div>
  //           </Col>
  //           <Col>
  //             <div className="sub-career">Sub Two</div>
  //           </Col>
  //           <Col>
  //             <div className="sub-career">Sub Three</div>
  //           </Col>
  //         </Row>
  //       </div>
  //     </Row>
  //     <Row className="career-row">
  //       <Col>
  //         <div className="career">One</div>
  //       </Col>
  //       <Col>
  //         <div className="career">Two</div>
  //       </Col>
  //       <Col>
  //         <div className="career">Three</div>
  //       </Col>
  //     </Row>
  //   </div>
  // );
 */

export default JDLayout;
