import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Card, CardBody, Col, CustomInput, Label, Row } from 'reactstrap';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

import FalconCardHeader from '../../components/common/FalconCardHeader';
import PricingCard from './PricingCard';
import { useSwotStore } from '../../store';
import PageLoader from '../../components/common/PageLoader';
import { Packages } from './Packages';

const bgColor = [null, 'rgba(115, 255, 236, 0.18)'];

const PricingLayout = observer(() => {
  const { swotStore } = useSwotStore();
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    swotStore.doFetchPackages().then(response => {
      if (response.statusCode != 200) {
        toast.error("Couldn't fetch packages. Please try again.");
      } else {
        setPackages(response.result);
      }
    });
  }, []);

  if (_.isEmpty(packages)) {
    return <PageLoader />;
  }

  return (
    <Card className="mb-3">
      <CardBody>  
        <FalconCardHeader title="Available Packages" light={false} className="text-center"/>
        {/* <Slider
          dots={true}
          infinite={false}
          speed={500}
          slidesToShow={3}
          slidesToScroll={1}
          arrows={true}
          responsive={[
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            }
          ]}
          className="mb-5 mt-5"
        >
          {packages.map((pricingItem, index) => (
            <PricingCard
              key={index}
              type={pricingItem.packageName}
              description={pricingItem.packageDesc}
              price={pricingItem.packageAmount}
              backgroundColor={bgColor[index % 2]}
              packageObj={pricingItem}
            />
          ))}
        </Slider> */}
        <Packages />
      </CardBody>
    </Card>
  );
});

export default PricingLayout;
