import React from 'react';
import { observer } from 'mobx-react-lite';
import { Route, Switch } from 'react-router-dom';
import { Row, Col, Container } from 'reactstrap';

import AdminNavbarVertical from '../../components/navbar/AdminNavbarVertical';
import EditJobDescription from './EditJobDescription';
import ListCoupons from './ListCoupons';
import CreateCoupon from './CreateCoupon';
import UpdateCoupon from './UpdateCoupon';
import CreateUser from './CreateUser';
import UserHierarchy from './UserHierarchy';
import BulkCreate from './BulkCreate';
import AddPayment from './AddPayment';
import AddPackages from './AddPackages';

const AdminLayout = observer(() => {
  return (
    <Container>
      <Row>
        <Col xs="3">
          <AdminNavbarVertical />
        </Col>
        <Col xs="9" className="mt-2">
          <Switch>
            <Route path="/admin/edit-jd" exact component={EditJobDescription} />
            <Route path="/admin/coupons/list" exact component={ListCoupons} />
            <Route path="/admin/coupons/create" exact component={CreateCoupon} />
            <Route path="/admin/coupons/update/:id" exact component={UpdateCoupon} />
            <Route path="/admin/users/create" exact component={CreateUser} />
            <Route path="/admin/users/bulk-create" exact component={BulkCreate} />
            <Route path="/admin/users/hierarchy" exact component={UserHierarchy} />
            <Route path="/admin/users/packages" exact component={AddPackages} />
            <Route path="/admin/payments/add" exact component={AddPayment} />
          </Switch>
        </Col>
      </Row>
    </Container>
  );
});

export default AdminLayout;
