import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Card, CardBody, Modal, ModalBody, Nav, NavItem, NavLink, UncontrolledTooltip } from 'reactstrap';
import classNames from 'classnames';

import Login from '../auth/basic/Login';
import Registration from '../auth/basic/Registration';
import NavbarDropdown from './NavbarDropdown';
import { isB2B } from '../../helpers/envUtils';

const breakpoint = 'lg';

const LandingRightSideNavItem = ({ navbarCollapsed }) => {
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);

  return (
    <Nav navbar className="ml-auto">
      {/* <NavbarDropdown title="Login" right>
        <Card className="navbar-card-login shadow-none">
          <CardBody className="fs--1 font-weight-normal p-4">
            <Login />
          </CardBody>
        </Card>
      </NavbarDropdown> */}
      <NavItem>
        <NavLink
          tag={Link}
          to="#!"
          onClick={() => setShowLoginModal(!showLoginModal)}
          className={classNames({ border: navbarCollapsed })}
          style={{ borderRadius: '10px' }}
        >
          Login
        </NavLink>
        <Modal isOpen={showLoginModal} centered toggle={() => setShowLoginModal(!showLoginModal)}>
          <ModalBody className="p-0">
            <Card>
              <CardBody className="fs--1 font-weight-normal p-4">
                <Login />
              </CardBody>
            </Card>
          </ModalBody>
        </Modal>
      </NavItem>
      {!isB2B() && (
        <NavItem>
          <NavLink
            tag={Link}
            to="#!"
            onClick={() => setShowRegistrationModal(!showRegistrationModal)}
            className={classNames({ 'ml-2': navbarCollapsed, border: navbarCollapsed })}
            style={{ borderRadius: '10px' }}
          >
            Register
          </NavLink>
          <Modal
            isOpen={showRegistrationModal}
            centered
            toggle={() => setShowRegistrationModal(!showRegistrationModal)}
          >
            <ModalBody className="p-0">
              <Card>
                <CardBody className="fs--1 font-weight-normal p-4">
                  <Registration />
                </CardBody>
              </Card>
            </ModalBody>
          </Modal>
        </NavItem>
      )}
    </Nav>
  );
};

export default LandingRightSideNavItem;
