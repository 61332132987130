import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NavItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';

import { breakpoints, getPageName } from '../../helpers/utils';
import { navbarBreakPoint, topNavbarBreakpoint } from '../../config';
import AppContext from '../../context/Context';
import { useSwotStore } from '../../store';
import { isB2B } from '../../helpers/envUtils';

/**
 * Author: Surya Prashanth
 */

const DASHBOARD_ROLES = [];
const ADMIN_ROLES = ['ADMIN', 'ADMIN_LEVEL_1', 'ADMIN_LEVEL_2', 'ADMIN_LEVEL_3'];
const ANALYTICS_ROLES = [
  'ADMIN',
  'CLIENT_LEVEL_1',
  'CLIENT_LEVEL_2',
  'CLIENT_LEVEL_3',
  'ADMIN_LEVEL_1',
  'ADMIN_LEVEL_2',
  'ADMIN_LEVEL_3',
  'ADMIN_LEVEL_4'
];
const PROGRAMS_ROLES = ['CLIENT_LEVEL_4', 'ADMIN_LEVEL_1', 'ADMIN_LEVEL_2', 'USER'];
const PRODUCTS_ROLES = [
  'ADMIN',
  'CLIENT_LEVEL_1',
  'CLIENT_LEVEL_2',
  'CLIENT_LEVEL_3',
  'ADMIN_LEVEL_1',
  'ADMIN_LEVEL_2',
  'ADMIN_LEVEL_3',
  'ADMIN_LEVEL_4',
  'USER'
];

const NavbarTopDropDownMenus = observer(({ setNavbarCollapsed, setShowBurgerMenu }) => {
  const { isCombo, isTopNav } = useContext(AppContext);

  const { swotStore } = useSwotStore();
  const { role, token, purchasedPackages } = swotStore;

  const handleSetNavbarCollapsed = () => {
    const windowWidth = window.innerWidth;
    isTopNav && !isCombo && windowWidth < breakpoints[topNavbarBreakpoint] && setNavbarCollapsed(false);
    isCombo && windowWidth < breakpoints[navbarBreakPoint] && setShowBurgerMenu(false);
  };
  const isLanding = getPageName('landing');

  const [packages, setPackages] = useState(null);

  // useEffect(() => {
  //   if (token != null) {
  //     swotStore.doFetchSubscribedPackages().then(response => {
  //       if (response.statusCode !== 200) {
  //         toast.error("Couldn't fetch purchased modules");
  //       } else {
  //         setPackages(response.result);
  //       }
  //     });
  //   }
  // }, []);

  return (
    <>
      {token != null && !_.isEmpty(token) && (
        <>
          {ADMIN_ROLES.includes(role) && (
            <>
              <NavItem onClick={handleSetNavbarCollapsed}>
                <NavLink className="nav-link" to="/admin">
                  Admin
                </NavLink>
              </NavItem>
            </>
          )}
          {isB2B() && ANALYTICS_ROLES.includes(role) && (
            <>
              <NavItem onClick={handleSetNavbarCollapsed}>
                <NavLink className="nav-link" to="/analytics/dashboard">
                  Analytics
                </NavLink>
              </NavItem>
            </>
          )}
          {
            <NavLink
              className="nav-link"
              to="/dashboard"
              isActive={() => ['/dashboard', '/packages/purchase'].includes(window.location.pathname)}
            >
              Dashboard
            </NavLink>
          }

          {(!isB2B() || PROGRAMS_ROLES.includes(role)) && (
            <NavItem onClick={handleSetNavbarCollapsed}>
              <NavLink className="nav-link" to="/modules">
                Programs
              </NavLink>
            </NavItem>
          )}
          {/* <NavItem onClick={handleSetNavbarCollapsed}>
            <NavLink className="nav-link" to="/career-info">
              Career Library
            </NavLink>
          </NavItem> */}
        </>
      )}
      {/* <NavItem onClick={handleSetNavbarCollapsed}>
        <NavLink className="nav-link" to="/packages">
          Packages
        </NavLink>
      </NavItem> */}
      {(!isB2B() || PRODUCTS_ROLES.includes(role)) && (
        <NavItem onClick={handleSetNavbarCollapsed}>
          <NavLink className="nav-link" to="/products">
            Products
          </NavLink>
        </NavItem>
      )}
      {token == null ? (
        <>
          <NavItem onClick={handleSetNavbarCollapsed}>
            <a className="nav-link" target="_blank" href="http://blog.swotcareers.com">
              Blog
            </a>
          </NavItem>
          <NavItem onClick={handleSetNavbarCollapsed}>
            <NavLink className="nav-link" to="/about">
              About Us
            </NavLink>
          </NavItem>
          <NavItem onClick={handleSetNavbarCollapsed}>
            <NavLink className="nav-link" to="/contact">
              Contact us
            </NavLink>
          </NavItem>
        </>
      ) : (
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret>
            More
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem>
              <NavItem onClick={handleSetNavbarCollapsed}>
                <a className="nav-link" target="_blank" href="http://blog.swotcareers.com" style={{ color: 'black' }}>
                  Blog
                </a>
              </NavItem>
            </DropdownItem>
            <DropdownItem>
              <NavLink className="nav-link" to="/about" style={{ color: 'black' }}>
                About Us
              </NavLink>
            </DropdownItem>
            <DropdownItem>
              <NavLink className="nav-link" to="/contact" style={{ color: 'black' }}>
                Contact us
              </NavLink>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      )}
    </>
  );
});

/* {packages != null &&
packages.map(packageObj => (
  <NavItem onClick={handleSetNavbarCollapsed} key={packageObj.testPackageId}>
    <NavLink
      className="nav-link"
      to={`/modules/${packageObj.testPackageId}`}
      isActive={() => ['/modules', '/tests'].includes(window.location.pathname)}
    >
      {packageObj.testPackageName}
    </NavLink>
  </NavItem>
))} */

NavbarTopDropDownMenus.propTypes = { setNavbarCollapsed: PropTypes.func.isRequired };

export default NavbarTopDropDownMenus;
