import React from 'react';
import { observer } from 'mobx-react-lite';
import { Col, Media, Row, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { BsCheckCircle } from 'react-icons/bs';
import { useState, useEffect } from 'react';
import { useSwotStore } from '../../store';
import { toast } from 'react-toastify';
import VideoPlayer from '../../components/common/VideoPlayer';

const PostModule = observer(() => {
  const { swotStore } = useSwotStore();
  const { postVideos } = swotStore;

  const [collapse, setCollapse] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);

  const handleVideo = video => e => {
    setCollapse(true);
    setCurrentVideo(video);
  };

  return (
    <>
      {postVideos.map((video, index) => (
        <Row
          key={index}
          noGutters
          className="align-items-center px-1"
          style={{ borderBottom: '1px solid grey', height: '100px', marginBottom: '10px' }}
        >
          <Col xs={12} className="py-3 px-2 px-md-3">
            <Media className="align-items-center">
              <Link to={`/e-commerce/product-details/`}>
                <img
                  className="rounded mr-3 d-none d-md-block"
                  src="http://addplaybuttontoimage.way4info.net/Images/Icons/25.png"
                  alt="Icon"
                  width="60"
                />
              </Link>
              <Media body>
                <h5 className="fs-0">{video.fileName}</h5>
              </Media>
              <Media>
                {/* <h5 className="fs-0 text-success">
                  Completed <BsCheckCircle />
                </h5> */}
                <Button onClick={handleVideo(video)} color="primary" block className="mt-3">
                  Play
                </Button>
              </Media>
            </Media>
          </Col>
        </Row>
      ))}
      {currentVideo && <VideoPlayer collapse={collapse} video={currentVideo} setCollapse={setCollapse} />}
    </>
  );
});

export default PostModule;
