import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';
import _ from 'lodash';

import { useSwotStore } from '../store/index';
import { toast } from 'react-toastify';

const ContactLayout = observer(() => {
  const { swotStore } = useSwotStore();

  const [data, setData] = useState({});
  const [validations, setValidations] = useState({});

  const handleChange = field => e => {
    setData({
      ...data,
      [field]: e.target.value
    });
    setValidations({});
  };

  const validate = () => {
    let errorObj = {};
    ['mobileNumer', 'emailId', 'name', 'message'].forEach(field => {
      let value = data[field];
      if (field == 'mobileNumer') {
        if (!_.isEmpty(value) && (value.length != 10 || /[^0-9]/.test(value))) {
          errorObj[field] = 'Mobile number should be of 10 digits';
        }
      } else if (field == 'emailId' && value != null) {
        if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)) {
          errorObj[field] = 'Invalid email id';
        }
      } else if ((field == 'name' || field == 'message') && _.isEmpty(value)) {
        errorObj[field] = 'Field is mandatory';
      }
    });
    setValidations(errorObj);
    return errorObj;
  };

  const onSubmit = () => {
    const errors = validate();
    if (!_.isEmpty(errors)) {
      return;
    }
    swotStore.contactUs(data).then(response => {
      if (response.statusCode !== 200) {
        toast.error("Couldn't submit the message. Please try again.");
      } else {
        toast.success('Submitted the message. Some one from our team will contact you.');
        setData({});
      }
    });
  };

  return (
    <Card className="mb-3">
      <CardBody>
        <div style={{ height: '200px' }} className="m-3">
          <h3>Contact Us</h3>
          <p>Get in touch and let us know how we can help you.</p>
        </div>
        {/* <PageHeader
          style={{ height: '200px' }}
          title="Contact Us"
          description="Get in touch and let us know how we can help you."
          className="mb-3"
        /> */}
        <div className="row" style={{ marginTop: '-100px', marginLeft: '0px' }}>
          <div className="col">
            <Card className="mb-3 border" style={{ width: '100%' }}>
              <CardBody>
                <CardText>
                  <Form>
                    <FormGroup>
                      <Label>Name</Label>
                      <Input type="text" name="name" value={data.name || ''} onChange={handleChange('name')} />
                      <p className="text-danger">{validations.name}</p>
                    </FormGroup>

                    <FormGroup>
                      <Label>Email</Label>
                      <Input type="email" name="email" value={data.emailId || ''} onChange={handleChange('emailId')} />
                      <p className="text-danger">{validations.emailId}</p>
                    </FormGroup>

                    <FormGroup>
                      <Label>Phone</Label>
                      <Input
                        type="tel"
                        name="phone"
                        value={data.mobileNumer || ''}
                        onChange={handleChange('mobileNumer')}
                        pattern="[0-9]{10}"
                      />
                      <p className="text-danger">{validations.mobileNumer}</p>
                    </FormGroup>

                    <FormGroup>
                      <Label>Message</Label>
                      <Input
                        type="textarea"
                        name="message"
                        value={data.message || ''}
                        onChange={handleChange('message')}
                      />
                      <p className="text-danger">{validations.message}</p>
                    </FormGroup>
                    <Button color="primary" onClick={onSubmit}>
                      Submit Message
                    </Button>
                  </Form>
                </CardText>
              </CardBody>
            </Card>
          </div>
          <div className="col" style={{ fontSize: '15px' }}>
            <h5 className="mb-2">Corporate Address:</h5>
            <p>No. 4-1-12 2,</p>
            <p>Boggulakunta,</p>
            <p>Telangana, India</p>
            <p>500001</p>
          </div>
        </div>
      </CardBody>
    </Card>
  );
});

export default ContactLayout;
